import ClassRoundedIcon from "@mui/icons-material/ClassRounded";

import ServiceCategoryList from "./ServiceCategoryList";
import ServiceCategoryCreate from "./ServiceCategoryCreate";
import ServiceCategoryEdit from "./ServiceCategoryEdit";

const resource = {
  list: ServiceCategoryList,
  create: ServiceCategoryCreate,
  edit: ServiceCategoryEdit,
  icon: ClassRoundedIcon,
};

export default resource;
