import FoodBankRoundedIcon from "@mui/icons-material/FoodBankRounded";

import CharityList from "./CharityList";
import CharityCreate from "./CharityCreate";
import CharityEdit from "./CharityEdit";

const resource = {
  list: CharityList,
  create: CharityCreate,
  edit: CharityEdit,
  icon: FoodBankRoundedIcon,
};

export default resource;
