import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import ReceiveForm from './ReceiveForm';
import { Box } from '@mui/material';
import SectionTitle from '../../../../elements/SectionTitle';

export default function ReceiveCreate() {
	return (
		<Create redirect="/pharmacyReceive">
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label={'pharmacy.account.receive'} />
			</Box>
			<SimpleForm>
				<ReceiveForm />
			</SimpleForm>
		</Create>
	);
}
