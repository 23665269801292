import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { Button, useGetList } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import { grey } from '@mui/material/colors';
import { NepaliDateInputCommon } from '../../../../elements/NepaliDateInput';
import handlePrint from '../../../../_helper/print';
import { Close, Print } from '@mui/icons-material';

export default function SalesSummary() {
	const [filter, setFilter] = useState({});
	const list = useGetList(`pharmacyReport/salesSummary`, { filter });
	function printSalesSummary() {
		fetch(
			process.env.REACT_APP_BACKEND_URL +
				'pharmacyReport/salesSummary/pdf' +
				`?filter=${JSON.stringify(filter)}`,
			{
				headers: {
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('auth'))?.access
					}`,
				},
			}
		)
			.then(async (res) => await res.json())
			.then((res) => {
				console.log(res);
				// res?.url && handlePrint(res?.url);
				if (res?.url) {
					const a = document.createElement('a');
					a.target = '_blank';
					a.href = res?.url;
					a.download = 'salesSummary';
					a.click();
				}
			});
	}
	return (
		<Paper sx={{ p: 2 }}>
			<SectionTitle label="pharmacy.report.salesSummary" />
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(4, 1fr)',
					gap: 1,
					pb: 2,
				}}
			>
				<NepaliDateInputCommon
					label="Date Form"
					value={filter?.['date.$gte']?.replace(/-/g, '/')}
					onChange={(date) =>
						setFilter((prev) => ({ ...prev, 'date.$gte': date }))
					}
				/>
				<NepaliDateInputCommon
					label="Date To"
					value={filter?.['date.$lte']?.replace(/-/g, '/')}
					onChange={(date) =>
						setFilter((prev) => ({ ...prev, 'date.$lte': date }))
					}
				/>
				<Button onClick={() => setFilter({})}>
					<Close sx={{ mr: 2 }} /> Clear Filter
				</Button>
				<Button onClick={printSalesSummary} variant="contained">
					<Print sx={{ mr: 2 }} /> Print Summary
				</Button>
			</Box>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Type</TableCell>
							<TableCell>Sales Amount</TableCell>
							<TableCell>Sales Discount</TableCell>
							<TableCell>Sales VAT</TableCell>
							<TableCell>Sales Net Amount</TableCell>
							<TableCell>Sales Return Amount</TableCell>
							<TableCell>Sales Return Discount</TableCell>
							<TableCell>Sales Return VAT</TableCell>
							<TableCell>Sales Return Net Amount</TableCell>
							<TableCell>Total</TableCell>
						</TableRow>
					</TableHead>
					{!list.isLoading && (
						<TableBody>
							{list?.data?.map((type) => (
								<TableRow key={type.id} sx={{ backgroundColor: grey[200] }}>
									<TableCell sx={{ fontWeight: 600 }}>{type.id}</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.sales?.basicAmount || 0}
									</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.sales?.discountAmount || 0}
									</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.sales?.vatAmount || 0}
									</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.sales?.total || 0}
									</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.salesReturn?.basicAmount || 0}
									</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.salesReturn?.discountAmount || 0}
									</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.salesReturn?.vatAmount || 0}
									</TableCell>
									<TableCell sx={{ textAlign: 'end' }}>
										{type?.salesReturn?.total || 0}
									</TableCell>
									<TableCell sx={{ fontWeight: 600 }}>
										{(
											(+type?.sales?.total || 0) -
											(+type?.salesReturn?.total || 0)
										).toFixed(2)}
									</TableCell>
								</TableRow>
							))}
							<TableRow>
								<TableCell sx={{ fontWeight: 700 }}>Total</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) => a + (+c?.sales?.basicAmount || 0),
										0
									)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) => a + (+c?.sales?.discountAmount || 0),
										0
									)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) => a + (+c?.sales?.vatAmount || 0),
										0
									)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce((a, c) => a + (+c?.sales?.total || 0), 0)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) => a + (+c?.salesReturn?.basicAmount || 0),
										0
									)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) => a + (+c?.salesReturn?.discountAmount || 0),
										0
									)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) => a + (+c?.salesReturn?.vatAmount || 0),
										0
									)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) => a + (+c?.salesReturn?.total || 0),
										0
									)}
								</TableCell>
								<TableCell sx={{ fontWeight: 700, textAlign: 'end' }}>
									{list?.data?.reduce(
										(a, c) =>
											a +
											+(
												(+c?.sales?.total || 0) - (+c?.salesReturn?.total || 0)
											).toFixed(2),
										0
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</TableContainer>
		</Paper>
	);
}
