import React, { useState } from 'react';
import { Create, SimpleForm } from 'react-admin';
import SalesReturnForm from './salesReturnForm';
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

export default function SalesReturnCreate() {
	const [brands, setBrands] = useState([]);
	return (
		<>
			<Create
				redirect={'/pharmacySalesReturn'}
				transform={(data) => {
					const body = { ...data };
					delete body.brand;
					delete body.batchNumber;
					delete body.quantity;
					delete body.rate;
					delete body.amount;
					!body.discount && delete body.discount;
					!body.roundOff && delete body.roundOff;
					body.brands = brands?.map((item) => ({
						...item,
						brand: item.brand?.id,
						batchNumber: item?.batchNumber?.data?.id || item?.batchNumber,
						total: item?.amount,
						name: item?.brand?.data?.name,
						batch: item?.batchNumber?.data?.batchNumber,
						expiryDate: item?.batchNumber?.data?.expiryDate,
					}));
					body.referenceSalesNumber = data?.referenceSalesNumber;

					return body;
				}}
			>
				<SimpleForm>
					<SectionTitle label="pharmacy.salesReturn" />
					<SalesReturnForm state={[brands, setBrands]} />
				</SimpleForm>
			</Create>
		</>
	);
}

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};
