import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField as MuiTextField,
  Button,
} from "@mui/material";
import SectionTitle from "../../../elements/SectionTitle.jsx";
import {
  Datagrid,
  ListContextProvider,
  useList,
  TextField,
  useGetList,
  FunctionField,
  useCreate,
} from "react-admin";
import { grey } from "@mui/material/colors";
import { Comment, FormatUnderlined } from "@mui/icons-material";

export default function ValueEntryList() {
  const [selectedSample, setSelectedSample] = useState(null);
  return (
    <>
      <Paper>
        <Box p={2} pb={0}>
          <SectionTitle label="pathology.valueEntry.valueEntry" />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
            p: 2,
          }}
        >
          <SampleList
            setSelectedSample={setSelectedSample}
            selectedSample={selectedSample}
          />
          {selectedSample && (
            <SampleValueEntry selectedSample={selectedSample} />
          )}
        </Box>
      </Paper>
    </>
  );
}

function SampleList({ setSelectedSample, selectedSample }) {
  const { data } = useGetList("pathologySample", {
    // filter: { invoice: false },
  });
  const listContext = useList({
    data,
  });
  return (
    <Box>
      <ListContextProvider value={listContext}>
        <Datagrid
          rowClick={(_, __, record) => {
            setSelectedSample(record);
            return false;
          }}
          rowSx={(record) => ({
            backgroundColor: record?.id === selectedSample?.id ? grey[200] : "",
            "& *": {
              fontWeight: record?.id === selectedSample?.id && 600,
            },
          })}
          bulkActionButtons={false}
        >
          <TextField source="invoice.name" />
          <TextField source="invoice.labNo" />
        </Datagrid>
      </ListContextProvider>
    </Box>
  );
}

function SampleValueEntry({ selectedSample }) {
  const { data } = useGetList(
    `pathologySample/serviceItems/${selectedSample?.id}`
  );
  const [values, setValues] = useState([]);
  const [create] = useCreate();

  useEffect(() => {
    setValues(
      data
        ?.map((item) => {
          return item?.serviceItems?.map((service) => ({
            service: item?.id,
            serviceItem: service?._id,
          }));
        })
        .flat()
    );
  }, [data]);

  function handleSave() {
    create(`pathologySample/serviceItems/${selectedSample?.id}`, {
      data: { values: values?.filter((value) => value?.value) },
    });
  }

  console.log(values);
  return (
    <Box
      sx={{
        gridColumn: "span 2 / span 2",
        borderLeft: "2px solid",
        borderColor: grey[300],
        pl: 2,
      }}
    >
      {data?.map((record, index) => (
        <Fragment key={record.id}>
          <Typography variant="body1" fontWeight={600} color={"primary.main"}>
            {record?.name}
          </Typography>
          <ServiceList record={record} setValues={setValues} values={values} />
        </Fragment>
      ))}
      <MuiTextField
        multiline
        fullWidth
        label="Internal Remarks/Note"
        rows={3}
        sx={{ my: 2 }}
      />
      <Button variant="contained" onClick={handleSave}>
        Save
      </Button>
    </Box>
  );
}

function ServiceList({ record, setValues, values }) {
  const listContext = useList({
    data: record?.serviceItems?.map((item) => ({ ...item, id: item._id })),
  });

  function handleChange(key, id, value = null) {
    return (e) => {
      setValues((prev) =>
        prev?.map((item) => {
          if (item.serviceItem === id) {
            return { ...item, [key]: value ?? e.target.value };
          } else return item;
        })
      );
    };
  }

  return (
    <>
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          sx={{
            "& .column-name": {
              width: "20% !important",
              maxWidth: "20% !important",
              verticalAlign: "top",
            },
            "& .column-value": {
              width: "50% !important",
              maxWidth: "50% !important",
            },
            "& .column-normalRange": {
              width: "30% !important",
              maxWidth: "30% !important",
              verticalAlign: "top",
            },
          }}
        >
          <TextField source="name" label="Test Name" />
          <FunctionField
            source="value"
            label="Value"
            render={(record) => (
              <Box>
                <MuiTextField
                  size="small"
                  placeholder={record?.normalRange}
                  fullWidth
                  defaultValue={record?.value?.value}
                  disabled={record?.value?.value}
                  sx={{
                    textDecoration:
                      record?.value?.underline ||
                      values?.find(
                        (value) => value?.serviceItem === record?._id
                      )?.underline
                        ? "underline"
                        : "none",
                    textDecorationColor: "red",
                  }}
                  onChange={handleChange("value", record?._id)}
                />
                {(record?.value?.comment ||
                  values?.find((value) => value?.serviceItem === record?._id)
                    ?.hasComment) && (
                  <MuiTextField
                    multiline
                    rows={4}
                    sx={{ mt: 1 }}
                    placeholder="Enter Comment"
                    fullWidth
                    defaultValue={record?.value?.comment}
                    disabled={record?.value?.comment}
                    onChange={handleChange("comment", record?._id)}
                  />
                )}
                <Box sx={{ gap: 1, display: "flex", pt: 1 }}>
                  <Button
                    size="small"
                    variant={
                      values?.find(
                        (value) => value?.serviceItem === record?._id
                      )?.underline
                        ? "contained"
                        : "outlined"
                    }
                    disabled={record?.value?.underline}
                    onClick={handleChange(
                      "underline",
                      record?._id,
                      !values?.find(
                        (value) => value?.serviceItem === record?._id
                      )?.underline
                    )}
                  >
                    <FormatUnderlined fontSize="16" />
                  </Button>
                  <Button
                    size="small"
                    variant={
                      values?.find(
                        (value) => value?.serviceItem === record?._id
                      )?.hasComment
                        ? "contained"
                        : "outlined"
                    }
                    disabled={record?.value?.comment}
                    onClick={handleChange(
                      "hasComment",
                      record?._id,
                      !values?.find(
                        (value) => value?.serviceItem === record?._id
                      )?.hasComment
                    )}
                  >
                    <Comment fontSize="16" />
                  </Button>
                </Box>
              </Box>
            )}
          />
          <TextField source="normalRange" />
        </Datagrid>
      </ListContextProvider>
    </>
  );
}
