import {
	Datagrid,
	List,
	TextField,
	useTranslate,
	FunctionField,
} from 'react-admin';
import { useMediaQuery, Typography } from '@mui/material';

const OpeningStockList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.store.openingstock" />
			<List
				resource="store"
				filters={undefined}
				filter={{ isOpening: true }}
				sort={{}}
				perPage={25}
			>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick={(id) => `/openingStock/${id}`}
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="date" />
						<TextField source="fiscal.title" label="Fiscal Year" />
						<FunctionField
							label="Net Amount"
							render={(data) => (
								<>
									{(+data?.subAmount || 0) +
										(+data?.vatAmount || 0) +
										(+data?.taxableAmount || 0) -
										(+data?.discount || 0)}
								</>
							)}
						/>
						<TextField source="remarks" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default OpeningStockList;
