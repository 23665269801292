import {
	Datagrid,
	FunctionField,
	List,
	TextField,
	useTranslate,
} from 'react-admin';
import { useMediaQuery, Typography } from '@mui/material';

const PurchaseList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.store.purchase" />
			<List
				filters={undefined}
				filter={{ isOpening: false }}
				resource="store"
				sort={{}}
				perPage={25}
			>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick={(id) => `/purchase/${id}`}
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="date" />
						<TextField source="purchaseNo" />
						<TextField source="vendor.name" />
						<TextField source="partyBillNo" label="Bill Number" />
						<TextField source="partyBillDate" label="Bill Date" />
						<TextField source="invoiceType" />
						<TextField source="subAmount" label="Sub Total" />
						<TextField source="discount" />
						<TextField source="taxableAmount" />
						<TextField source="vatAmount" />
						<TextField label="Net Total" source="netAmount" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default PurchaseList;
