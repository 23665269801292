import * as React from "react";
import { Box, Card, CardContent } from "@mui/material";
import LabelImportantRoundedIcon from "@mui/icons-material/LabelImportantRounded";
import { Form, RecordContextProvider, useNotify, Button } from "react-admin";
import dayjs from "dayjs";

import {
  Typography,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import CustomSearch from "../../elements/CustomSearch";

import { Print, copyTextToClipboard } from "../../_helper";

const gender = {
  male: "M",
  female: "F",
  other: "O",
};

const ageType = {
  years: "Y",
  months: "M",
  days: "D",
};

// import segments from "../../segments/data";

const Aside = ({ data: bills, isLoading, error, setSearch, search }) => {
  const notify = useNotify();

  if (isLoading) {
    // return <Loading />;
    return <p>loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const handleCopyTicket = async (text) => {
    console.log(text);
    try {
      await copyTextToClipboard(text);
      notify("Copied to Clipboard", { type: "info", undoable: false });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card
      sx={{
        display: {
          xs: "none",
          md: "block",
        },
        order: 1,
        flex: "0 0 20em",
        ml: 2,
        mt: 0,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <Form>
          <CustomSearch
            source="search"
            onChange={(e) => setSearch(e)}
            value={search}
          />
        </Form>

        <Stepper orientation="vertical" sx={{ mt: 1 }}>
          {bills.map((bill) => (
            <Step key={`${bill.id}`} expanded active completed>
              <StepLabel
                icon={
                  <LabelImportantRoundedIcon
                    color="disabled"
                    sx={{ pl: 0.5, fontSize: "1.25rem" }}
                  />
                }
              >
                {bill.name}
              </StepLabel>
              <StepContent>
                <RecordContextProvider value={bill.data}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {bill.age}&nbsp;
                        {ageType[bill.ageType]}/{gender[bill.gender]}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {dayjs(new Date()).diff(dayjs(bill.createdAt), "day")}{" "}
                        &nbsp; day/s ago
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" gutterBottom>
                        Rs. {bill.total} /-
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <Link
                          onClick={() => handleCopyTicket(`${bill.billNo}`)}
                        >
                          &nbsp;#
                          {bill.billNo}
                        </Link>
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ columnSpan: "2" }}
                      variant="body2"
                      color="textSecondary"
                    >
                      <Button
                        onClick={() => {
                          Print(bill.print);
                        }}
                      >
                        Print
                      </Button>
                    </Typography>
                  </Box>
                </RecordContextProvider>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
};

export default Aside;
