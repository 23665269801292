import { Create, SimpleForm, useTranslate } from "react-admin";
import { Typography } from "@mui/material";
import FiscalYearForm from "./FiscalYearForm";

export const validateForm = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = "ra.validation.required";
  }
  const pattern = /^\d{4}\/\d{2}$/;
  if (!pattern.test(values.title)) {
    errors.title = "Fiscal year must be in the format YYYY/YY";
  }
  return errors;
};

const FiscalYearCreate = () => (
  <Create
    redirect="/fiscalYear"
    transform={(data) => {
      let body = { ...data };
      // body.active = data?.is_active?.[0] === 'active';
      return body;
    }}
  >
    <SimpleForm
      // sx={{ maxWidth: 500 }}
      defaultValues={{
        title: "",
      }}
      validate={validateForm}
    >
      <SectionTitle label="admin.fiscalYear" />
      <FiscalYearForm />
    </SimpleForm>
  </Create>
);

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export default FiscalYearCreate;
