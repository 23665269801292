import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  SelectInput,
  TextInput,
  useTranslate,
} from "react-admin";

import { Box, Typography } from "@mui/material";

import { PALIKADATA } from "../../_data/district";
import CustomAutoInput from "../../elements/CustomAutoInput";
import CustomTextInput from "../../elements/CustomTextInput";
import ServiceInfo from "./ServiceInfo";
import { useFormContext } from "react-hook-form";

const province = Object.keys(PALIKADATA).map((dis) => ({ id: dis, name: dis }));
const getDistricts = (province) => {
  if (province) {
    return Object.keys(PALIKADATA[province]).map((palika) => ({
      id: palika,
      name: palika,
    }));
  }
};
const getLocalBodies = (province, district) => {
  if (province && district) {
    return PALIKADATA[province][district].map((palika) => ({
      id: palika["title_en"],
      name: palika["title_en"],
    }));
  } else {
    return [];
  }
};

export const validateForm = (values) => {
  const errors = {};
  return errors;
};

function BillingCreateForm({
  setServices,
  services,
  setPatientDetails,
  patientDetails,
  total,
}) {
  const { setValue } = useFormContext();

  return (
    <>
      <SectionTitle label="billing.billingName" />
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <TextInput disabled source="Billing No" fullWidth />
      </Box> */}
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput label="Charity" source="charity" reference="charity">
            <AutocompleteInput
              optionText="title"
              optionValue="id"
              fullWidth
              size="small"
            />
          </ReferenceInput>
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput label="Patient" source="patient" reference="patient">
            <CustomAutoInput
              optionText="firstName"
              optionValue="id"
              fullWidth
              isRequired
              onSelect={(e) => {
                console.log(e);
                const name = `${e.firstName || ""} ${e.middleName || ""} ${
                  e.lastName || ""
                }`;
                setPatientDetails({
                  ...e,
                  name: name.trim() || undefined,
                });
                setValue("ageType", e.ageType);
                setValue("gender", e.gender);
              }}
            />
          </ReferenceInput>
        </Box>
      </Box>

      <Box
        display={{
          xs: "block",
          sm: "flex",
          width: "100%",
        }}
        sx={{ marginBottom: "30px" }}
      >
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <CustomTextInput
            source="name"
            value={patientDetails.name}
            fullWidth
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <CustomTextInput
            type="number"
            source="age"
            value={patientDetails.age}
            fullWidth
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <AutocompleteInput
            source="ageType"
            label="Age Type"
            isRequired
            fullWidth
            choices={[
              { id: "years", name: "years" },
              { id: "months", name: "months" },
              { id: "days", name: "days" },
            ]}
            size="small"
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <AutocompleteInput
            source="gender"
            label="Gender"
            isRequired
            fullWidth
            choices={[
              { id: "male", name: "male" },
              { id: "female", name: "female" },
              { id: "others", name: "others" },
            ]}
            size="small"
          />
        </Box>
      </Box>
      <ServiceInfo
        services={services}
        setServices={setServices}
        total={total}
      />
      <Box sx={{ width: "100%" }}>
        <p
          style={{
            textAlign: "right",
            fontWeight: "600",
            fontSize: "3rem",
            lineHeight: 1,
            margin: "0",
            // fontFamily: 'monospace',
          }}
        >
          {total.basicAmount - total.discount}
        </p>
      </Box>

      <Box
        display={{
          xs: "block",
          sm: "grid",
          width: "100%",
          marginBottom: "16px",
        }}
        sx={{
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput label="Doctor" source="doctor" reference="doctor">
            <CustomAutoInput
              sx={{ marginTop: "0.5em", marginBottom: "0.25em" }}
              optionText="name"
              optionValue="id"
              fullWidth
              isRequired
            />
          </ReferenceInput>
          <Box
            display={{
              xs: "block",
              sm: "grid",
              width: "100%",
            }}
            sx={{
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "0.5em",
            }}
          >
            <ReferenceInput label="Shift" source="shift" reference="shift">
              <CustomAutoInput
                optionText="title"
                optionValue="id"
                fullWidth
                isRequired
              />
            </ReferenceInput>
            <ReferenceInput
              label="Counter"
              source="counter"
              reference="counter"
            >
              <CustomAutoInput
                optionText="title"
                optionValue="id"
                fullWidth
                isRequired
              />
            </ReferenceInput>
          </Box>
          <CustomTextInput label={"Remarks"} source="name" fullWidth />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              marginTop: "1em",
            }}
          >
            <Box>
              <Typography variant="subtitle2">INSURANCE BALANCE</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">CASH BALANCE</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <Box />
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="paymentMethod"
              isRequired
              fullWidth
              choices={[
                { id: "cash", name: "cash" },
                { id: "credit", name: "credit" },
              ]}
              size="small"
            />
            <CustomTextInput
              type="number"
              label="Tender Amount"
              source="tenderAmount"
              fullWidth
              style={{ marginBottom: "16px" }}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <CustomTextInput
                  disabled
                  type="number"
                  label="Change Amount"
                  source="changeAmount"
                  fullWidth
                  value={(+formData.tenderAmount || 0) - (total.total || 0)}
                />
              )}
            </FormDataConsumer>
          </Box>
        </Box>
      </Box>

      <Box
        display={{
          xs: "block",
          sm: "flex",
          width: "100%",
          marginTop: "15px",
        }}
      >
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <AutocompleteInput
                source="province"
                choices={province}
                fullWidth
                size="small"
                defaultValue={patientDetails?.province}
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <AutocompleteInput
                source="district"
                choices={getDistricts(formData.province)}
                defaultValue={patientDetails?.district}
                isRequired
                {...rest}
                size="small"
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <AutocompleteInput
                source="local"
                choices={getLocalBodies(formData.province, formData.district)}
                isRequired
                {...rest}
                size="small"
                defaultValue={patientDetails?.local}
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box flex={1}>
          <TextInput
            source="ward"
            defaultValue={patientDetails?.ward}
            isRequired
            fullWidth
          />
        </Box>
      </Box>
    </>
  );
}

export default BillingCreateForm;

const SectionTitle = ({ label, variant = "h6" }) => {
  const translate = useTranslate();

  return (
    <Typography variant={variant} gutterBottom>
      {translate(label)}
    </Typography>
  );
};
