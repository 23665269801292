import {
	ArrayField,
	Datagrid,
	FunctionField,
	List,
	TextField,
	useTranslate,
} from 'react-admin';
import { useMediaQuery, Typography } from '@mui/material';

const BatchEditList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.store.batchedit" />
			<List resource="store/brand" filters={undefined} sort={{}} perPage={25}>
				{isXsmall ? null : (
					<Datagrid
						optimized
						// rowClick="edit"
						bulkActionButtons={false}
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
						expand={<BatchDataGrid />}
					>
						<TextField source="brand.name" />
						<FunctionField
							label="Total Batch"
							render={(record) => record?.items?.length}
						/>
					</Datagrid>
				)}
			</List>
		</>
	);
};

function BatchDataGrid() {
	return (
		<ArrayField source="items">
			<Datagrid bulkActionButtons={false}>
				<TextField source="batchNumber" />
				<TextField source="ratePUnit" label="Cost Per Unit" />
				<TextField source="salesRPUnit" label="Sales Rate Per Unit" />

				<FunctionField
					label="Total Quantity"
					render={(record) => `${record?.totalQty - (record?.soldQty || 0)}`}
				/>
			</Datagrid>
		</ArrayField>
	);
}

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default BatchEditList;
