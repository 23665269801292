import {
	Apartment,
	Biotech,
	Hotel,
	LocalHospital,
	Newspaper,
	Settings,
	Vaccines,
	Inventory,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSidebarContext } from '../_contexts/SidebarContext';
import { useEffect } from 'react';
import { usePermissions } from 'react-admin';

const Dashboard = () => {
	const navigate = useNavigate();
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('lg'));
	const { dispatch } = useSidebarContext();

	useEffect(() => {
		dispatch && dispatch({ type: 'no-menus' });
		localStorage.removeItem('sidebarType');
	}, []);

	function onClick(path, type) {
		if (type) {
			dispatch && dispatch({ type });
			localStorage.setItem('sidebarType', type);
		}
		path && navigate(path);
	}

	const styles = {
		flex: { display: 'flex' },
		flexColumn: { display: 'flex', flexDirection: 'column' },
		leftCol: { flex: 1, marginRight: '0.5em' },
		rightCol: { flex: 1, marginLeft: '0.5em' },
		singleCol: { marginTop: '1em', marginBottom: '1em' },
		grid: {
			display: 'grid',
			gridTemplateColumns: isXSmall
				? '1fr'
				: isSmall
				? 'repeat(3, 1fr)'
				: 'repeat(5, 1fr)',
			gap: '2rem',
			padding: '2rem',
			// backgroundColor: 'steelblue',
			height: '100%',
			borderRadius: '0.5rem',
		},
		gridCell: {
			aspectRatio: '1 / 1',
			backgroundColor: 'white',
			borderRadius: '0.5rem',
			display: 'flex',
			flexDirection: 'column',
			padding: '10%',
			justifyContent: 'space-around',
			alignItems: 'center',
			textDecoration: 'none',
			color: 'black',
			boxShadow: '0 0 8px 1px #53459e44',
			border: 'none',
			cursor: 'pointer',
		},
	};
	const { permissions } = usePermissions();

	return (
		<div style={styles.grid}>
			{menus?.map(
				({ key, Icon, title, path = null, type = null, permission = '' }) => {
					const isAuthorized = authCheck(permission, permissions);
					if (isAuthorized)
						return (
							<button
								onClick={() => onClick(path, type)}
								key={key}
								style={styles.gridCell}
							>
								<span>
									<Icon style={{ fontSize: '70px', color: '#53459e' }} />
								</span>
								<h4>{title}</h4>
							</button>
						);
					else return null;
				}
			)}
		</div>
	);
};

function authCheck(permission, permissions) {
	if (permissions?.['*']) return true;
	const rules = permissions
		? Object.keys(permissions)?.map((key) => {
				if (permissions?.[key]) return key;
				else return '';
		  })
		: [];
	if (rules?.find((rule) => rule.includes(permission))) return true;
	return false;
}

const menus = [
	// {
	// 	key: 1,
	// 	title: 'Authority',
	// 	Icon: (props) => <AccountBalance {...props} />,
	// },
	{
		key: 2,
		title: 'Admin',
		Icon: (props) => <Settings {...props} />,
		path: '/counter',
		type: 'admin',
		permission: 'admin',
	},
	{
		key: 3,
		title: 'Registration',
		Icon: (props) => <Newspaper {...props} />,
		path: '/ticket/create',
		type: 'registration',
		permission: 'registration',
	},
	{
		key: 4,
		title: 'OPD',
		Icon: (props) => <LocalHospital {...props} />,
		permission: 'opd',
	},
	{
		key: 5,
		title: 'Pharmacy',
		Icon: (props) => <Vaccines {...props} />,
		path: '/pharmacyBilling/create',
		type: 'pharmacy',
		permission: 'pharmacy',
	},
	{
		key: 6,
		title: 'Radiology ',
		Icon: (props) => <Apartment {...props} />,
		permission: 'radiology',
	},
	{
		key: 9,
		title: 'Inventory ',
		Icon: (props) => <Inventory {...props} />,
		permission: 'inventory',
	},
	{
		key: 7,
		title: 'Pathology',
		Icon: (props) => <Biotech {...props} />,
		// permission: 'pathology',
		type: 'pathology',
		path: '/pathologyReferal',
	},
	{
		key: 8,
		title: 'In-Patient',
		Icon: (props) => <Hotel {...props} />,
		permission: 'inpatient',
	},
];

export default Dashboard;
