import { Abc } from '@mui/icons-material';
import GenericNamesCreate from './GenericNamesCreate';
import GenericNamesEdit from './GenericNamesEdit';
import GenericNamesList from './GenericNamesList';

const resource = {
	list: GenericNamesList,
	create: GenericNamesCreate,
	edit: GenericNamesEdit,
	icon: Abc,
};

export default resource;
