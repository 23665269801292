import BrandList from './BrandList';
import BrandCreate from './BrandCreate';
import BrandEdit from './BrandEdit';
import { Extension } from '@mui/icons-material';

const resource = {
	list: BrandList,
	create: BrandCreate,
	edit: BrandEdit,
	icon: Extension,
};

export default resource;
