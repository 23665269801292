import React from "react";
import { Box, Typography } from "@mui/material";
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  useTranslate,
} from "react-admin";
import CustomAutoInput from "../../elements/CustomAutoInput";
import ShiftSelect from "../../elements/ShiftSelect";
import { PALIKADATA } from "../../_data/district";
import { useFormContext } from "react-hook-form";

const province = Object.keys(PALIKADATA).map((dis) => ({ id: dis, name: dis }));

const getDistricts = (province) => {
  if (province) {
    return Object.keys(PALIKADATA[province]).map((palika) => ({
      id: palika,
      name: palika,
    }));
  }
};
const getLocalBodies = (province, district) => {
  if (province && district) {
    return PALIKADATA[province][district].map((palika) => ({
      id: palika["title_en"],
      name: palika["title_en"],
    }));
  } else {
    return [];
  }
};

export default function FollowUpForm() {
  const { setValue } = useFormContext();

  function onPatientSelected(e) {
    setValue("p.id", e.id);
    setValue("p.firstName", e.firstName);
    setValue("p.middleName", e?.middleName);
    setValue("p.lastName", e?.lastName);
    setValue("p.age", e?.age);
    setValue("p.ageType", e?.ageType);
    setValue("p.gender", e?.gender);
    setValue("p.province", e?.province);
    setValue("p.district", e?.district);
    setValue("p.local", e?.local);
    setValue("p.ward", e?.ward);
    setValue("p.mobile", e?.mobile);
    setValue("ticketType", e?.ticketType);
    setValue("department", e?.department);
    setValue("charity", e?.charity);
  }

  return (
    <>
      <Box width="100%">
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <ReferenceInput
              label="Patient"
              source="patient"
              reference="patient"
            >
              <CustomAutoInput
                optionText="firstName"
                optionValue="id"
                fullWidth
                isRequired
                size="small"
                onSelect={onPatientSelected}
              />
            </ReferenceInput>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <ReferenceInput
              label="Ticket Type"
              source="ticketType"
              reference="ticketType"
            >
              <CustomAutoInput
                optionText="title"
                optionValue="id"
                fullWidth
                isRequired
                size="small"
              />
            </ReferenceInput>
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <ShiftSelect />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="nshiNo" fullWidth />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <ReferenceInput
              label="Department"
              source="department"
              reference="department"
            >
              <AutocompleteInput
                optionText="title"
                optionValue="id"
                fullWidth
                isRequired
                size="small"
              />
            </ReferenceInput>
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <ReferenceInput
              label="Charity"
              source="charity"
              reference="charity"
            >
              <AutocompleteInput
                optionText="title"
                optionValue="id"
                fullWidth
                size="small"
              />
            </ReferenceInput>
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="insuranceClaimCode" fullWidth />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              source="p.firstName"
              label="First Name"
              isRequired
              fullWidth
            />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="p.middleName" label="Middle Name" fullWidth />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              source="p.lastName"
              label="Last Name"
              isRequired
              fullWidth
            />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              type="number"
              source="p.age"
              label="Age"
              isRequired
              fullWidth
            />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <AutocompleteInput
              source="p.ageType"
              label="Age Type"
              isRequired
              fullWidth
              choices={[
                { id: "years", name: "years" },
                { id: "months", name: "months" },
                { id: "days", name: "days" },
              ]}
              size="small"
            />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <AutocompleteInput
              source="p.gender"
              label="Gender"
              isRequired
              fullWidth
              choices={[
                { id: "male", name: "male" },
                { id: "female", name: "female" },
                { id: "others", name: "others" },
              ]}
              size="small"
            />
          </Box>
        </Box>

        <Separator />

        <SectionTitle label="resources.customers.fieldGroups.address" />
        <Box
          display={{
            xs: "block",
            sm: "flex",
            width: "100%",
            marginTop: "15px",
          }}
        >
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <AutocompleteInput
              source="p.province"
              label="Province"
              choices={province}
              fullWidth
              size="small"
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <AutocompleteInput
                  source="p.district"
                  label="District"
                  choices={getDistricts(formData?.p?.province)}
                  isRequired
                  {...rest}
                  size="small"
                />
              )}
            </FormDataConsumer>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <AutocompleteInput
                  source="p.local"
                  label="Local"
                  choices={getLocalBodies(
                    formData?.p?.province,
                    formData?.p?.district
                  )}
                  isRequired
                  {...rest}
                  size="small"
                />
              )}
            </FormDataConsumer>
          </Box>
          <Box flex={1}>
            <TextInput source="p.ward" label="Ward" isRequired fullWidth />
          </Box>
        </Box>
        <Separator />

        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="p.mobile" label="Mobile" fullWidth />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <ReferenceInput label="doctor" source="doctor" reference="doctor">
              <AutocompleteInput
                optionText="name"
                optionValue="id"
                fullWidth
                size="small"
              />
            </ReferenceInput>
          </Box>
        </Box>

        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="amount" fullWidth />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="discount" fullWidth />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="tenderAmount" fullWidth />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput source="changeAmount" fullWidth />
          </Box>
        </Box>

        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <AutocompleteInput
              source="invoiceType"
              isRequired
              fullWidth
              choices={[
                { id: "cash", name: "cash" },
                { id: "credit", name: "credit" },
              ]}
              size="small"
            />
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <ReferenceInput
              label="counter"
              source="counter"
              reference="counter"
            >
              <AutocompleteInput
                optionText="title"
                optionValue="id"
                fullWidth
                isRequired
                size="small"
              />
            </ReferenceInput>
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="remarks" fullWidth />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;
