import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import CashDepositForm from './CashDepositForm';

export const validateForm = (values) => {
	const errors = {};
	// if (!values.name) {
	// 	errors.name = 'ra.validation.required';
	// }
	return errors;
};

const CashDepositCreate = () => (
	<Create resource="pharmacyCashDeposit" redirect="/cashDeposit">
		<SimpleForm validate={validateForm}>
			<SectionTitle label="pharmacy.account.cashDeposit" />
			<CashDepositForm />
		</SimpleForm>
	</Create>
);

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default CashDepositCreate;
