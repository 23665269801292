import AccountLedger from './accountLedger';
import PatientCustomerLedger from './patientCustomerLedger';
import ProductLedger from './productLedger';
import UserwiseSummary from './userwiseSummary';
import VendorLedger from './vendorLedger';

export const userwiseSummary = {
	list: UserwiseSummary,
	icon: null,
};

export const patientCustomLedger = {
	list: PatientCustomerLedger,
	icon: null,
};

export const accountLedger = {
	list: AccountLedger,
	icon: null,
};

export const productLedger = {
	list: ProductLedger,
	icon: null,
};

export const vendorLedger = {
	list: VendorLedger,
	icon: null,
};
