import { Datagrid, List, TextField } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import SectionTitle from '../../../../elements/SectionTitle';

const BrandList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	//   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

	return (
		<>
			<SectionTitle label="pharmacy.brand.title" />
			<List filters={undefined} sort={{}} perPage={25}>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="name" title="Brand Name" />
						<TextField label="Generic" source="generic.title" />
						<TextField
							label="Product Category"
							source="productCategory.title"
						/>
						<TextField source="salesUnit" />
						<TextField source="form" />
						<TextField source="defaultFrequency" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default BrandList;
