import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { validateForm } from './BrandBulkCreate';
import BrandBulkForm from './BrandBulkForm';

const BrandBulkEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/bulkBrand">
			<SimpleForm validate={validateForm}>
				<div>
					<Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
						<Grid item xs={12} md={8}>
							<Typography variant="h6" gutterBottom>
								{translate('pharmacy.bulkBrand.title')}
							</Typography>
							<BrandBulkForm />
						</Grid>
					</Grid>
				</div>
			</SimpleForm>
		</Edit>
	);
};

export default BrandBulkEdit;
