import * as React from "react";
import { Box } from "@mui/material";
import {
  // AppBar,
  // Menu,
  Sidebar,
} from "react-admin";
import { AppBar, Menu } from ".";
import { useSidebarContext } from "../_contexts/SidebarContext";

const CustomLayout = ({ children, dashboard }) => {
  const { menu } = useSidebarContext();

  return (
    <Box
      display="flex"
      flexDirection="column"
      zIndex={1}
      minHeight="100vh"
      backgroundColor="theme.palette.background.default"
      position="relative"
    >
      <Box display="flex" flexDirection="column">
        <AppBar />
        <Box display="flex" flexGrow={1} maxWidth={"100vw"} overflow={"hidden"}>
          {menu && Object.keys(menu)?.length > 0 && (
            <Sidebar>
              <Menu hasDashboard={!!dashboard} />
            </Sidebar>
          )}
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            p={3}
            paddingLeft={5}
            overflow={"scroll"}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomLayout;
