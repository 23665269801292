import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { camelToFlat } from '../_helper';

const CustomTextInput = ({
	source,
	label,
	value = '',
	onChange,
	onBlur,
	isRequired = false,
	...rest
}) => {
	const [formValue, setFormValue] = useState('');

	const {
		field,
		fieldState: { isTouched, invalid, error },
		formState: { isSubmitted },
	} = useController({ name: source, required: isRequired });

	useEffect(() => {
		setFormValue(value);
	}, [value]);

	return (
		<TextField
			{...field}
			required={isRequired}
			onChange={(e) => {
				field.onChange(e);
				setFormValue(e.target.value);
				onChange && onChange(e);
			}}
			// onBlur={(e) => {
			// field.onBlur(e);
			// setFormValue(e.target.value);
			// onBlur && onBlur(e);
			// }}
			value={formValue}
			label={label || camelToFlat(source)}
			error={(isTouched || isSubmitted) && invalid}
			helperText={(isTouched || isSubmitted) && invalid ? error?.message : ''}
			{...rest}
			size="small"
		/>
	);
};

export default CustomTextInput;
