import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { Box } from '@mui/material';
import SectionTitle from '../../../../elements/SectionTitle';
import PaymentForm from './PaymentForm';

export default function PaymentEdit() {
	return (
		<Edit>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label={'pharmacy.account.receive'} />
				<SimpleForm>
					<PaymentForm />
				</SimpleForm>
			</Box>
		</Edit>
	);
}
