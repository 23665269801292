import { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  colors,
} from "@mui/material";
import React from "react";
import SectionTitle from "../../../../elements/SectionTitle";
import { Close, FileDownload } from "@mui/icons-material";
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  useGetList,
} from "react-admin";

export default function MonthlyReportList() {
  const [filter, setFilter] = useState({
    fiscalYear: null,
    month: null,
    year: null,
  });

  const { data, isLoading } = useGetList(`registrationReport/monthlyReport`, {
    filter: {
      ...(filter?.fiscalYear?.id &&
        filter?.month?.id && {
          // fiscalYear: filter?.fiscalYear?.id,
          month: filter?.month?.id,
          year: filter?.year,
        }),
    },
  });

  function handleDownload() {
    fetch(
      process.env.REACT_APP_BACKEND_URL +
        `registrationReport/monthlyReport/pdf?filter=${JSON.stringify({})}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("auth"))?.access
          }`,
        },
      }
    )
      .then(async (res) => await res.json())
      .then((res) => {
        console.log(res);
        if (res?.url) {
          const a = document.createElement("a");
          a.target = "_blank";
          a.href = res?.url;
          a.download = "salesSummary";
          a.click();
        }
      });
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <SimpleForm toolbar={false}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <ReferenceInput source="fiscalYear" reference="fiscalYear">
              <AutocompleteInput
                optionText="title"
                onChange={(value, record) =>
                  setFilter((prev) => ({
                    ...prev,
                    fiscalYear: record,
                    year: prev.month
                      ? parseInt(prev.month) > 3
                        ? Number(record.title.split("/")[0])
                        : Number("20" + record.title.split("/")[1])
                      : null,
                  }))
                }
                value={filter?.fiscalYear?.id}
              />
            </ReferenceInput>
            <AutocompleteInput
              source="month"
              choices={[
                { id: "04", name: "श्रावन" },
                { id: "05", name: "भाद्र" },
                { id: "06", name: "आषाढ" },
                { id: "07", name: "कार्तिक" },
                { id: "08", name: "मङ्सिर" },
                { id: "09", name: "पुष" },
                { id: "10", name: "माघ" },
                { id: "11", name: "फाल्गुन" },
                { id: "12", name: "चैत्र" },
                { id: "01", name: "वैशाख" },
                { id: "02", name: "ज्येष्ठ" },
                { id: "03", name: "आषाढ" },
              ]}
              onChange={(value, record) =>
                setFilter((prev) => ({
                  ...prev,
                  month: record,
                  year: prev.fiscalYear
                    ? parseInt(record.id) > 3
                      ? Number(prev.fiscalYear.title.split("/")[0])
                      : Number("20" + prev.fiscalYear.title.split("/")[1])
                    : null,
                }))
              }
              value={filter?.month?.id}
            />
          </Box>
        </SimpleForm>
        <Box sx={{ "& button": { ml: 1 } }}>
          <Button
            startIcon={<Close />}
            onClick={() =>
              setFilter({
                month: null,
                fiscalYear: null,
                year: null,
              })
            }
          >
            Clear Filter
          </Button>
          <Button
            startIcon={<FileDownload />}
            variant="contained"
            onClick={handleDownload}
          >
            Export to PDF
          </Button>
        </Box>
      </Box>
      <SectionTitle
        label="Public Hospital Monthly Report"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          color: "primary.main",
        }}
      />
      <Box>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Fiscal Year</TableCell>
                <TableCell>
                  {filter?.fiscalYear?.title || "20..../20...."}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>To</TableCell>
                <TableCell></TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>HF Code:</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Reference No:</TableCell>
                <TableCell></TableCell>
                <TableCell colSpan={3} sx={{ fontWeight: "bold" }}>
                  Subject: Submission of Monthly Report on Hospital Services:
                </TableCell>
                <TableCell>
                  {filter?.year
                    ? `M ${filter?.month?.id}/Y ${filter?.year}`
                    : "M..../Y 20...."}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? colors.common.black
                    : colors.grey[100],
              })}
            >
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                  Hospital Services
                </TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    borderLeft: `2px solid ${colors.grey[300]}`,
                    textAlign: "center",
                  }}
                >
                  Emergency Services
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>Age Group</TableCell>
                <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                  New Clients Served
                </TableCell>
                <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                  Total Clients Served
                </TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    borderLeft: `2px solid ${colors.grey[300]}`,
                    textAlign: "center",
                  }}
                >
                  Total Clients Served
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Female</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Male</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Other</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Female</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Male</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Other</TableCell>
                <TableCell
                  sx={{
                    borderLeft: `2px solid ${colors.grey[300]}`,
                    textAlign: "center",
                  }}
                >
                  Female
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>Male</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Other</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                Object.entries(data?.[0] || {})
                  .sort()
                  .map(
                    ([key, value]) =>
                      !["id", "total"].includes(key) && (
                        <TableRow key={key}>
                          <TableCell>{key} Years</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value?.newPatient?.gender?.female || "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value?.newPatient?.gender?.male || "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value.newPatient?.gender?.others || "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value.totalPatient?.gender?.female || "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value.totalPatient?.gender?.male || "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value.totalPatient?.gender?.others || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              borderLeft: `2px solid ${colors.grey[300]} !important`,
                            }}
                          >
                            {value?.emergency?.gender?.female || "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value?.emergency?.gender?.male || "-"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {value?.emergency?.gender?.others || "-"}
                          </TableCell>
                        </TableRow>
                      )
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
