import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { validateForm } from './VendorsCreate';
import VendorsForm from './VendorsForm';

const VendorsEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/vendors">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.vendors.title')}
				</Typography>
				<VendorsForm />
			</SimpleForm>
		</Edit>
	);
};

export default VendorsEdit;
