import { Box } from '@mui/material';
import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import SectionTitle from '../../../../../elements/SectionTitle';
import ReferralForm from './referralForm';

export default function ReferralEdit() {
	return (
		<Edit>
			<Box p={3} pb={0}>
				<SectionTitle label="pathology.settings.referral" />
			</Box>
			<SimpleForm>
				<ReferralForm />
			</SimpleForm>
		</Edit>
	);
}
