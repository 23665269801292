import { Box } from '@mui/material';
import React from 'react';
import { Datagrid, List, TextField, SearchInput, TextInput } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import NepaliDateInput from '../../../../elements/NepaliDateInput';

export default function BillSearch() {
	return (
		<List
			resource="pharmacyBilling"
			filters={[
				<TextInput source="billNo" alwaysOn sx={{ marginBottom: '1rem' }} />,
				<TextInput
					source="name"
					label="Patient Name"
					sx={{ marginBottom: '1rem' }}
					alwaysOn
				/>,
				<NepaliDateInput
					source="date.$gte"
					defaultValue=""
					label="Date From"
					alwaysOn
				/>,
				<NepaliDateInput
					source="date.$lte"
					defaultValue=""
					label="Date To"
					alwaysOn
				/>,
			]}
			filter={{
				select:
					'billNo date name basicAmount discountAmount total -brands.brand',
			}}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.billSearch" />
			</Box>
			<Datagrid>
				<TextField source="billNo" />
				<TextField source="date" />
				<TextField source="name" label="Patient Name" />
				<TextField source="basicAmount" label="Sub Amount" />
				<TextField source="discountAmount" />
				<TextField source="total" label="Net Amount" />
				<TextField source="createdBy.fullname_en" label="Billed By" />
			</Datagrid>
		</List>
	);
}
