import { ProductionQuantityLimits } from '@mui/icons-material';
import BrandBulkCreate from './BrandBulkCreate';
import BrandBulkEdit from './BrandBulkEdit';
import BrandBulkList from './BrandBulkList';

const resource = {
	list: BrandBulkList,
	create: BrandBulkCreate,
	edit: BrandBulkEdit,
	icon: ProductionQuantityLimits,
};

export default resource;
