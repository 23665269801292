import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";

import BillingCreate from "./BillingCreate";
import BillingList from "./BillingList";
import BillingEdit from "./BillingEdit";

const resource = {
  create: BillingCreate,
  list: BillingList,
  icon: ReceiptLongRoundedIcon,
  edit: BillingEdit,
};

export default resource;
