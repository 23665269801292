import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

import TicketTypeList from "./TicketTypeList";
import TicketTypeCreate from "./TicketTypeCreate";
import TicketTypeEdit from "./TicketTypeEdit";

const resource = {
  list: TicketTypeList,
  create: TicketTypeCreate,
  edit: TicketTypeEdit,
  icon: ConfirmationNumberIcon,
};

export default resource;
