import { Box } from '@mui/material';
import React from 'react';
import {
	AutocompleteInput,
	Datagrid,
	List,
	SearchInput,
	TextField,
} from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';

export default function StockLedger() {
	return (
		<List
			filters={[
				<AutocompleteInput source="year" choices={[]} alwaysOn />,
				<AutocompleteInput source="month" choices={[]} alwaysOn />,
				<SearchInput source="q" alwaysOn />,
			]}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.stockLedger" />
			</Box>
			<Datagrid>
				<TextField source="brand" />
				<TextField source="batchNo" />
				<TextField source="expiryDate" />
				<TextField source="openingQty" />
				<TextField source="openingValue" />
				<TextField source="stockInQty" />
				<TextField source="stockInValue" />
				<TextField source="stockOutQty" />
				<TextField source="stockOutValue" />
				<TextField source="balanceQty" />
				<TextField source="balanceValue" />
			</Datagrid>
		</List>
	);
}
