import ReceiveList from './ReceiveList';
import ReceiveCreate from './ReceiveCreate';
import ReceiveEdit from './ReceiveEdit';

const receive = {
	list: ReceiveList,
	create: ReceiveCreate,
	edit: ReceiveEdit,
	icon: null,
};

export default receive;
