import { Category } from '@mui/icons-material';
import BrandCategoryCreate from './BrandCategoryCreate';
import BrandCategoryEdit from './BrandCategoryEdit';
import BrandCategoryList from './BrandCategoryList';

const resource = {
	list: BrandCategoryList,
	create: BrandCategoryCreate,
	edit: BrandCategoryEdit,
	icon: Category,
};

export default resource;
