import {
  Edit,
  SimpleForm,
  useTranslate,
  useGetList,
  Toolbar,
  SaveButton,
  DeleteButton,
  useUpdate,
} from "react-admin";
import { Box, Typography } from "@mui/material";
import PatientAside from "./PatientAside";
import { useSidebarContext } from "../../_contexts/SidebarContext";
import { useEffect, useState } from "react";
import PatientForm from "./PatientForm";
import handlePrint from "../../_helper/print";

import { useFormContext } from "react-hook-form";

export const validateForm = (values) => {
  const errors = {};
  return errors;
};

const PatientEdit = () => {
  const [ticketType, setTicketType] = useState(undefined);
  console.log(ticketType, "ticketType");
  const [search, setSearch] = useState("");
  const tickets = useGetList("ticket", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "createdAt", order: "DESC" },
    filter: { q: search },
    debounce: 500,
  });

  const { dispatch } = useSidebarContext();
  useEffect(() => {
    dispatch && dispatch({ type: "registration" });
  }, [dispatch]);
  return (
    <Edit
      aside={
        <PatientAside {...tickets} setSearch={setSearch} search={search} />
      }
      redirect={false}
      transform={(data) => {
        const tempData = {
          ...data,
          amount: data.amount || 0,
          discount: data.discount || 0,
          tenderAmount: data.tenderAmount || 0,
          changeAmount: data.changeAmount || 0,
          ticketType: ticketType?.id || data.ticketType.id,
        };
        return tempData;
      }}
      // mutationOptions={{
      //   onSuccess: (data) => {
      //     data?.print && handlePrint(data?.print);
      //   },
      // }}
    >
      <SimpleForm
        validate={validateForm}
        toolbar={<UserEditToolbar tickets={tickets} />}
        sanitizeEmptyValues
      >
        <SectionTitle label="patient.patient" />
        <PatientForm ticketType={ticketType} setTicketType={setTicketType} />
      </SimpleForm>
    </Edit>
  );
};

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const UserEditToolbar = ({ tickets, ...props }) => {
  const [update] = useUpdate();
  const { getValues } = useFormContext();

  function handleClick() {
    const { id, ...data } = getValues();
    update(
      "ticket",
      { id, data },
      {
        onSuccess: (data) => {
          data?.print && handlePrint(data?.print);
        },
      }
    );
  }
  return (
    <Toolbar
      {...props}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton type="button" onClick={handleClick} alwaysEnable />
      <DeleteButton />
    </Toolbar>
  );
};

const Separator = () => <Box pt="1em" />;

export default PatientEdit;
