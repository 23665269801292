import { Box } from '@mui/material';
import { FileInput, FileField } from 'react-admin';

export default function TemplateForm({ accept }) {
	return (
		<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
			<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
				<FileInput source="bill" accept={accept}>
					<FileField source="src" title="title" />
				</FileInput>
			</Box>
			<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
				<FileInput source="ticket" accept={accept}>
					<FileField source="src" title="title" />
				</FileInput>
			</Box>
		</Box>
	);
}
