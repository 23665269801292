import CountertopsRoundedIcon from "@mui/icons-material/CountertopsRounded";

import CounterList from "./CounterList";
import CounterCreate from "./CounterCreate";
import CounterEdit from "./CounterEdit";

const resource = {
  list: CounterList,
  create: CounterCreate,
  edit: CounterEdit,
  icon: CountertopsRoundedIcon,
};

export default resource;
