import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import BrandForm from '../master/brand/BrandForm';
import { Create, SimpleForm } from 'react-admin';

export default function BrandCreateModal({
	modalData,
	handleClose,
	handleOpen,
	onBrandAdded,
}) {
	console.log('modalData', modalData);
	function onSuccess(data) {
		onBrandAdded && onBrandAdded(data);
		handleClose();
	}

	return (
		<>
			<Modal
				open={modalData.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Add New Brand
					</Typography>
					<Create
						resource="brand"
						redirect={false}
						mutationOptions={{ onSuccess }}
					>
						<SimpleForm defaultValues={{ name: modalData?.value?.label }}>
							<Box sx={{ padding: 2 }}>
								<BrandForm />
							</Box>
						</SimpleForm>
					</Create>
				</Box>
			</Modal>
		</>
	);
}

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 400,
	bgcolor: 'background.paper',
	color: 'text.primary',
	// border: '2px solid #000',
	// boxShadow: 24,
	p: 2,
};
