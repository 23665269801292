import { Box } from '@mui/material';
import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import SectionTitle from '../../../../../elements/SectionTitle';

export default function LabDeptList() {
	return (
		<List>
			<Box p={3} pb={0}>
				<SectionTitle label="pathology.settings.labDept" />
			</Box>
			<Datagrid rowClick="edit">
				<TextField source="name" />
				<TextField source="reportFooter" />
				<TextField source="break" />
				<TextField source="position" />
			</Datagrid>
		</List>
	);
}
