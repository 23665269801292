import {
	Create,
	SimpleForm,
	useTranslate,
	useGetList,
	// DisabledInput,
} from 'react-admin';
import { Typography } from '@mui/material';
import PatientAside from './PatientAside';
import { useSidebarContext } from '../../_contexts/SidebarContext';
import { useEffect, useState } from 'react';
import FollowUpForm from './FollowUpForm';
import handlePrint from '../../_helper/print';

export const validateForm = (values) => {
	const errors = {};
	return errors;
};

const VisitorCreate = () => {
	const [search, setSearch] = useState('');
	const tickets = useGetList('ticket', {
		pagination: { page: 1, perPage: 10 },
		sort: { field: 'createdAt', order: 'DESC' },
		debounce: 500,
		filter: { q: search },
	});

	const { dispatch } = useSidebarContext();
	useEffect(() => {
		dispatch && dispatch({ type: 'registration' });
	}, [dispatch]);
	return (
		<Create
			aside={
				<PatientAside {...tickets} setSearch={setSearch} search={search} />
			}
			transform={(data) => {
				let body = { ...data };
				if (!body?.amount) body.amount = body?.ticketType?.rate || 0;
				body.patient = data.p;
				delete body.p;
				return body;
			}}
			resource="ticket/followup"
			redirect={false}
			mutationOptions={{
				onSuccess: (data) => {
					handlePrint(data?.print);
				},
			}}
		>
			<SimpleForm
				// sx={{ maxWidth: 500 }}
				// Here for the GQL provider
				defaultValues={{}}
				validate={validateForm}
				sanitizeEmptyValues
			>
				<SectionTitle label="patient.followup" />
				<FollowUpForm />
			</SimpleForm>
		</Create>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default VisitorCreate;
