import { useEffect } from "react";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useList,
} from "react-admin";

const CancelTable = ({ list = [], setSeletedRow }) => {
  const listContext = useList({
    data: list.map((l, i) => ({ ...l, id: l._id })),
    sort: { field: "name", order: "ASC" },
  });

  useEffect(() => {
    setSeletedRow(listContext.selectedIds);
    return () => listContext.onUnselectItems([]);
  }, []);

  return (
    <ListContextProvider
      value={{
        ...listContext,
        onSelect: (data) => {
          setSeletedRow(data);
          listContext.onSelect(data);
        },
        onToggleItem: (data) => {
          setSeletedRow((prev) => {
            const tempPrev = [...prev];
            const index = prev.indexOf(data);
            if (index !== -1) {
              tempPrev.splice(index, 1);
            } else {
              tempPrev.push(data);
            }
            return tempPrev;
          });
          listContext.onToggleItem(data);
        },
        onUnselectItems: (data) => {
          setSeletedRow([]);

          listContext.onUnselectItems(data);
        },
      }}
    >
      <Datagrid
        sx={{ width: "100%" }}
        bulkActionButtons={<></>}
        resource="test"
      >
        <FunctionField
          label="Particulars"
          render={(record) => (
            <TextField
              source="service.name"
              sx={{ textDecoration: record.cancelled && "line-through" }}
            />
          )}
        />

        <FunctionField
          label="Quantity"
          render={(record) => (
            <TextField
              source="quantity"
              sx={{ textDecoration: record.cancelled && "line-through" }}
            />
          )}
        />

        <FunctionField
          label="Rate"
          render={(record) => (
            <TextField
              source="rate"
              sx={{ textDecoration: record.cancelled && "line-through" }}
            />
          )}
        />

        <FunctionField
          label="Basic Amount"
          render={(record) => (
            <TextField
              source="basicAmount"
              sx={{ textDecoration: record.cancelled && "line-through" }}
            />
          )}
        />
        <FunctionField
          label="Discount"
          render={(record) => (
            <TextField
              source="discount"
              sx={{ textDecoration: record.cancelled && "line-through" }}
            />
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export default CancelTable;
