import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
	Datagrid,
	TextField,
	ListContextProvider,
	useList,
	useGetOne,
	useGetList,
	useCreate,
	SimpleForm,
	CheckboxGroupInput,
} from 'react-admin';
import camelToFlat from '../../../_helper/camelToFlat';

export default function RoleMgmt() {
	const { data } = useGetList('users');
	const listContext = useList({ data });
	const [selectedUser, setSelectedUser] = useState(null);
	return (
		<>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)',
					columnGap: 2,
					width: '100%',
				}}
			>
				<ListContextProvider value={listContext}>
					<Datagrid
						title="Users"
						bulkActionButtons={false}
						rowClick={(id, resource, record) => setSelectedUser(record)}
					>
						<TextField source="fullname_en" label="User" />
					</Datagrid>
				</ListContextProvider>
				{selectedUser && <Permissions user={selectedUser} />}
			</Box>
		</>
	);
}
function Permissions({ user }) {
	console.log(user);
	const navigate = useNavigate();
	const { data, isLoading, refetch } = useGetOne(`permission`, {
		id: user?.id,
	});
	const { data: pData } = useGetOne('permission', { id: '' });
	const [create] = useCreate(
		`permission`,
		{},
		{
			onSuccess: () => {
				refetch();
				// navigate('/users');
			},
		}
	);

	return (
		<Box sx={{ width: '100%', gridColumn: 'span 2 / span 2' }}>
			<SimpleForm
				sanitizeEmptyValues
				onSubmit={(data) => {
					const d = data?.permissions;
					for (let k in d) {
						d[k] = d[k]?.join('') || '';
					}
					create(`permission`, { data: { permission: d, user: user.id } });
				}}
			>
				{!isLoading &&
					pData?.permission?.map((permission, i) => (
						<Checkboxes
							permission={permission}
							data={data}
							key={user.id + permission.name}
						/>
					))}
			</SimpleForm>
		</Box>
	);
}

function Checkboxes({ permission, data }) {
	const formContext = useFormContext();
	useEffect(() => {
		formContext.setValue(
			`permissions`,
			data?.permission?.[permission.name]?.split('')
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formContext]);
	const choices = {
		d: 'Delete',
		r: 'View All',
		a: 'Update',
		w: 'Create',
		s: 'View One',
	};
	return (
		<Box sx={{ width: '100%' }}>
			<Typography fontWeight={600} color="primary.main">
				{permission.name
					.split('_')
					.map((v) => camelToFlat(v))
					.join(' ')}
			</Typography>
			<CheckboxGroupInput
				defaultValue={data?.permission?.[permission.name]?.split('')}
				source={`permissions.${permission.name}`}
				label="Permissions"
				choices={permission?.choices?.map((choice) => ({
					id: choice,
					name: choices[choice],
				}))}
			/>
		</Box>
	);
}
