import {
	Datagrid,
	FunctionField,
	List,
	TextField,
	useTranslate,
} from 'react-admin';
import { useMediaQuery, Typography } from '@mui/material';
import dayjs from 'dayjs';

const CashDepositList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.account.cashDeposit" />
			<List
				resource="pharmacyCashDeposit"
				filters={undefined}
				sort={{}}
				perPage={25}
			>
				{isXsmall ? null : (
					<Datagrid
						bulkActionButtons={false}
						optimized
						rowClick={false}
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<FunctionField
							label="Date"
							render={(record) => dayjs(record?.createdAt).format('YYYY/MM/DD')}
						/>
						<TextField label="Deposited By" source="user.fullname_en" />
						{/* <TextField source="voucherNo" /> */}
						{/* <TextField source="beforePaid" /> */}
						<TextField source="amount" />
						{/* <TextField source="balance" /> */}
						{/* <TextField source="receivedBy" /> */}
						<TextField source="approvedBy" />
						<TextField source="remarks" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default CashDepositList;
