import VaccinesRoundedIcon from "@mui/icons-material/VaccinesRounded";

import PatientCreate from "./PatientCreate";
import PatientEdit from "./PatientEdit";
import FollowUp from "./FollowUp";

const resource = {
  create: PatientCreate,
  icon: VaccinesRoundedIcon,
  edit: PatientEdit,
  followUp: FollowUp,
};

export default resource;
