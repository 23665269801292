import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { validateForm } from './FiscalYearCreate';
import FiscalYearForm from './FiscalYearForm';

const FiscalYearEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/fiscalYear">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('admin.fiscalYear')}
				</Typography>
				<FiscalYearForm />
			</SimpleForm>
		</Edit>
	);
};

export default FiscalYearEdit;
