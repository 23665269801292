import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import {
  Datagrid,
  TextField,
  useGetList,
  useList,
  ListContextProvider,
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  Button,
} from "react-admin";
import PdfViewer from "../../../elements/PdfViewer";
import handlePrint from "../../../_helper/print";
import { Print } from "@mui/icons-material";

export default function SampleCollectionList() {
  const { data, isLoading } = useGetList("billing", {
    filter: { "services.service.serviceModule": "lab" },
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const billingListContext = useList({ data });

  return (
    <>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
        <ListContextProvider value={billingListContext}>
          <Datagrid
            isLoading={isLoading}
            rowSx={(record) => ({
              backgroundColor: record?.id === selectedRow?.id ? grey[200] : "",
            })}
            rowClick={(_, __, record) => {
              setSelectedRow(record);
              return false;
            }}
            bulkActionButtons={false}
          >
            <TextField source="name" />
            <TextField source="billNo" />
            <TextField source="labNo" />
          </Datagrid>
        </ListContextProvider>
        {!isLoading && selectedRow ? (
          <SampleItemList selectedRow={selectedRow} />
        ) : (
          <Box></Box>
        )}
        {!isLoading && selectedRow && (
          <CollectedItemList billId={selectedRow?.id} />
        )}
      </Box>
    </>
  );
}

function SampleItemList({ selectedRow }) {
  const { data, isLoading } = useGetList(
    `billing/pathologySampleItems/${selectedRow?.id}`
  );
  const sampleItemContext = useList({
    // data: data?.filter((d) => !d.collected),
    data,
  });
  const PostToolBar = () => (
    <Toolbar>
      <SaveButton label="Collect" />
    </Toolbar>
  );
  return (
    <Box>
      <ListContextProvider value={sampleItemContext}>
        <Datagrid
          isLoading={isLoading && sampleItemContext?.isLoading}
          bulkActionButtons={<></>}
        >
          <TextField source="name" label="Test Items" />
        </Datagrid>
      </ListContextProvider>
      <Create
        resource="pathologySample"
        redirect={false}
        transform={(data) => {
          let body = { ...data };
          body.services = sampleItemContext.selectedIds;
          body.invoice = selectedRow?.id;
          return body;
        }}
      >
        <SimpleForm toolbar={<PostToolBar />}>
          <TextInput source="sampleRemarks" fullWidth multiline />
        </SimpleForm>
      </Create>
    </Box>
  );
}

function CollectedItemList({ billId }) {
  const { data, isLoading } = useGetList(`pathologySample`, {
    filter: { invoice: billId },
  });
  const collectedList = useList({
    data:
      data?.[0]?.services?.map((service) => ({
        ...service,
        id: service._id,
      })) || [],
  });

  console.log(data);

  return (
    <Box>
      <ListContextProvider value={collectedList}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="service.name" />
          <TextField source="sampleNo" />
        </Datagrid>
      </ListContextProvider>
      <Box sx={{ p: 2, backgroundColor: grey[300], height: "100%" }}>
        <PdfViewer loading={isLoading} url={data?.[0]?.print?.[0]} />
        <Button
          disabled={!data?.[0]?.print?.[0]}
          onClick={() => handlePrint(data[0].print[0])}
          startIcon={<Print />}
          sx={{ mt: 2 }}
          label="Print"
        />
      </Box>
    </Box>
  );
}
