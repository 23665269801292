import MedicationIcon from "@mui/icons-material/Medication";

import DoctorList from "./DoctorList";
import DoctorCreate from "./DoctorCreate";
import DoctorEdit from "./DoctorEdit";

const resource = {
  list: DoctorList,
  create: DoctorCreate,
  edit: DoctorEdit,
  icon: MedicationIcon,
};

export default resource;
