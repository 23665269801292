import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import ShiftForm from './ShiftForm';
import dayjs from 'dayjs';

export const validateForm = (values) => {
	const errors = {};
	if (!values.title) {
		errors.title = 'ra.validation.required';
	}
	return errors;
};

const DepartmentCreate = () => (
	<Create
		redirect="/shift"
		transform={(data) => {
			let body = { ...data };
			body.startTime = dayjs(data.startTime).format('HH:mm');
			body.endTime = dayjs(data.endTime).format('HH:mm');
			return body;
		}}
	>
		<SimpleForm
			sx={{ maxWidth: 500 }}
			defaultValues={{
				title: '',
			}}
			validate={validateForm}
		>
			<SectionTitle label="setting.menu.shift" />
			<ShiftForm />
		</SimpleForm>
	</Create>
);

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default DepartmentCreate;
