import {
	TextInput,
	ReferenceInput,
	ReferenceArrayInput,
	AutocompleteInput,
	NumberInput,
	BooleanInput,
	AutocompleteArrayInput,
} from 'react-admin';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export default function ServiceForm() {
	const { watch } = useFormContext();
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(4, 2fr)',
				gap: 2,
				width: '100%',
			}}
		>
			<TextInput source="name" isRequired fullWidth />
			<ReferenceInput
				label="Service Category"
				source="serviceCategory"
				reference="serviceCategory"
			>
				<AutocompleteInput
					optionText="title"
					optionValue="id"
					fullWidth
					isRequired
				/>
			</ReferenceInput>
			<TextInput source="code" isRequired fullWidth />
			<NumberInput source="rate" isRequired fullWidth />
			<AutocompleteInput
				source="serviceModule"
				choices={[
					{ id: 'lab', name: 'Lab' },
					{ id: 'inpatient', name: 'In Patient' },
				]}
			/>
			<TextInput source="printGroup" />
			<Box sx={{ gridColumn: 'span 2 / span 2' }} />
			<BooleanInput source="hasDynamicRate" />
			<BooleanInput source="isDoctorRequired" />
			<BooleanInput source="isPatientRequired" />
			<BooleanInput source="isDischargeItem" />
			<BooleanInput source="isServiceGroup" />
			{watch('isServiceGroup') && (
				<Box sx={{ gridColumn: 'span 3 / span 3' }}>
					<ReferenceArrayInput source="packageItems" reference="service">
						<AutocompleteArrayInput optionText="name" />
					</ReferenceArrayInput>
				</Box>
			)}
		</Box>
	);
}
