import {
	Datagrid,
	List,
	TextField,
	useTranslate,
	Button,
	ListContextProvider,
	useList,
	useListContext,
	useCreate,
} from 'react-admin';
import { Typography } from '@mui/material';
import { Remove } from '@mui/icons-material';

const ExpiryMgmtList = () => {
	return (
		<>
			<SectionTitle label="pharmacy.store.expirymgmt" />
			<List
				filters={undefined}
				resource="store/brand/expiry"
				sort={{}}
				perPage={25}
				title="Expiry Management"
			>
				<ExpandedList />
			</List>
		</>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default ExpiryMgmtList;

function ExpandedList() {
	const listContext = useListContext({});
	console.log('list context', listContext);
	const list = useList({
		data: listContext.data
			?.map((d) => d.items.map((item) => ({ ...item, name: d?.brand?.name })))
			.flat(),
	});

	return (
		<ListContextProvider
			value={{
				...list,
			}}
			source="items"
		>
			<Datagrid
				bulkActionButtons={
					<BulkActions data={list.selectedIds} refetch={listContext.refetch} />
				}
			>
				<TextField source="name" />
				<TextField source="batchNumber" />
				<TextField source="totalQty" />
				<TextField source="amount" />
				<TextField source="expiryDate" />
			</Datagrid>
		</ListContextProvider>
	);
}

function BulkActions({ data, refetch }) {
	const [create, { isLoading }] = useCreate('store/brand/expiry', {
		data: { id: data },
	});
	function handleSubmit() {
		create();
		refetch();
	}
	return (
		<Button
			size="small"
			color="error"
			disabled={isLoading}
			onClick={handleSubmit}
		>
			<>
				<Remove />
				Remove
			</>
		</Button>
	);
}
