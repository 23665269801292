import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { validateForm } from './ShiftCreate';
import ShiftForm from './ShiftForm';

const DepartmentEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/shift">
			<SimpleForm validate={validateForm}>
				<div>
					<Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
						<Grid item xs={12} md={12}>
							<Typography variant="h6" gutterBottom>
								{translate('setting.menu.shift')}
							</Typography>
							<ShiftForm />
						</Grid>
					</Grid>
				</div>
			</SimpleForm>
		</Edit>
	);
};

export default DepartmentEdit;
