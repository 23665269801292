import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {
	AutocompleteInput,
	DateInput,
	RadioButtonGroupInput,
	ReferenceInput,
	TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

export default function ReceiveForm() {
	const { watch } = useFormContext();
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				gap: 2,
				width: '100%',
			}}
		>
			<DateInput
				source="date"
				defaultValue={dayjs(Date.now()).format('MM/DD/YYYY')}
				fullWidth
			/>
			<Box sx={{ gridColumn: 'span 2 / span 2' }} />

			<RadioButtonGroupInput
				source="type"
				choices={[
					{ id: 'Patient', name: 'Patient' },
					{ id: 'Customer', name: 'Customer' },
					{ id: 'Vendor', name: 'Vendor' },
				]}
				defaultValue={'Patient'}
			/>
			<Box sx={{ gridColumn: 'span 2 / span 2' }} />

			<ReferenceInput
				source="receiveId"
				reference={
					watch('type') === 'Patient'
						? 'patient'
						: watch('type') === 'Vendor'
						? 'vendors'
						: watch('type') === 'Customer'
						? 'customers'
						: 'patient'
				}
			>
				<AutocompleteInput
					label={watch('type')}
					{...(watch('type') === 'Patient'
						? {
								optionText: (record) =>
									`${record?.firstName} ${
										record?.middleName ? record?.middleName + ' ' : ''
									}${record?.lastName}`,
						  }
						: { optionText: 'name' })}
					disabled={!watch('type')}
				/>
			</ReferenceInput>
			<TextInput source="amount" />
			<Box />
			<RadioButtonGroupInput
				source="transactionType"
				choices={[
					{ id: 'cash', name: 'cash' },
					{ id: 'cheque', name: 'cheque' },
				]}
			/>
			<Box sx={{ gridColumn: 'span 2 / span 2' }} />
			{watch('transactionType') === 'cheque' && (
				<>
					<TextInput source="chequeNo" />
					<Box sx={{ gridColumn: 'span 2 /span 2' }} />
				</>
			)}
			<TextInput source="voucherNo" />
			<TextInput source="remarks" multiline />
		</Box>
	);
}
