import { Datagrid, List, SearchInput, TextField } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import SectionTitle from '../../elements/SectionTitle';

const BillingList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	//   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

	return (
		<>
			<SectionTitle label="billing.billingList" />
			<List
				filters={[<SearchInput source="q" alwaysOn />]}
				filter={{
					select:
						'date name billNo age gender basicAmount discount total nshiNo claimCode charity',
				}}
				sort={{ field: 'createdAt', order: 'DESC' }}
				perPage={25}
			>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="billNo" />
						<TextField source="date" />
						<TextField source="name" />
						<TextField source="patient.patientId" label="Ticket No" />
						<TextField source="age" />
						<TextField source="gender" />
						<TextField source="basicAmount" label="Basic" />
						<TextField source="discount" />
						<TextField label="amount" source="total" />
						<TextField label="Billed By" source="createdBy.fullname_en" />
						<TextField source="nshiNo" />
						<TextField source="claimCode" />
						<TextField label="Charity" source="charity.title" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default BillingList;
