import { Box } from '@mui/material';
import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import LabServiceForm from './labServiceForm';

export default function LabServiceEdit() {
	return (
		<Edit>
			<Box p={2} pb={0}>
				<SectionTitle label="pathology.settings.labServices" />
			</Box>
			<SimpleForm>
				<LabServiceForm />
			</SimpleForm>
		</Edit>
	);
}
