import { createContext, useContext, useEffect, useReducer } from 'react';

import { admin, pharmacy, registration, pathology } from './SidebarMenus';

const SidebarContext = createContext({});

function reducer(state, action) {
	switch (action.type) {
		case 'admin':
			return {
				...state,
				menus: admin,
			};
		case 'registration':
			return {
				...state,
				menus: registration,
			};
		case 'pharmacy':
			return {
				...state,
				menus: pharmacy,
			};
		case 'pathology':
			return {
				...state,
				menus: pathology,
			};
		case 'no-menus':
			return {
				...state,
				menus: null,
			};

		default:
			return {
				menus: null,
			};
	}
}

const SidebarProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, { menus: null });
	useEffect(() => {
		const sidebarType = localStorage.getItem('sidebarType');
		dispatch({ type: sidebarType });
	}, []);
	// const { permission } = usePermissions();

	// console.log('sidebar permission', permission);

	return (
		<SidebarContext.Provider
			value={{
				menu: state?.menus,
				dispatch: dispatch || null,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};

export default SidebarProvider;

export function useSidebarContext() {
	const context = useContext(SidebarContext);
	return context;
}
