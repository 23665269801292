
    import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import BatchEditForm from './BatchEditForm';

export const validateForm = (values) => {
	const errors = {};
	if (!values.name) {
		errors.name = 'ra.validation.required';
	}
	return errors;
};

const BatchEditCreate = () => (
	<Create redirect="/batchEdit">
		<SimpleForm validate={validateForm}>
			<SectionTitle label="pharmacy.store.batchedit" />
			<BatchEditForm />
		</SimpleForm>
	</Create>
);

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default BatchEditCreate;

    