import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export default function ReferralList() {
	return (
		<List>
			<Datagrid rowClick="edit">
				<TextField source="name" />
				<TextField source="address" />
				<TextField source="phone" />
				<TextField source="email" />
			</Datagrid>
		</List>
	);
}
