import React from 'react';

function ValueEntrySheet() {
	return (
		<div>
			<div>value entry sheet</div>
		</div>
	);
}

const valueEntrySheet = {
	list: ValueEntrySheet,
	icon: null,
};

export default valueEntrySheet;
