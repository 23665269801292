import TemplateCreate from './templateCreate';
import TemplateEdit from './templateEdit';
import TemplateList from './templateList';

const pathologyTemplates = {
	list: TemplateList,
	create: TemplateCreate,
	edit: TemplateEdit,
	icon: null,
};

export default pathologyTemplates;
