import BatchEditCreate from "./BatchEditCreate";
import BatchEditList from "./BatchEditList";
import BatchEditEdit from "./BatchEditEdit";
import { BatchPrediction } from "@mui/icons-material";

const resource = {
  list: BatchEditList,
  create: BatchEditCreate,
  edit: BatchEditEdit,
  icon: BatchPrediction,
};

export default resource;
