import React from 'react';
import { Box } from '@mui/material';
import {
	AutocompleteInput,
	NumberInput,
	ReferenceInput,
	TextInput,
} from 'react-admin';

export default function LabServiceForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(4, 1fr)',
				gap: 2,
				width: '100%',
			}}
		>
			<TextInput source="name" />
			<ReferenceInput source="department" reference="pathologyDepartment">
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput source="sampleGroup" reference="pathologySampleGroup">
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<NumberInput source="position" />
			<TextInput source="footer" multiline />
		</Box>
	);
}
