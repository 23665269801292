import DebitNoteCreate from "./DebitNoteCreate";
import DebitNoteList from "./DebitNoteList";
import DebitNoteEdit from "./DebitNoteEdit";
import { Note } from "@mui/icons-material";

const resource = {
  list: DebitNoteList,
  create: DebitNoteCreate,
  edit: DebitNoteEdit,
  icon: Note,
};

export default resource;
