import { Admin, Resource, fetchUtils } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route } from 'react-router';

import authProvider from './authProvider';
import { Login } from './layout';
import CustomLayout from './layout/CustomLayout';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { lightTheme, darkTheme } from './layout/themes';
import routes from './routes';

// import dataProviderFactory from "./dataProvider";
import simpleRestProvider from 'ra-data-simple-rest';
import SidebarProvider from './_contexts/SidebarContext';

const i18nProvider = polyglotI18nProvider(
	(locale) => {
		if (locale === 'np') {
			return import('./i18n/np').then((messages) => messages.default);
		}

		// Always fallback on english
		return englishMessages;
	},
	'en',
	[
		{ locale: 'en', name: 'English' },
		{ locale: 'np', name: 'Nepali' },
	]
);

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}
	const { access } = JSON.parse(localStorage.getItem('auth'));
	options.headers.set('Authorization', `Bearer ${access}`);

	return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
	process.env.REACT_APP_BACKEND_URL,
	httpClient
);

const customDataProvider = {
	...dataProvider,
	create: (resource, params) => {
		console.log(resource, params);
		if (params.meta?.type !== 'file')
			return dataProvider.create(resource, params);

		let formData = new FormData();
		formData.append(params.data.key, params.data[params.data.key]?.rawFile);
		return httpClient(`${process.env.REACT_APP_BACKEND_URL}/${resource}`, {
			method: 'POST',
			body: formData,
		}).then(({ json }) => ({
			data: { ...params.data, id: json.id },
		}));
	},
};

const App = () => (
	<SidebarProvider>
		<Router>
			<Admin
				title=""
				dataProvider={customDataProvider}
				authProvider={authProvider}
				dashboard={Dashboard}
				loginPage={Login}
				layout={CustomLayout}
				i18nProvider={i18nProvider}
				disableTelemetry
				theme={lightTheme}
				darkTheme={darkTheme}
				defaultTheme="light"
				requireAuth={true}
			>
				{withPermission(routes)}
			</Admin>
		</Router>
	</SidebarProvider>
);

export default App;

const withPermission =
	(resources = []) =>
	(permission) => {
		let resourceOptions = resources.map((resource) => {
			const { list, create, edit, show, ...rest } = resource.routes;
			const { children = [] } = resource;
			if (permission?.['*'])
				return (
					<Resource name={resource.name} {...resource.routes}>
						{children?.length > 0 &&
							children.map((childRoute, i) => (
								<Route
									key={i}
									path={childRoute.path}
									element={childRoute.element}
								/>
							))}
					</Resource>
				);
			const allowed = permission?.[resource.permissionName];
			if (allowed) {
				let Resources = {
					...rest,
					...(allowed.includes('r') && { list }),
					...(allowed.includes('a') && { edit }),
					...(allowed.includes('w') && { create }),
					...(allowed.includes('s') && { show }),
				};

				return (
					<Resource name={resource.name} {...Resources}>
						{children?.length > 0 &&
							children.map((childRoute, i) => (
								<Route
									key={childRoute.key}
									path={childRoute.path}
									element={childRoute.element}
								/>
							))}
					</Resource>
				);
			} else return null;
		});
		return resourceOptions;
	};
