
  import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { validateForm } from './ExpiryMgmtCreate';
import ExpiryMgmtForm from './ExpiryMgmtForm';

const ExpiryMgmtEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/expiryMgmt">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.store.expirymgmt')}
				</Typography>
				<ExpiryMgmtForm />
			</SimpleForm>
		</Edit>
	);
};



export default ExpiryMgmtEdit;

  