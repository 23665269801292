import dayjs from 'dayjs';

export default function isTimeBetween(
	startTime,
	endTime,
	targetTime = dayjs(Date.now()).format('HH:mm')
) {
	// const format = "HH:mm";
	const startDate = Date.parse(`1970-01-01T${startTime}`);
	const endDate = Date.parse(`1970-01-01T${endTime}`);
	const targetDate = Date.parse(`1970-01-01T${targetTime}`);

	// Check if targetDate is within the range defined by startDate and endDate
	if (startDate <= endDate) {
		return targetDate >= startDate && targetDate <= endDate;
	} else {
		// If the time range spans different days, check if targetDate is outside the range
		return targetDate >= startDate || targetDate <= endDate;
	}
}
