import { AppBar, TitlePortal } from 'react-admin';
import { Box, useMediaQuery } from '@mui/material';

import Logo from './Logo.png';
import { Link } from 'react-router-dom';

const CustomAppBar = () => {
	const isLargeEnough = useMediaQuery((theme) => theme.breakpoints.up('sm'));
	return (
		<AppBar color="secondary" position="static" elevation={1}>
			{/* <TitlePortal /> */}
			{isLargeEnough && (
				<Link to="/" style={{ display: 'grid', placeContent: 'center' }}>
					<img src={Logo} style={{ height: '40px' }} />
				</Link>
			)}
			{isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
		</AppBar>
	);
};

export default CustomAppBar;
