import { Box } from '@mui/material';
import { FileInput, FileField } from 'react-admin';

export default function TemplateForm({ accept }) {
	return (
		<Box
			sx={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}
		>
			<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
				<FileInput source="labReport" accept={accept}>
					<FileField source="labReport" title="title" />
				</FileInput>
			</Box>
			<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
				<FileInput source="labSticker" accept={accept}>
					<FileField source="labSticker" title="title" />
				</FileInput>
			</Box>
			<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
				<FileInput source="onlineLabReport" accept={accept}>
					<FileField source="onlineLabReport" title="title" />
				</FileInput>
			</Box>
			<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
				<FileInput source="testTubeLabel" accept={accept}>
					<FileField source="testTubeLabel" title="title" />
				</FileInput>
			</Box>
		</Box>
	);
}
