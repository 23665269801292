import CurrentStock from './currentStock';
import StockLedger from './stockLedger';

export const currentStock = {
	list: CurrentStock,
	icon: null,
};

export const stockLedger = {
	list: StockLedger,
	icon: null,
};
