import { AutocompleteInput, useChoicesContext } from 'react-admin';

const CustomAutoInput = ({ onSelect, ...props }) => {
	const { allChoices } = useChoicesContext();

	return (
		<AutocompleteInput
			{...props}
			onChange={(e) => {
				onSelect &&
					onSelect(allChoices.find((choice) => choice.id === e) || {});
			}}
			size="small"
		/>
	);
};

export default CustomAutoInput;
