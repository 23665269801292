import {
  SimpleForm,
  useTranslate,
  ReferenceInput,
  Edit,
  TextInput,
  RecordContext,
} from "react-admin";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CustomAutoInput from "../../elements/CustomAutoInput";
import CancelTable from "./CancelTable";

const BillingCancel = () => {
  const [selectedRows, setSeletedRow] = useState([]);

  return (
    <Edit
      redirect="/billing"
      resource="billing"
      transform={(data) => {
        return { ...data, ids: selectedRows };
      }}
    >
      <RecordContext.Consumer>
        {(value) => {
          return (
            <SimpleForm
              // sx={{ maxWidth: 500 }}
              defaultValues={{}}
              sanitizeEmptyValues
            >
              <SectionTitle label="billing.billingName" />
              <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput disabled source="billNo" fullWidth />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput disabled source="charity.title" fullWidth />
                </Box>
              </Box>

              <Box
                display={{
                  xs: "block",
                  sm: "flex",
                  width: "100%",
                }}
                sx={{ marginBottom: "30px" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput disabled source="name" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput disabled type="number" source="age" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput disabled source="ageType" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput disabled source="gender" fullWidth />
                </Box>
              </Box>
              <CancelTable
                list={value.services}
                id={value.id}
                setSeletedRow={setSeletedRow}
              />
              <Box sx={{ width: "100%" }}>
                <p
                  style={{
                    textAlign: "right",
                    fontWeight: "600",
                    fontSize: "3rem",
                    lineHeight: 1,
                    margin: "0",
                    // fontFamily: 'monospace',
                  }}
                >
                  {value.total}
                </p>
              </Box>

              <Box
                display={{
                  xs: "block",
                  sm: "grid",
                  width: "100%",
                  marginBottom: "16px",
                }}
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput disabled source="doctor.name" fullWidth />

                  <Box
                    display={{
                      xs: "block",
                      sm: "grid",
                      width: "100%",
                    }}
                    sx={{
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "0.5em",
                    }}
                  >
                    <ReferenceInput
                      label="Shift"
                      source="shift"
                      reference="shift"
                    >
                      <CustomAutoInput
                        optionText="title"
                        optionValue="id"
                        fullWidth
                        isRequired
                      />
                    </ReferenceInput>
                    <ReferenceInput
                      label="Counter"
                      source="counter"
                      reference="counter"
                    >
                      <CustomAutoInput
                        optionText="title"
                        optionValue="id"
                        fullWidth
                        isRequired
                      />
                    </ReferenceInput>
                  </Box>
                  <TextInput label={"Remarks"} source="name" fullWidth />
                </Box>
              </Box>
            </SimpleForm>
          );
        }}
      </RecordContext.Consumer>
    </Edit>
  );
};

const SectionTitle = ({ label, variant = "h6" }) => {
  const translate = useTranslate();

  return (
    <Typography variant={variant} gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export default BillingCancel;
