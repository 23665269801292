import { Datagrid, List, TextField } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import SectionTitle from '../../../elements/SectionTitle';

const DoctorList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	//   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

	return (
		<>
			<SectionTitle label="setting.menu.doctor" />
			<List filters={undefined} sort={{}} perPage={25}>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="name" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default DoctorList;
