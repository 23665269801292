import { Box } from '@mui/material';
import { DateInput, TextInput } from 'react-admin';
import CustomArrayInput from '../../../../elements/CustomArrayInput';
import dayjs from 'dayjs';
import NepaliDateInput from '../../../../elements/NepaliDateInput';
import { ADToBS } from '../../../../elements/DatePicker/AdToBsConverter/AdBsConverter';

export default function OpeningStockForm({ state, editMode }) {
	const inputs = [
		{
			key: 1,
			type: 'autoSelect',
			source: 'brand',
			reference: 'brand',
			optionText: 'name',
			props: {
				isRequired: true,
			},
		},
		{
			key: 2,
			source: 'batchNumber',
			type: 'text',
			props: {
				isRequired: true,
			},
		},
		{
			key: 3,
			source: 'expiryDate',
			type: 'date',
			props: {
				isRequired: true,
			},
		},
		{
			key: 4,
			source: 'pkgQty',
			type: 'number',
			props: {
				label: 'Package Quantity',
				isRequired: true,
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					unitQty: (
						value * (_?.brand?.data?.unitsPerPackaging || 1) || 0
					).toFixed(2),
					totalQty: (
						+(value * (_?.brand?.data?.unitsPerPackaging || 1)) +
							(+_?.freeQty || 0) || 0
					).toFixed(2),
				})),
		},
		{
			key: 5,
			source: 'unitQty',
			type: 'number',
			props: {
				label: 'Unit Quantity',
				isRequired: true,
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					pkgQty: (
						value / (_?.brand?.data?.unitsPerPackaging ?? 1) || 0
					).toFixed(2),
					totalQty: (value + (+_?.freeQty || 0) || 0).toFixed(2),
				})),
		},
		{
			key: 6,
			source: 'freeQty',
			type: 'number',
			props: {
				label: 'Free Quantity (in Units)',
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					totalQty: (
						+value +
							(+_?.unitQty ||
								+(_?.pkgQty * (_?.brand?.data?.unitsPerPackaging || 1))) || 0
					).toFixed(2),
				})),
		},
		{
			key: 7,
			source: 'totalQty',
			type: 'number',
			props: {
				label: 'Total Quantity',
				isRequired: true,
				disabled: true,
			},
		},
		{
			key: 8,
			source: 'ratePP',
			type: 'number',
			props: {
				label: 'Rate/Pkg',
				isRequired: true,
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					ratePUnit: (
						value / (_?.brand?.data?.unitsPerPackaging || 1) || 0
					).toFixed(2),
					amount: (
						(value / (_?.brand?.data?.unitsPerPackaging || 1)) *
							(_?.unitQty || 0) || 0
					).toFixed(2),
					vat: _?.brand?.data?.taxable
						? (
								(value / (_?.brand?.data?.unitsPerPackaging || 1)) *
									(_?.unitQty || 0) *
									0.13 || 0
						  ).toFixed(2)
						: 0,
					netAmount: (
						+(value / (_?.brand?.data?.unitsPerPackaging || 1)) *
							(_?.unitQty || 0) +
							+(_?.brand?.data?.taxable
								? (value / (_?.brand?.data?.unitsPerPackaging || 1)) *
								  ((_?.unitQty || 0) * 0.13)
								: 0) +
							(+_?.ccAmt || 0) || 0
					).toFixed(2),
				})),
		},
		{
			key: 9,
			source: 'ratePUnit',
			type: 'number',
			props: {
				label: 'Rate/Unit',
				isRequired: true,
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					ratePP: (
						value * (_?.brand?.data?.unitsPerPackaging ?? 1) || 0
					).toFixed(2),
					amount: (value * (_?.unitQty || 0) || 0).toFixed(2),
					vat: (value * (_?.unitQty || 0) * 0.13 || 0).toFixed(2),
					netAmount: (
						+value * (_?.unitQty || 0) +
							(_?.brand?.data?.taxable
								? +value * (_?.unitQty || 0) * 0.13
								: 0) +
							(+_?.ccAmt || 0) || 0
					).toFixed(2),
				})),
		},
		{
			key: 10,
			source: 'amount',
			type: 'number',
			props: {
				disabled: true,
				isRequired: true,
			},
		},
		{
			key: 11,
			source: 'vat',
			type: 'number',
			props: {
				label: 'VAT',
				disabled: true,
			},
		},
		{
			key: 12,
			source: 'ccRate',
			type: 'number',
			props: {
				label: 'CC Rate (%)',
			},
			onChange: (value, _, setValues) => {
				setValues((prev) => ({
					...prev,
					ccAmt: ((_?.freeQty * _.ratePUnit * value) / 100 || 0).toFixed(2),
					netAmount: (
						(+_?.freeQty * _.ratePUnit * value) / 100 +
							(+_?.amount + (+_?.vat || 0) || 0) || 0
					).toFixed(2),
				}));
			},
		},
		{
			key: 13,
			source: 'ccAmt',
			type: 'number',
			props: {
				label: 'CC Amount',
				disabled: true,
			},
		},
		{
			key: 14,
			source: 'netAmount',
			type: 'number',
			props: {
				disabled: true,
				isRequired: true,
			},
		},
		{
			key: 15,
			source: 'margin',
			type: 'number',
			props: {
				label: 'Margin (%)',
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					salesRPPkg: (+_.ratePP + (_.ratePP * value) / 100 || 0).toFixed(2),
					salesRPUnit: (
						+_.ratePUnit + (_.ratePUnit * value) / 100 || 0
					).toFixed(2),
				})),
		},
		{
			key: 16,
			source: 'salesRPPkg',
			type: 'number',
			props: {
				label: 'Sales Rate Per Pkg',
				isRequired: true,
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					salesRPUnit: (
						(value || 0) / (_?.brand?.data?.unitsPerPackaging || 1) || 0
					).toFixed(2),
					margin: ((((value || 0) - _?.ratePP) / _?.ratePP) * 100 || 0).toFixed(
						2
					),
				})),
		},
		{
			key: 17,
			source: 'salesRPUnit',
			type: 'number',
			props: {
				label: 'Sales Rate Per Unit',
				isRequired: true,
			},
			onChange: (value, _, setValues) =>
				setValues((prev) => ({
					...prev,
					salesRPPkg: (
						(value || 0) * (_?.brand?.data?.unitsPerPackaging || 1) || 0
					).toFixed(2),
					margin: (
						(((value || 0) - _?.ratePUnit) / _?.ratePUnit) * 100 || 0
					).toFixed(2),
				})),
		},
	];
	return (
		<Box sx={{ width: '100%' }}>
			<CustomArrayInput
				inputs={editMode ? [] : inputs}
				headers={headers}
				state={state}
				actions={!editMode && { delete: true }}
			/>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(6, 1fr)',
					width: '100%',
					columnGap: '1rem',
				}}
			>
				{!editMode && (
					<>
						<NepaliDateInput
							source="date"
							isRequired
							defaultValue={ADToBS(dayjs(Date.now()).format('YYYY/MM/DD'))}
							fullWidth
						/>
						<TextInput
							sx={{ gridColumn: 'span 3 / span 3' }}
							source="remarks"
							fullWidth
						/>
					</>
				)}
			</Box>
		</Box>
	);
}

const headers = [
	[
		{
			key: 2,
			source: 'brand',
			label: 'Particulars',
			rowSpan: 2,
			render: (value) => value?.brand?.value || value?.brand?.name,
			order: 1,
		},
		{
			key: 3,
			label: 'Qty',
			colSpan: 2,
		},
		{
			key: 4,
			label: 'Rate',
			colSpan: 2,
		},
		{
			key: 5,
			label: 'CC Charge',
			source: 'ccAmt',
			rowSpan: 2,
			order: 6,
		},
		{
			key: 6,
			label: 'Amount',
			source: 'netAmount',
			rowSpan: 2,
			order: 7,
		},
		{
			key: 7,
			label: 'MRP',
			colSpan: 2,
		},
	],
	[
		{
			key: 1,
			label: 'PKG',
			source: 'pkgQty',
			order: 2,
		},
		{
			key: 2,
			label: 'UNIT',
			source: 'unitQty',
			order: 3,
		},
		{
			key: 3,
			label: 'PER PKG',
			source: 'ratePP',
			order: 4,
		},
		{
			key: 4,
			label: 'PER UNIT',
			source: 'ratePUnit',
			order: 5,
		},
		{
			key: 5,
			label: 'PER PKG',
			source: 'salesRPPkg',
			order: 8,
		},
		{
			key: 6,
			label: 'PER UNIT',
			source: 'salesRPUnit',
			order: 9,
		},
	],
];
