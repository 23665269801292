import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserList from './UserList';
import { Person, PersonAdd } from '@mui/icons-material';
import UserShow from './UserShow';

const users = {
	create: UserCreate,
	edit: UserEdit,
	list: UserList,
	show: UserShow,
	icon: Person,
	createIcon: PersonAdd,
};

export default users;
