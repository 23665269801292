import { useTranslate } from "react-admin";
import { Typography } from "@mui/material";

const SectionTitle = ({ label, variant = "h6", sx = {} }) => {
  const translate = useTranslate();

  return (
    <Typography variant={variant} sx={{ ...sx }} gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export default SectionTitle;
