import {
	Datagrid,
	List,
	TextField,
	useTranslate,
	FunctionField,
} from 'react-admin';
import { useMediaQuery, Typography } from '@mui/material';

const DebitNoteList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.store.debitnote" />
			<List filters={undefined} sort={{}} perPage={25}>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="date" />
						<TextField source="debitNoteNo" />
						<TextField source="vendor.name" />
						<TextField source="referenceBillNo" />
						<TextField source="subAmount" />
						<TextField source="discount" />
						<TextField source="taxableAmount" />
						<TextField source="vatAmount" />
						<FunctionField
							label="Net Amount"
							render={(data) => (
								<>
									{(+data?.subAmount || 0) +
										(+data?.vatAmount || 0) +
										(+data?.taxableAmount || 0) -
										(+data?.discount || 0)}
								</>
							)}
						/>
					</Datagrid>
				)}
			</List>
		</>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default DebitNoteList;
