import { Box } from '@mui/material';
import React from 'react';
import { PasswordInput, TextInput, AutocompleteInput } from 'react-admin';

export default function UserForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(4, 1fr)',
				width: '100%',
				gap: 1,
			}}
		>
			<TextInput source="fullname_en" label="Full Name" isRequired fullWidth />
			<TextInput source="email" isRequired fullWidth />
			<TextInput source="phone_en" label="Phone" isRequired fullWidth />
			<TextInput
				source="designation_en"
				label="Designation"
				isRequired
				fullWidth
			/>
			<AutocompleteInput
				source="role_en"
				label="Role"
				choices={[
					// { id: 'registration_user', name: 'Registration' },
					// { id: 'opd_user', name: 'OPD' },
					// { id: 'pharmacy_user', name: 'Pharmacy' },
					// { id: 'radiology_user', name: 'Radiology' },
					// { id: 'inventory_user', name: 'Inventory' },
					// { id: 'pathalogy_user', name: 'Pathology' },
					// { id: 'inPatient_user', name: 'Patient' },
					{ id: 'admin', name: 'Admin' },
					{ id: 'user', name: 'User' },
				]}
			/>
			{/* <Box sx={{ gridColumn: 'span 4 / span 4' }}>
				<FileInput source="image" fullWidth />
			</Box> */}
			<Box
				sx={{
					gridColumn: 'span 4 / span 4',
					display: 'grid',
					gridTemplateColumns: 'repeat(4, 1fr)',
					gap: 1,
				}}
			>
				<TextInput
					source="email"
					label="Username"
					isRequired
					disabled
					fullWidth
				/>
				<PasswordInput source="password" fullWidth />
			</Box>
		</Box>
	);
}

// fullname_en
// email
// phone_en
// designation_en
// role_en
// image
// username
// password

// designation_np
// phone_np
// fullname_np
// isEmailVerified
