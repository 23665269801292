import printJS from 'print-js';

const handlePrint = (printContents) => {
	try {
		let printContent = printContents;
		if (window.location.protocol === 'https:') {
			printContent = printContent.replace(/^http:/, 'https:');
		}
		console.log(printContent);
		printJS({
			printable: printContent,
			type: 'pdf',
		});
	} catch (error) {
		console.log(error);
	}
};

export default handlePrint;
