import { Storefront } from '@mui/icons-material';
import VendorsCreate from './VendorsCreate';
import VendorsEdit from './VendorsEdit';
import VendorsList from './VendorsList';

const resource = {
	list: VendorsList,
	create: VendorsCreate,
	edit: VendorsEdit,
	icon: Storefront,
};

export default resource;
