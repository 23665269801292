import OpeningStockCreate from "./OpeningStockCreate";
import OpeningStockList from "./OpeningStockList";
import OpeningStockEdit from "./OpeningStockEdit";
import { Inventory } from "@mui/icons-material";

const resource = {
  list: OpeningStockList,
  create: OpeningStockCreate,
  edit: OpeningStockEdit,
  icon: Inventory,
};

export default resource;
