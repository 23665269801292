import { Box } from '@mui/material';
import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import SampleGrpForm from './sampleGrpForm';

export default function SampleGrpCreate() {
	return (
		<Create redirect="list">
			<Box p={2} pb={0}>
				<SectionTitle label="pathology.settings.sampleGrp" />
			</Box>
			<SimpleForm>
				<SampleGrpForm />
			</SimpleForm>
		</Create>
	);
}
