import React, { useState } from 'react';
import { Create, SimpleForm } from 'react-admin';
import BillingForm from './BillingForm';
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import handlePrint from '../../../_helper/print';
import { useNavigate } from 'react-router-dom';

export default function BillingCreate() {
	const [brands, setBrands] = useState([]);
	const navigate = useNavigate();
	return (
		<>
			<Create
				resource="pharmacyBilling"
				redirect="list"
				transform={(data) => {
					const body = { ...data };
					delete body.brand;
					delete body.batchNumber;
					delete body.quantity;
					delete body.rate;
					delete body.amount;
					if (!body.name)
						body.name = `${body?.patient?.firstName} ${
							body?.patient?.middleName || ''
						} ${body?.patient?.lastName}`;
					!body.discount && delete body.discount;
					body.discountAmount = data?.discountAmount || 0;
					body.roundOff = data?.roundOff || 0;
					body.tenderAmount = data?.tenderAmount || 0;
					body.changeAmount = data?.changeAmount || 0;
					body.brands = brands?.map((item) => ({
						...item,
						brand: item.brand?.id,
						batchNumber: item?.batchNumber?.data?.id,
						total: item?.amount,
						name: item?.brand?.data?.name,
						batch: item?.batchNumber?.data?.batchNumber,
						expiryDate: item?.batchNumber?.data?.expiryDate,
					}));

					return body;
				}}
				mutationOptions={{
					onSuccess: (data) => {
						data?.print && handlePrint(data?.print);
						navigate('/pharmacyBilling');
					},
				}}
			>
				<SimpleForm>
					<SectionTitle label="pharmacy.billing.title" />
					<BillingForm state={[brands, setBrands]} />
				</SimpleForm>
			</Create>
		</>
	);
}

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};
