import * as React from "react";
import { Box, Card, CardContent } from "@mui/material";
import LabelImportantRoundedIcon from "@mui/icons-material/LabelImportantRounded";
import { RecordContextProvider, useNotify, Form } from "react-admin";

import {
  Typography,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import printJS from "print-js";

import { Print, copyTextToClipboard } from "../../_helper";
import CustomSearch from "../../elements/CustomSearch";
import dayjs from "dayjs";

const gender = {
  male: "M",
  female: "F",
  other: "O",
};

const ageType = {
  years: "Y",
  months: "M",
  days: "D",
};

const Aside = ({ data: tickets, isLoading, error, setSearch, search }) => {
  const notify = useNotify();

  if (isLoading) {
    return <p>loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const handleCopyTicket = async (text) => {
    try {
      await copyTextToClipboard(text);
      notify("Copied to Clipboard", { type: "info", undoable: false });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card
      sx={{
        display: {
          xs: "none",
          md: "block",
        },
        order: 1,
        flex: "0 0 20em",
        ml: 2,
        mt: 0,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <Form>
          <CustomSearch
            source="search"
            onChange={(e) => setSearch(e)}
            value={search}
          />
        </Form>

        <Stepper orientation="vertical" sx={{ mt: 1 }}>
          {tickets.map((patient) => (
            <Step key={`${patient.id}`} expanded active completed>
              <StepLabel
                icon={
                  <LabelImportantRoundedIcon
                    color="disabled"
                    sx={{ pl: 0.5, fontSize: "1.25rem" }}
                  />
                }
              >
                {patient?.patient?.firstName}&nbsp;
                {patient?.patient?.middleName}&nbsp;
                {patient?.patient?.lastName}
              </StepLabel>
              <StepContent>
                <RecordContextProvider value={patient.data}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {patient?.patient?.age}&nbsp;
                        {ageType[patient?.patient?.ageType]}/
                        {gender[patient?.patient?.gender]}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {dayjs(new Date()).diff(
                          dayjs(patient.createdAt),
                          "day"
                        )}
                        &nbsp;day/s ago
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {patient?.department?.title}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        New
                        <Link
                          onClick={() =>
                            handleCopyTicket(`${patient?.patient?.patientId}`)
                          }
                        >
                          &nbsp;#{patient?.patient?.patientId}
                        </Link>
                      </Typography>
                    </Box>

                    <Typography
                      sx={{ columnSpan: "2" }}
                      variant="body2"
                      color="textSecondary"
                    >
                      <Link to={`/ticket/${patient.id}`} component={RouterLink}>
                        Edit
                      </Link>
                      <Link
                        onClick={(e) => Print(patient.print)}
                        style={{ marginLeft: "5px" }}
                      >
                        Print
                      </Link>
                    </Typography>
                  </Box>
                </RecordContextProvider>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
};

export default Aside;
