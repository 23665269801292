import { Typography } from '@mui/material';
import { useGetList } from 'react-admin';

export default function CashBalance() {
	const { data } = useGetList('balance/registration');
	console.log(data);
	return (
		<>
			<div>
				<Typography variant="h6">My Cash Balance : </Typography>
				<Typography
					variant="h4"
					sx={{ color: (theme) => theme?.palette?.primary?.main }}
				>
					{data?.[0]?.totalValue
						? 'Rs. ' + data[0]?.totalValue + ' /-'
						: 'Rs. 0 /-'}
				</Typography>
			</div>
		</>
	);
}
