import BillingCreate from './BillingCreate';
import BillingEdit from './BillingEdit';
import BillingList from './BillingList';

const resource = {
	create: BillingCreate,
	edit: BillingEdit,
	list: BillingList,
	icon: null,
};

export default resource;
