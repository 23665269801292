import { Box } from '@mui/material';
import { TextInput } from 'react-admin';

export default function VendorsForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				width: '100%',
				columnGap: '1rem',
			}}
		>
			<TextInput source="name" isRequired fullWidth />
			<TextInput source="pan" fullWidth label="VAT/PAN" />
			<TextInput source="address" fullWidth />
			<TextInput source="website" fullWidth />
			<TextInput source="email" fullWidth />
			<TextInput source="phone" fullWidth />
			<TextInput source="cPName" fullWidth label="Contact Person Name" />
			<TextInput source="cPPhone" fullWidth label="Contact Person Phone" />
			<TextInput multiline source="note" fullWidth />
		</Box>
	);
}
