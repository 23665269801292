import { Box } from '@mui/material';
import React from 'react';
import {
	AutocompleteArrayInput,
	Create,
	Datagrid,
	FunctionField,
	List,
	ReferenceArrayInput,
	SimpleForm,
	TextField,
	Button,
} from 'react-admin';
import SectionTitle from '../../../../../elements/SectionTitle';

export default function BillingLabItemsList() {
	return (
		<List empty={false}>
			<Box p={2} pb={0}>
				<SectionTitle label="pathology.settings.billingLabItems" />
			</Box>
			<Datagrid
				sx={{
					'& .column-labServices': {
						maxWidth: '20vw !important',
					},
				}}
			>
				<TextField source="serviceCategory.title" />
				<TextField source="name" label="Billing Item" />
				<FunctionField
					source="labServices"
					label="Lab Services"
					render={(record) => <LabItemCreate record={record} />}
				/>
			</Datagrid>
		</List>
	);
}

function LabItemCreate({ record }) {
	// labService = []

	return (
		<Create
			resource="pathologyLabItems"
			transform={(data) => {
				let body = { ...data };
				body.service = record.id;
				return body;
			}}
			actions={[]}
		>
			<SimpleForm
				sx={{
					padding: 0,
					margin: 0,
					paddingBottom: '0 !important',
				}}
				toolbar={false}
			>
				<Box display="flex" direction="row" alignItems="start" width="100%">
					<Box flexGrow={1} width="100%">
						<ReferenceArrayInput
							source="labServices"
							reference="pathologyService"
						>
							<AutocompleteArrayInput
								optionText="name"
								defaultValue={record?.labItems?.labServices}
								fullWidth
							/>
						</ReferenceArrayInput>
					</Box>
					<Button type="submit">Submit</Button>
				</Box>
			</SimpleForm>
		</Create>
	);
}
