import { Avatar, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import {
	Datagrid,
	FunctionField,
	List,
	TextField,
	usePermissions,
} from 'react-admin';

export default function UserList() {
	const { permissions } = usePermissions();
	console.log(permissions);

	return (
		<List>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<FunctionField
					label="User"
					render={(record) => (
						<Box display={'flex'} gap={1}>
							<Avatar src={record?.image} />
							<Box>
								<Typography variant="body1" fontWeight={600}>
									{record?.fullname_en}
								</Typography>
								<Typography variant="body2" color={grey[500]}>
									{record?.username}
								</Typography>
							</Box>
						</Box>
					)}
				/>
				<TextField source="email" />
				<TextField source="phone_en" label="Phone" />
				<TextField source="designation_en" label="Designation" />

				<FunctionField
					source="role_en"
					label="Role"
					render={(record) => {
						switch (record?.role_en) {
							case 'admin':
								return 'Super User';
							case 'registration_user':
								return 'Registration User';
							case 'pharmacy_user':
								return 'Pharmacy User';
							case 'opd_user':
							case 'radiology_user':
								return 'Radiology User';
							case 'inventory_user':
								return 'Inventory User';
							case 'pathalogy_user':
								return 'Pathology User';
							case 'inPatient_user':
								return 'Patient';
							case 'user':
								return 'User';
							default:
								return 'Unidentified Role';
						}
					}}
				/>
			</Datagrid>
		</List>
	);
}
