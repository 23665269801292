import { useEffect } from 'react';
import { Box } from '@mui/material';
import {
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	DateInput,
} from 'react-admin';
import ArrayTable, {
	CustomAutoInput,
} from '../../../../elements/CustomArrayInput';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import NepaliDateInput from '../../../../elements/NepaliDateInput';
import { ADToBS } from '../../../../elements/DatePicker/AdToBsConverter/AdBsConverter';

export default function DebitNoteForm({ state, editMode = null }) {
	const { setValue, watch } = useFormContext();
	let [discountvalue, rfvalue] = watch(['discount', 'roundOff']);
	useEffect(() => {
		let calculatedDataFromTable = {};
		if (state[0].length > 0) {
			calculatedDataFromTable.subAmount = (
				+state[0]?.reduce((acc, curr) => acc + +curr?.amount, 0) || 0
			).toFixed(2);
			calculatedDataFromTable.ccAmount = (
				+state[0]?.reduce((acc, curr) => acc + +curr?.ccAmt, 0) || 0
			).toFixed(2);
			calculatedDataFromTable.taxableAmount = (
				+state[0]?.reduce(
					(acc, curr) => acc + (curr?.brand?.data?.taxable ? +curr?.amount : 0),
					0
				) || 0
			).toFixed(2);
			calculatedDataFromTable.vatAmount = (
				+state[0]?.reduce((acc, curr) => acc + +curr?.vat, 0) || 0
			).toFixed(0);

			calculatedDataFromTable.netAmount = (
				(+calculatedDataFromTable?.subAmount || 0) +
					(+calculatedDataFromTable?.ccAmount || 0) +
					(+calculatedDataFromTable?.vatAmount || 0) -
					(+discountvalue || 0) +
					(+rfvalue || 0) || 0
			).toFixed(2);
			setValue(
				'subAmount',
				(+calculatedDataFromTable.subAmount || 0).toFixed(2)
			);
			setValue('ccAmount', (+calculatedDataFromTable.ccAmount || 0).toFixed(2));
			setValue(
				'taxableAmount',
				(+calculatedDataFromTable.taxableAmount || 0).toFixed(2)
			);
			setValue(
				'vatAmount',
				(+calculatedDataFromTable.vatAmount || 0).toFixed(2)
			);
			console.log(calculatedDataFromTable.netAmount);
			setValue(
				'netAmount',
				(+calculatedDataFromTable.netAmount || 0).toFixed(2)
			);
		}
	}, [setValue, state, rfvalue, discountvalue]);
	return (
		<Box style={{ width: '100%' }}>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)',
					width: '100%',
					columnGap: '1rem',
				}}
			>
				<ReferenceInput label="Vendor" source="vendor" reference="vendors">
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						size="small"
						fullWidth
						isRequired
						disabled={editMode}
					/>
				</ReferenceInput>
				<Box />
				<TextInput
					source="referenceBillNo"
					label="Reference Bill Number"
					isRequired
					fullWidth
					disabled={editMode}
				/>
			</Box>

			<ArrayTable
				inputs={editMode ? [] : inputs}
				headers={headers}
				state={state}
				actions={!editMode && { delete: true }}
			/>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)',
					width: '100%',
					columnGap: '1rem',
				}}
			>
				<Box>
					<NepaliDateInput
						source="date"
						isRequired
						defaultValue={ADToBS(dayjs(Date.now()).format('MM/DD/YYYY'))}
						disabled={editMode}
						fullWidth
					/>
					<TextInput source="remarks" disabled={editMode} multiline fullWidth />
				</Box>
				<Box>
					<TextInput
						source="subAmount"
						isRequired
						label="Sub Amount"
						disabled
						fullWidth
					/>
					<TextInput
						source="discount"
						disabled={editMode}
						label="Discount Amount"
						fullWidth
					/>
					<TextInput source="ccAmount" label="CC Amount" disabled fullWidth />
				</Box>
				<Box>
					<TextInput
						source="taxableAmount"
						label="Taxable Amount"
						disabled
						fullWidth
					/>
					<TextInput source="vatAmount" label="VAT Amount" disabled fullWidth />
					<TextInput
						source="roundOff"
						label="Round Off"
						disabled={editMode}
						fullWidth
					/>
					<TextInput
						source="netAmount"
						isRequired
						label="Net Amount"
						disabled
						fullWidth
					/>
				</Box>
			</Box>
		</Box>
	);
}

const inputs = [
	{
		key: 1,
		type: 'autoSelect',
		source: 'brand',
		reference: 'brand',
		optionText: 'name',
	},
	{
		key: 2,
		source: 'batchNumber',
		type: 'custom',
		render: (values, onValueChange, setValues) =>
			values?.brand?.data ? (
				<CustomAutoInput
					onValueChange={onValueChange}
					input={{
						source: 'batchNumber',
						reference: `store/brand/${values?.brand?.data?.id}`,
						optionText: 'batchNumber',
						onChange: (value, _, setValues) =>
							setValues((prev) => ({
								...prev,
								ratePUnit: (+value?.ratePUnit || 0).toFixed(2),
								ratePPkg: (+value?.ratePPkg || 0).toFixed(2),
								// ratePUnit: (+value?.ratePUnit || 0).toFixed(2),
								// ratePUnit: (+value?.ratePUnit || 0).toFixed(2),
							})),
					}}
					values={values}
					setValues={setValues}
				/>
			) : (
				<TextInput source="batchNumber" disabled />
			),
	},
	{
		key: 3,
		source: 'expiryDate',
		type: 'date',
		props: {
			isRequired: true,
		},
	},
	{
		key: 4,
		source: 'pkgQty',
		type: 'number',
		props: {
			label: 'Package Quantity',
			isRequired: true,
		},
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				unitQty: (
					value * (_?.brand?.data?.unitsPerPackaging || 1) || 0
				).toFixed(2),
				totalQty: (
					+(value * (_?.brand?.data?.unitsPerPackaging || 1)) +
						(+_?.freeQty || 0) || 0
				).toFixed(2),
			})),
	},
	{
		key: 5,
		source: 'unitQty',
		type: 'number',
		props: {
			label: 'Unit Quantity',
			isRequired: true,
		},
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				pkgQty: (value / (_?.brand?.data?.unitsPerPackaging ?? 1) || 0).toFixed(
					2
				),
				totalQty: (+value + (+_?.freeQty || 0) || 0).toFixed(2),
			})),
	},
	{
		key: 6,
		source: 'freeQty',
		type: 'number',
		props: {
			label: 'Free Quantity (in Units)',
		},
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				totalQty: (
					+value +
						(+_?.unitQty ||
							+(_?.pkgQty * (_?.brand?.data?.unitsPerPackaging || 1))) || 0
				).toFixed(2),
			})),
	},
	{
		key: 7,
		source: 'totalQty',
		type: 'number',
		props: {
			label: 'Total Quantity',
			isRequired: true,
			disabled: true,
		},
	},
	{
		key: 8,
		source: 'ratePP',
		type: 'number',
		props: {
			label: 'Rate/Pkg',
			isRequired: true,
		},
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				ratePUnit: (
					value / (_?.brand?.data?.unitsPerPackaging || 1) || 0
				).toFixed(2),
				amount: (
					(value / (_?.brand?.data?.unitsPerPackaging || 1)) *
						(_?.unitQty || 0) || 0
				).toFixed(2),
				vat: _?.brand?.data?.taxable
					? (
							(value / (_?.brand?.data?.unitsPerPackaging || 1)) *
								(_?.unitQty || 0) *
								0.13 || 0
					  ).toFixed(2)
					: 0,
				netAmount: (
					+(value / (_?.brand?.data?.unitsPerPackaging || 1)) *
						(_?.unitQty || 0) +
						+(_?.brand?.data?.taxable
							? (value / (_?.brand?.data?.unitsPerPackaging || 1)) *
							  ((_?.unitQty || 0) * 0.13)
							: 0) +
						(+_?.ccAmt || 0) || 0
				).toFixed(2),
			})),
	},
	{
		key: 9,
		source: 'ratePUnit',
		type: 'number',
		props: {
			label: 'Rate/Unit',
			isRequired: true,
		},
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				ratePP: (value * (_?.brand?.data?.unitsPerPackaging ?? 1) || 0).toFixed(
					2
				),
				amount: (value * (_?.unitQty || 0) || 0).toFixed(2),
				vat: (value * (_?.unitQty || 0) * 0.13 || 0).toFixed(2),
				netAmount: (
					+value * (_?.unitQty || 0) +
						(_?.brand?.data?.taxable ? +value * (_?.unitQty || 0) * 0.13 : 0) +
						(+_?.ccAmt || 0) || 0
				).toFixed(2),
			})),
	},
	{
		key: 10,
		source: 'amount',
		type: 'number',
		props: {
			disabled: true,
			isRequired: true,
		},
	},
	{
		key: 11,
		source: 'vat',
		type: 'number',
		props: {
			label: 'VAT',
			disabled: true,
		},
	},
	{
		key: 12,
		source: 'ccRate',
		type: 'number',
		props: {
			label: 'CC Rate (%)',
		},
		onChange: (value, _, setValues) => {
			setValues((prev) => ({
				...prev,
				ccAmt: ((_?.freeQty * _.ratePUnit * value) / 100 || 0).toFixed(2),
				netAmount: (
					(+_?.freeQty * _.ratePUnit * value) / 100 +
						(+_?.amount + (+_?.vat || 0) || 0) || 0
				).toFixed(2),
			}));
		},
	},
	{
		key: 13,
		source: 'ccAmt',
		type: 'number',
		props: {
			label: 'CC Amount',
			disabled: true,
		},
	},
	{
		key: 14,
		source: 'netAmount',
		type: 'number',
		props: {
			disabled: true,
			isRequired: true,
		},
	},
	{
		key: 15,
		source: 'narration',
		type: 'text',
		props: { sx: { gridColumn: 'span 3 / span 3' } },
	},
];

const headers = [
	[
		{
			key: 2,
			source: 'brand',
			label: 'Particulars',
			rowSpan: 2,
			render: (value) => value?.brand?.value || value?.brand?.name,
			order: 1,
		},
		{
			key: 3,
			label: 'Qty',
			colSpan: 2,
		},
		{
			key: 4,
			label: 'Rate',
			colSpan: 2,
		},
		{
			key: 5,
			label: 'CC Charge',
			source: 'ccAmt',
			rowSpan: 2,
			order: 6,
		},
		{
			key: 6,
			label: 'Amount',
			source: 'netAmount',
			rowSpan: 2,
			order: 7,
		},
	],
	[
		{
			key: 1,
			label: 'PKG',
			source: 'pkgQty',
			order: 2,
		},
		{
			key: 2,
			label: 'UNIT',
			source: 'unitQty',
			order: 3,
		},
		{
			key: 3,
			label: 'PER PKG',
			source: 'ratePP',
			order: 4,
		},
		{
			key: 4,
			label: 'PER UNIT',
			source: 'ratePUnit',
			order: 5,
		},
	],
];
