
  import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { validateForm } from './BatchEditCreate';
import BatchEditForm from './BatchEditForm';

const BatchEditEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/batchEdit">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.store.batchedit')}
				</Typography>
				<BatchEditForm />
			</SimpleForm>
		</Edit>
	);
};



export default BatchEditEdit;

  