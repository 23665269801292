import fiscalYear from "./pages/administration/fiscalYear";
import users from "./pages/administration/Users";
import roleManagement from "./pages/administration/RoleManagement";

import counters from "./pages/settings/counter";
import charity from "./pages/settings/charity";
import department from "./pages/settings/department";
import doctor from "./pages/settings/doctor";
import nurse from "./pages/settings/nurse";
import shift from "./pages/settings/shift";
import ticketType from "./pages/settings/ticketType";

import serviceCategory from "./pages/services/category";
import service from "./pages/services/service";

import patient from "./pages/patient";
import billing from "./pages/billing";
import document from "./pages/document";

// pharmacy master data
import brandCategory from "./pages/pharmacy/master/brandCategory";
import brand from "./pages/pharmacy/master/brand";
import brandBulk from "./pages/pharmacy/master/BrandBulk";
import genericNames from "./pages/pharmacy/master/genericNames";
import manufacturer from "./pages/pharmacy/master/manufacturer";
import vendors from "./pages/pharmacy/master/vendors";
import customers from "./pages/pharmacy/master/customers";
import pharmacyTemplate from "./pages/pharmacy/template";

// pharmacy store
import openingStock from "./pages/pharmacy/store/openingstock";
import purchase from "./pages/pharmacy/store/purchase";
import debitNote from "./pages/pharmacy/store/debitnote";
// import stockIn from './pages/pharmacy/store/stockin';
// import stockOut from './pages/pharmacy/store/stockout';
import expiryMgmt from "./pages/pharmacy/store/expirymgmt";
import batchEdit from "./pages/pharmacy/store/batchedit";
import salesReturn from "./pages/pharmacy/salesReturn";
import pharmacyBilling from "./pages/pharmacy/billing";

// pharmacy account
import cashDeposit from "./pages/pharmacy/account/cashDeposit";
import receive from "./pages/pharmacy/account/receive";
import payment from "./pages/pharmacy/account/payment";
import openingBalance from "./pages/pharmacy/account/openingBalance";

// pharmacy report
import {
  billSearch,
  narcoticsSalesReport,
  productSalesRegister,
  productSalesReturnRegister,
  salesRegister,
  salesReturnRegister,
  salesSummary,
} from "./pages/pharmacy/reports/sales";
import { currentStock, stockLedger } from "./pages/pharmacy/reports/stocks";
import {
  accountLedger,
  patientCustomLedger,
  productLedger,
  userwiseSummary,
  vendorLedger,
} from "./pages/pharmacy/reports/account";
import purchaseHistory from "./pages/pharmacy/reports/purchaseHistory";
import referral from "./pages/pathology/settings/master/referral";
import labDept from "./pages/pathology/settings/master/labDepartments";
import sampleGrp from "./pages/pathology/settings/sampleGroups";
import labServices from "./pages/pathology/settings/master";
import pathologyTemplates from "./pages/pathology/administrative/templates";
import billingLabItems from "./pages/pathology/settings/master/billingLabItems";
import sampleCollection from "./pages/pathology/sample";
import valueEntry from "./pages/pathology/valueEntry";
import valueEntrySheet from "./pages/pathology/valueEntry/valueEntrySheet";
import mulDartaRegister from "./pages/registration/reports/MulDartaRegister";
import monthlyReport from "./pages/registration/reports/MonthlyReport";

const admin = [
  // settings
  {
    name: "counter",
    routes: counters,
    permissionName: "counter",
  },
  {
    name: "charity",
    routes: charity,
    permissionName: "charity",
  },
  {
    name: "department",
    routes: department,
    permissionName: "department",
  },
  {
    name: "doctor",
    routes: doctor,
    permissionName: "doctor",
  },
  {
    name: "nurse",
    routes: nurse,
    permissionName: "nurse",
  },
  {
    name: "shift",
    routes: shift,
    permissionName: "shift",
  },
  {
    name: "ticketType",
    routes: ticketType,
    permissionName: "ticketType",
  },
  // services
  {
    name: "serviceCategory",
    routes: serviceCategory,
    permissionName: "serviceCategory",
  },
  {
    name: "service",
    routes: service,
    permissionName: "service",
    children: [
      {
        key: "excel",
        path: "excel/create",
        element: <service.excel />,
      },
    ],
  },
  // fiscal
  {
    name: "fiscalYear",
    routes: fiscalYear,
    permissionName: "fiscalYear",
  },
  // users
  {
    name: "users",
    routes: users,
    permissionName: "users",
  },
  {
    name: "roleManagement",
    routes: roleManagement,
    permissionName: "roleManagement",
  },
];

const registration = [
  {
    name: "ticket",
    routes: patient,
    permissionName: "registration_ticketing",
    children: [
      {
        key: "followup",
        path: "/followup",
        element: <patient.followUp />,
      },
    ],
  },
  {
    name: "billing",
    routes: billing,
    permissionName: "registration_billing",
  },
  {
    name: "template",
    routes: document,
    permissionName: "registration_template",
  },
  {
    name: "registration/reports/mulDartaRegister",
    routes: mulDartaRegister,
  },
  {
    name: "registration/reports/monthlyReport",
    routes: monthlyReport,
  },
];

const pharmacy = [
  {
    name: "pharmacyBilling",
    routes: pharmacyBilling,
    permissionName: "pharmacy_billing",
  },
  // master data
  {
    name: "brandCategory",
    routes: brandCategory,
    permissionName: "brandCategory",
  },
  {
    name: "brand",
    routes: brand,
    permissionName: "brand",
  },
  {
    name: "bulkBrand",
    routes: brandBulk,
    permissionName: "bulkBrand",
  },
  {
    name: "genericNames",
    routes: genericNames,
    permissionName: "genericNames",
    options: {
      label: "Generic Names",
    },
  },
  {
    name: "manufacturer",
    routes: manufacturer,
    permissionName: "manufacturer",
  },
  {
    name: "vendors",
    routes: vendors,
    permissionName: "vendors",
  },
  {
    name: "customers",
    routes: customers,
    permissionName: "customers",
  },
  // store
  {
    name: "openingStock",
    routes: openingStock,
    permissionName: "pharmacy_store",
  },
  {
    name: "purchase",
    routes: purchase,
    permissionName: "pharmacy_store",
  },
  {
    name: "debitNote",
    routes: debitNote,
    permissionName: "pharmacy_store",
  },
  // {
  // 	name: 'stockIn',
  // 	routes: stockIn,
  // 	permissionName: 'pharmacy_store',
  // },
  // {
  // 	name: 'stockOut',
  // 	routes: stockOut,
  // 	permissionName: 'pharmacy_store',
  // },
  {
    name: "expiryMgmt",
    routes: expiryMgmt,
    permissionName: "pharmacy_store",
  },
  {
    name: "batchEdit",
    routes: batchEdit,
    permissionName: "pharmacy_store",
  },
  // account
  {
    name: "cashDeposit",
    routes: cashDeposit,
    permissionName: "pharmacy_account",
  },
  {
    name: "pharmacyOpeningBalance",
    routes: openingBalance,
    permissionName: "pharmacy_account",
  },
  {
    name: "pharmacyReceive",
    routes: receive,
    permissionName: "pharmacy_account",
  },
  {
    name: "pharmacyPayment",
    routes: payment,
    permissionName: "pharmacy_account",
  },
  // misc and administration
  {
    name: "template/pharmacy",
    routes: pharmacyTemplate,
    permissionName: "pharmacyTemplate",
    options: {
      label: "Template",
    },
  },
  {
    name: "pharmacySalesReturn",
    routes: salesReturn,
    permissionName: "salesReturn",
  },
  // reports
  {
    name: "reports/billSearch",
    routes: billSearch,
  },
  {
    name: "reports/salesSummary",
    routes: salesSummary,
  },
  {
    name: "reports/salesRegister",
    routes: salesRegister,
  },
  {
    name: "reports/productSalesRegister",
    routes: productSalesRegister,
  },
  {
    name: "reports/productSalesReturnRegister",
    routes: productSalesReturnRegister,
  },
  {
    name: "reports/salesReturnRegister",
    routes: salesReturnRegister,
  },
  {
    name: "reports/narcoticsSalesReport",
    routes: narcoticsSalesReport,
  },
  {
    name: "reports/currentStock",
    routes: currentStock,
  },
  {
    name: "reports/stockLedger",
    routes: stockLedger,
  },
  {
    name: "reports/userwiseSummary",
    routes: userwiseSummary,
  },
  {
    name: "reports/patientCustomerLedger",
    routes: patientCustomLedger,
  },
  {
    name: "reports/accountLedger",
    routes: accountLedger,
  },
  {
    name: "reports/productLedger",
    routes: productLedger,
  },
  {
    name: "reports/vendorLedger",
    routes: vendorLedger,
  },
  {
    name: "reports/purchaseHistory",
    routes: purchaseHistory,
  },
];

const pathology = [
  {
    name: "pathologyReferal",
    routes: referral,
  },
  {
    name: "pathologyDepartment",
    routes: labDept,
  },
  {
    name: "pathologyService",
    routes: labServices,
  },
  {
    name: "pathologySampleGroup",
    routes: sampleGrp,
  },
  {
    name: "pathologyTemplate",
    routes: pathologyTemplates,
  },
  {
    name: "pathologyLabItems",
    routes: billingLabItems,
  },
  {
    name: "pathologySampleCollection",
    routes: sampleCollection,
  },
  {
    name: "valueEntry",
    routes: valueEntry,
  },
  {
    name: "valueEntrySheet",
    routes: valueEntrySheet,
  },
];

const routes = [...admin, ...registration, ...pharmacy, ...pathology];

export default routes;
