import { ReferenceInput, Button, FormDataConsumer } from 'react-admin';
import { Box, Table } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { Delete } from '@mui/icons-material';

import CustomAutoMUI from '../../elements/CustomAutoMUI';
import CustomTextInput from '../../elements/CustomTextInput';
const ServiceInfo = ({ services, setServices, total }) => {
	const selectRef = useRef(null);
	const [serviceDetails, setServiceDetails] = useState(undefined);
	const [currentValues, setCurrentValues] = useState({});

	const remove = (index) => {
		const tempService = [...services];
		tempService.splice(index, 1);
		setServices(tempService);
	};

	return (
		<>
			<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
				<Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
					<ReferenceInput
						label="Service"
						source="service"
						reference="service"
						perPage={1000}
					>
						<CustomAutoMUI
							selectRef={selectRef}
							optionText="name"
							optionValue="id"
							fullWidth
							isRequired
							onSelect={(e) => {
								setServiceDetails(e);
								setCurrentValues((prev) => ({
									...prev,
									rate: e?.rate,
									quantity: 1,
								}));
							}}
							value={serviceDetails}
							size={'small'}
							label="Service"
						/>
					</ReferenceInput>
				</Box>
				<Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
					<CustomTextInput
						type="number"
						source="quantity"
						label="Quantity"
						fullWidth
						isRequired
						value={currentValues?.quantity}
						onChange={(e) =>
							setCurrentValues((prev) => ({
								...prev,
								quantity: e.target.value,
							}))
						}
					/>
				</Box>
				<Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
					<CustomTextInput
						type="number"
						source="rate"
						label="Rate"
						value={currentValues?.rate}
						fullWidth
						onChange={(e) =>
							setCurrentValues((prev) => ({ ...prev, rate: e.target.value }))
						}
					/>
				</Box>
				<Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
					<FormDataConsumer>
						{({ formData, ...rest }) => (
							<CustomTextInput
								disabled
								source="basicAmount"
								label={'Basic Amount'}
								fullWidth
								value={
									(currentValues?.rate || 0) * +currentValues.quantity || 0
								}
							/>
						)}
					</FormDataConsumer>
				</Box>
				<Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
					<CustomTextInput
						source="discount"
						label={'Discount'}
						fullWidth
						value={currentValues?.discount}
						onChange={(e) =>
							setCurrentValues((prev) => ({
								...prev,
								discount: e.target.value,
								discountRate:
									((e.target.value || 0) /
										((currentValues?.rate || 0) * +currentValues.quantity ||
											0)) *
									100,
							}))
						}
					/>
				</Box>
				<Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
					<CustomTextInput
						source="discountRate"
						label={'Discount Rate %'}
						fullWidth
						value={currentValues?.discountRate}
						onChange={(e) =>
							setCurrentValues((prev) => ({
								...prev,
								discount:
									(e.target.value *
										((currentValues?.rate || 0) * +currentValues.quantity ||
											0)) /
									100,
								discountRate: e.target.value,
							}))
						}
					/>
				</Box>
				<Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
					<Button
						disabled={!currentValues.quantity}
						variant="contained"
						onClick={() => {
							setServices((prev) => [
								...prev,
								{
									...currentValues,
									discount: currentValues.discount || 0,
									service: serviceDetails?.id,
									particulars: serviceDetails?.name,
									basicAmount:
										(serviceDetails?.rate || 0) * +currentValues.quantity ||
										0 ||
										0,
								},
							]);
							setCurrentValues({});
							setServiceDetails(undefined);
							if (selectRef.current) {
								const ele = selectRef.current.getElementsByClassName(
									'MuiAutocomplete-clearIndicator'
								)[0];
								if (ele) ele.click();
							}
						}}
					>
						Add
					</Button>
				</Box>
			</Box>
			<Table sx={{ minHeight: '22.5vmin', marginBlock: '1em' }}>
				<thead style={{ backgroundColor: '#e4e4e4', textAlign: 'left' }}>
					<tr>
						<th>#</th>
						<th>Particulars</th>
						<th>Qty</th>
						<th>Rate</th>
						<th>Basic Amt</th>
						<th>Discount</th>
						<th>Total</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{services.map((ser, idx) => (
						<tr key={idx}>
							<td>{idx + 1}</td>
							<td>{ser.particulars}</td>
							<td>{ser.quantity}</td>
							<td>{ser.rate || serviceDetails?.rate}</td>
							<td>{ser.basicAmount}</td>
							<td>{ser.discount}</td>
							<td>{ser.basicAmount - ser.discount}</td>
							<td onClick={() => remove(idx)}>
								<Delete style={{ color: 'red' }} />
							</td>
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr style={{ borderTop: '3px double black' }}>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>{total.discount}</td>
						<td>{total.basicAmount - total.discount}</td>
					</tr>
				</tfoot>
			</Table>
		</>
	);
};

export default ServiceInfo;
