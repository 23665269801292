import { Datagrid, List, TextField } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import SectionTitle from '../../../../elements/SectionTitle';

const ManufacturerList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.manufacturer.title" />
			<List filters={undefined} sort={{}} perPage={25}>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="name" />
						<TextField source="country" />
						<TextField source="address" />
						<TextField source="website" />
						<TextField source="email" />
						<TextField source="phone" />
						<TextField source="actions" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default ManufacturerList;
