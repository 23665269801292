import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { validateForm } from './CustomerCreate';
import CustomerForm from './CustomerForm';

const CustomerEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/customers">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.customers.title')}
				</Typography>
				<CustomerForm />
			</SimpleForm>
		</Edit>
	);
};

export default CustomerEdit;
