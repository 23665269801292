import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";

import DepartmentList from "./DepartmentList";
import DepartmentCreate from "./DepartmentCreate";
import DepartmentEdit from "./DepartmentEdit";

const resource = {
  list: DepartmentList,
  create: DepartmentCreate,
  edit: DepartmentEdit,
  icon: HomeWorkRoundedIcon,
};

export default resource;
