import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

export default function DoctorForm() {
	return (
		<>
			<TextInput source="name" isRequired fullWidth />
			<ReferenceInput
				label="Department"
				source="department"
				reference="department"
			>
				<AutocompleteInput
					optionText="title"
					optionValue="id"
					fullWidth
					isRequired
				/>
			</ReferenceInput>
		</>
	);
}
