import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import CustomerForm from './CustomerForm';

export const validateForm = (values) => {
	const errors = {};
	if (!values.name) {
		errors.name = 'ra.validation.required';
	}
	return errors;
};

const CustomerCreate = () => (
	<Create redirect="/customers">
		<SimpleForm validate={validateForm}>
			<SectionTitle label="pharmacy.customers.title" />
			<CustomerForm />
		</SimpleForm>
	</Create>
);

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default CustomerCreate;
