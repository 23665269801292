import {
  Datagrid,
  List,
  CreateButton,
  ExportButton,
  SelectColumnsButton,
  FilterButton,
  TopToolbar,
  TextInput,
  TextField,
} from "react-admin";
import { Box, Typography, useMediaQuery } from "@mui/material";
import SectionTitle from "../../../elements/SectionTitle";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";
import MedicalServicesRoundedIcon from "@mui/icons-material/MedicalServicesRounded";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton
      resource="/service/excel"
      label="Import"
      icon={<PublishRoundedIcon />}
    />
    <CreateButton />

    <ExportButton />
  </TopToolbar>
);

const postFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

const Empty = () => (
  <Box textAlign="center" color={"grey"} width="100%" m={5}>
    <MedicalServicesRoundedIcon sx={{ fontSize: "220px" }} />
    <Typography variant="h4" paragraph>
      No Service yet.
    </Typography>
    <Typography variant="body1" mb={4}>
      Create one or import from a file
    </Typography>
    <CreateButton variant="contained" sx={{ marginRight: "15px" }} />
    <CreateButton
      variant="contained"
      resource="/service/excel"
      label="Import"
      icon={<PublishRoundedIcon />}
    />
  </Box>
);

const ShowList = () => {
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <SectionTitle label="setting.menu.service" />
      <List
        empty={<Empty />}
        actions={<ListActions />}
        perPage={25}
        filters={postFilters}
      >
        {isXsmall ? null : (
          <Datagrid
            optimized
            rowClick="edit"
            sx={{
              "& .column-groups": {
                md: { display: "none" },
                lg: { display: "table-cell" },
              },
            }}
          >
            <TextField source="name" />
            <TextField
              source="serviceCategory.title"
              label="Service Category"
            />
            <TextField source="code" />
            <TextField source="rate" />
          </Datagrid>
        )}
      </List>
    </>
  );
};

export default ShowList;
