import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import {
	AutocompleteInput,
	ReferenceInput,
	useChoicesContext,
} from 'react-admin';
import isTimeBetween from '../_helper/isTimeBetween';

export default function ShiftSelect() {
	return (
		<ReferenceInput label="Shift" source="shift" reference="shift">
			<AutoComplete />
		</ReferenceInput>
	);
}

function AutoComplete() {
	const { allChoices } = useChoicesContext();
	const defaultValue = useMemo(() => {
		const value = allChoices.find((choice) => {
			return isTimeBetween(choice?.startTime, choice?.endTime);
		});
		console.log('default shift', value);
		return value;
	}, [allChoices]);

	return (
		<AutocompleteInput
			optionText="title"
			optionValue="id"
			fullWidth
			isRequired
			size="small"
			defaultValue={defaultValue?.id}
		/>
	);
}

// {
//   "title": "Night",
//   "startTime": "2023-12-24T13:15:36.771Z",
//   "endTime": "2023-12-24T00:15:51.715Z",
//   "createdBy": "652e63590dfc5a0043d91e03",
//   "createdAt": "2023-12-24T07:53:56.733Z",
//   "id": "6587e394e99b7f001d8d488d"
// }
