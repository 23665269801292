import { Box } from '@mui/material';
import {
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	BooleanInput,
} from 'react-admin';

export default function BrandForm() {
	return (
		<>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)',
					width: '100%',
					columnGap: '1rem',
				}}
			>
				<TextInput source="name" fullWidth />
				<ReferenceInput
					label="Generic"
					source="generic"
					reference="genericNames"
				>
					<AutocompleteInput
						optionText="title"
						optionValue="id"
						size="small"
						fullWidth
						// isRequired
					/>
				</ReferenceInput>
				<TextInput source="form" fullWidth />
				<TextInput source="salesUnit" fullWidth />
				<TextInput source="packageUnit" fullWidth />
				<TextInput source="unitsPerPackaging" fullWidth />
				<TextInput source="reOrderLevel" fullWidth label="Reorder Level" />
				<TextInput source="reOrderQty" fullWidth label="Reorder Quantity" />
				<TextInput source="defaultDose" fullWidth />
				<TextInput source="defaultRoute" fullWidth />
				<TextInput source="defaultFrequency" fullWidth />
				<ReferenceInput
					label="Manufacturer"
					source="manufacturer"
					reference="manufacturer"
				>
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						size="small"
						fullWidth
						// isRequired
					/>
				</ReferenceInput>
				<ReferenceInput label="Vendor" source="vendor" reference="vendors">
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						size="small"
						fullWidth
						// isRequired
					/>
				</ReferenceInput>
				<ReferenceInput
					label="Product Category"
					source="productCategory"
					reference="brandCategory"
				>
					<AutocompleteInput
						optionText="title"
						optionValue="id"
						size="small"
						fullWidth
						// isRequired
					/>
				</ReferenceInput>
			</Box>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(4, 1fr)',
					width: '100%',
				}}
			>
				<BooleanInput source="taxable" fullWidth />
				<BooleanInput source="narcoticProduct" fullWidth />
				<BooleanInput
					source="editableAmount"
					label="Editable Amount on Billng"
					fullWidth
				/>
				<BooleanInput source="insuranceItem" fullWidth />
			</Box>
		</>
	);
}
