import BillSearch from './billSearch';
import NarcoticsSalesReport from './narcoticsSalesReport';
import ProductSalesRegister from './productSalesRegister';
import ProductSalesReturnRegister from './productSalesReturnRegister';
import SalesRegister from './salesRegister';
import SalesReturnRegister from './salesReturnRegister';
import SalesSummary from './salesSummary';

export const billSearch = {
	list: BillSearch,
	icon: null,
};

export const salesSummary = {
	list: SalesSummary,
	icon: null,
};

export const salesRegister = {
	list: SalesRegister,
	icon: null,
};

export const productSalesRegister = {
	list: ProductSalesRegister,
	icon: null,
};

export const productSalesReturnRegister = {
	list: ProductSalesReturnRegister,
	icon: null,
};

export const salesReturnRegister = {
	list: SalesReturnRegister,
	icon: null,
};

export const narcoticsSalesReport = {
	list: NarcoticsSalesReport,
	icon: null,
};
