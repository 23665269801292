import { Box } from "@mui/material";
import React from "react";
import { Button, Datagrid, FunctionField, List } from "react-admin";
import SectionTitle from "../../../../elements/SectionTitle";
import handlePrint from "../../../../_helper/print";

export default function TemplateList() {
  return (
    <List empty={false}>
      <Box p={2} pb={0}>
        <SectionTitle label="pathology.administrative.template" />
      </Box>
      <Datagrid rowClick={false}>
        <FunctionField
          label="Lab Report"
          render={(record) => (
            <Button
              onClick={() => record?.labReport && handlePrint(record.labReport)}
            >
              Download
            </Button>
          )}
        />
        <FunctionField
          label="Lab Sticker"
          render={(record) =>
            record?.labSticker && (
              <Button onClick={() => handlePrint(record.labSticker)}>
                Download
              </Button>
            )
          }
        />
        <FunctionField
          label="Online Lab Report"
          render={(record) =>
            record?.onlineLabReport && (
              <Button onClick={() => handlePrint(record.onlineLabReport)}>
                Download
              </Button>
            )
          }
        />
        <FunctionField
          label="Test Tube Label"
          render={(record) =>
            record?.testTubeLabel && (
              <Button onClick={() => handlePrint(record.testTubeLabel)}>
                Download
              </Button>
            )
          }
        />
      </Datagrid>
    </List>
  );
}
