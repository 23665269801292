import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import {
	AutocompleteInput,
	DateInput,
	NumberInput,
	ReferenceInput,
	SelectInput,
	TextInput,
} from 'react-admin';
import ArrayTable, {
	CustomAutoInput,
} from '../../../elements/CustomArrayInput';
import { useFormContext } from 'react-hook-form';
import CustomAutoMUI from '../../../elements/CustomAutoMUI';
import dayjs from 'dayjs';
import NepaliDateInput from '../../../elements/NepaliDateInput';
import { ADToBS } from '../../../elements/DatePicker/AdToBsConverter/AdBsConverter';

export default function SalesReturnForm({ state }) {
	const [referenceBill, setReferenceBill] = useState(null);
	const { setValue } = useFormContext();
	console.log(referenceBill);
	useEffect(() => {
		if (referenceBill) {
			setValue('patient', referenceBill?.patient);
			setValue('name', referenceBill?.name);
			setValue('charity', referenceBill?.charity);
			setValue('discount', referenceBill?.discount);
			setValue('discountAmount', referenceBill?.discountAmount);
			referenceBill?.taxableAmount &&
				setValue('taxableAmount', referenceBill?.taxableAmount);
			setValue('roundOff', referenceBill?.roundOff);
			setValue('tenderAmount', referenceBill?.tenderAmount);
			setValue('changeAmount', referenceBill?.changeAmount);
			setValue('total', referenceBill?.total);
			setValue('doctor', referenceBill?.doctor);
			state[1](referenceBill?.brands);
		}
	}, [referenceBill, setValue]);

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: '3fr auto 1fr',
				columnGap: '1rem',
			}}
		>
			<Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(4, 1fr)',
						width: '100%',
						columnGap: '1rem',
					}}
				>
					<NepaliDateInput
						source="date"
						defaultValue={ADToBS(dayjs(Date.now()).format('YYYY/MM/DD'))}
					/>
					<Box sx={{ gridColumn: 'span 2 / span 2' }} />
					<ReferenceInput
						source="referenceSalesNumber"
						reference="pharmacyBilling"
						sort={{ field: 'billNo', order: 'DESC' }}
					>
						<AutocompleteInput
							optionText="billNo"
							onChange={(id, record) => setReferenceBill(record)}
						/>
					</ReferenceInput>

					<ReferenceInput
						label="Patient Id"
						source="patient"
						reference="patient"
					>
						<AutocompleteInput
							label="Patient ID"
							optionText="patientId"
							optionValue="id"
							size="small"
							fullWidth
							isRequired
						/>
					</ReferenceInput>
					<ReferenceInput
						label="Patient Name"
						source="patient"
						reference="patient"
					>
						<AutocompleteInput
							label="Patient Name"
							optionText={(record) =>
								record?.firstName + ' ' + record?.lastName
							}
							optionValue="id"
							size="small"
							fullWidth
							isRequired
						/>
					</ReferenceInput>
					<ReferenceInput label="Scheme" source="charity" reference="charity">
						<AutocompleteInput
							label="Scheme"
							optionText="title"
							optionValue="id"
							size="small"
							fullWidth
							isRequired
						/>
					</ReferenceInput>
					<TextInput source="name" label="Invoice Name" />
				</Box>
				<Divider
					sx={{ width: '100%', marginBottom: '1em', marginTop: '-0.75em' }}
				/>

				<ArrayTable
					inputs={inputs}
					headers={headers}
					state={state}
					actions={{ delete: true }}
					inputGridColumn={3}
				/>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(4, 1fr)',
						width: '100%',
						columnGap: '1rem',
					}}
				>
					<ReferenceInput source="doctor" reference="doctor">
						<CustomAutoMUI label="Prescribed By Doctor" />
					</ReferenceInput>
					<SelectInput
						label="Payment Method"
						source="paymentMethod"
						fullWidth
						sx={{ marginTop: 0 }}
						defaultValue="cash"
						choices={[
							{ id: 'cash', name: 'Cash' },
							{ id: 'credit', name: 'Credit' },
						]}
					/>
					<TextInput source="remarks" sx={{ gridColumn: 'span 2 / span 2' }} />
				</Box>
			</Box>

			<Divider orientation="vertical" sx={{ borderWidth: 2 }} />
			<SalesReturnAsideForm state={state} />
		</Box>
	);
}

export function SalesReturnAsideForm({ state }) {
	const { setValue, watch } = useFormContext();
	const [discount, roundOff, basicAmount, vat, discountAmount, total] = watch([
		'discount',
		'roundOff',
		'basicAmount',
		'vat',
		'discountAmount',
		'total',
	]);
	useEffect(() => {
		if (state[0].length > 0) {
			setValue(
				'basicAmount',
				state[0]?.reduce((acc, curr) => acc + +curr?.amount, 0) || 0
			);
			setValue(
				'taxableAmount',
				state[0]?.reduce((acc, curr) => {
					return acc + (curr?.brand?.data?.taxable ? curr?.amount : 0);
				}, 0) || 0
			);
			setValue(
				'vat',
				state[0]?.reduce((acc, curr) => {
					return acc + (curr?.brand?.data?.taxable ? curr?.amount * 0.13 : 0);
				}, 0) || 0
			);
		}
	}, [setValue, state]);

	useEffect(() => {
		setValue(
			'discountAmount',
			((basicAmount * discount) / 100 || 0).toFixed(2)
		);
	}, [discount, setValue, basicAmount]);

	useEffect(() => {
		setValue(
			'total',
			(+basicAmount + +vat - discountAmount + +roundOff || 0).toFixed(2)
		);
	}, [discountAmount, setValue, basicAmount, vat, roundOff]);

	return (
		<Box>
			<NumberInput source="basicAmount" disabled fullWidth />
			<Box sx={{ display: 'flex' }}>
				<NumberInput source="discount" label="%" sx={{ flexBasis: '40%' }} />
				<NumberInput
					source="discountAmount"
					label="Discount Amount"
					sx={{ flexGrow: 1 }}
					onChange={(e) => {
						setValue(
							'discount',
							((e.target.value * 100) / basicAmount || 0).toFixed(2)
						);
						return e.target.value;
					}}
				/>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<NumberInput source="taxableAmount" disabled label="Taxable Amount" />
				<NumberInput source="vat" label="VAT" disabled />
			</Box>
			<NumberInput source="roundOff" label="Round Off" fullWidth />
			<NumberInput source="total" label="Net Amount" fullWidth disabled />
			<NumberInput
				source="tenderAmount"
				onChange={(e) => {
					setValue('changeAmount', (e.target.value - total || 0).toFixed(2));
				}}
				label="Tender Amount"
				fullWidth
			/>
			<NumberInput
				source="changeAmount"
				disabled
				label="Change Amount"
				fullWidth
			/>
		</Box>
	);
}

const inputs = [
	{
		key: 1,
		type: 'autoSelect',
		source: 'brand',
		reference: 'brand',
		optionText: 'name',
	},
	{
		key: 2,
		type: 'custom',
		render: (values, onValueChange, setValues) =>
			values?.brand?.data ? (
				<CustomAutoInput
					onValueChange={onValueChange}
					input={{
						source: 'batchNumber',
						reference: `store/brand/${values?.brand?.data?.id}`,
						optionText: 'batchNumber',
						onChange: (value, _, setValues) =>
							setValues((prev) => ({
								...prev,
								rate: value?.salesRPUnit || 0,
							})),
					}}
					values={values}
					setValues={setValues}
				/>
			) : (
				<TextInput source="batchNumber" disabled />
			),
	},
	{
		key: 3,
		type: 'number',
		source: 'quantity',
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				amount: (value * (_?.rate || 0) || 0).toFixed(2),
			})),
	},
	{
		key: 4,
		type: 'number',
		source: 'rate',
		props: { disabled: true },
	},
	{
		key: 4,
		type: 'number',
		source: 'amount',
		props: {
			disabled: true,
		},
	},
];

const headers = [
	[
		{
			key: 1,
			source: 'brand',
			label: 'Brand/Description',
			render: (value) => value?.brand?.value || value?.brand?.name,
		},
		{
			key: 2,
			source: 'batchNumber',
			label: 'Batch',
			render: (value) => value?.batchNumber?.value || value?.batch,
		},
		{
			key: 3,
			source: 'quantity',
			label: 'Qty',
		},
		{
			key: 4,
			source: 'rate',
			label: 'Rate',
		},
		{
			key: 5,
			source: 'amount',
			label: 'Amount',
		},
	],
];
