import LabDeptCreate from './labDeptCreate';
import LabDeptEdit from './labDeptEdit';
import LabDeptList from './labDeptList';

const labDept = {
	list: LabDeptList,
	create: LabDeptCreate,
	edit: LabDeptEdit,
	icon: null,
};

export default labDept;
