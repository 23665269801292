import salesReturnCreate from './salesReturnCreate';
import salesReturnEdit from './salesReturnEdit';
import salesReturnList from './salesReturnList';
import { Reply } from '@mui/icons-material';

const salesReturn = {
	list: salesReturnList,
	create: salesReturnCreate,
	edit: salesReturnEdit,
	icon: Reply,
};

export default salesReturn;
