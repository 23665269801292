import {
  Create,
  FileField,
  FileInput,
  SimpleForm,
  useTranslate,
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (values) => {
  const errors = {};
  if (!values.excel) {
    errors.excel = "ra.validation.required";
  }
  return errors;
};

const ServiceCreateExcel = () => (
  <Create
    redirect="/service"
    transform={(data) => {
      return { ...data, key: "excel" };
    }}
    resource="service/excel"
    mutationOptions={{ meta: { type: "file" } }}
  >
    <SimpleForm sx={{ maxWidth: 500 }} validate={validateForm}>
      <SectionTitle label="setting.menu.service" />
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FileInput
            accept={[
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            source="excel"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export default ServiceCreateExcel;
