import { Box, Typography } from '@mui/material';
import React from 'react';
import {
	Show,
	SimpleForm,
	SimpleShowLayout,
	useGetOne,
	useShowContext,
	CheckboxGroupInput,
	useUpdate,
	useCreate,
} from 'react-admin';
import camelToFlat from '../../../_helper/camelToFlat';
import SectionTitle from '../../../elements/SectionTitle';
import { useNavigate } from 'react-router-dom';

export default function UserShow() {
	return (
		<Show>
			<SimpleShowLayout>
				{/* <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
					<TextField source="fullname_en" label="Full Name" />
					<TextField source="designation_en" label="Designation" />
					<TextField source="email" />
					<TextField source="phone_en" label="Phone" />
				</Box> */}
				<SectionTitle label="Permissions" />
				<Permissions />
			</SimpleShowLayout>
		</Show>
	);
}

function Permissions() {
	const navigate = useNavigate();
	const { record } = useShowContext();
	const { data, refetch } = useGetOne(`permission`, { id: record.id });
	const { data: pData } = useGetOne('permission', { id: '' });
	const [create] = useCreate(
		`permission`,
		{},
		{
			onSuccess: () => {
				refetch();
				navigate('/users');
			},
		}
	);
	const choices = {
		d: 'Delete',
		r: 'View All',
		a: 'Update',
		w: 'Create',
		s: 'View One',
	};
	return (
		<SimpleForm
			sanitizeEmptyValues
			onSubmit={(data) => {
				const d = data?.permissions;
				for (let k in d) {
					d[k] = d[k]?.join('') || '';
				}
				create(`permission`, { data: { permission: d, user: record.id } });
			}}
		>
			{pData?.permission?.map((permission) => (
				<Box key={permission}>
					<Typography fontWeight={600} color="primary.main">
						{permission.name
							.split('_')
							.map((v) => camelToFlat(v))
							.join(' ')}
					</Typography>
					<CheckboxGroupInput
						defaultValue={data?.permission?.[permission.name]?.split('')}
						source={`permissions.${permission.name}`}
						label="Permissions"
						choices={permission?.choices?.map((choice) => ({
							id: choice,
							name: choices[choice],
						}))}
					/>
				</Box>
			))}
		</SimpleForm>
	);
}
