import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import OpeningStockForm from './OpeningStockForm';
import { useState } from 'react';

export const validateForm = (values) => {
	const errors = {};
	return errors;
};

const OpeningStockCreate = () => {
	const [items, setItems] = useState([]);
	return (
		<Create
			resource="store"
			transform={(data) => {
				const body = { ...data };
				delete body.brand;
				delete body.batchNumber;
				delete body.expiryDate;
				delete body.pkgQty;
				delete body.unitQty;
				delete body.freeQty;
				delete body.totalQty;
				delete body.ratePP;
				delete body.amount;
				delete body.vat;
				delete body.ccRate;
				delete body.ccAmt;
				delete body.margin;
				delete body.salesRPPkg;
				delete body.salesRPUnit;
				body.items = items?.map((item) => ({
					...item,
					brand: item?.brand?.id,
				}));
				body.isOpening = true;
				return body;
			}}
			redirect="/openingStock"
		>
			<SimpleForm
				validate={validateForm}
				sanitizeEmptyValues
				sx={{ overflow: 'visible' }}
			>
				<SectionTitle label="pharmacy.store.openingstock" />
				<OpeningStockForm state={[items, setItems]} />
			</SimpleForm>
		</Create>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default OpeningStockCreate;
