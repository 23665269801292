import { Box } from '@mui/material';
import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import TemplateForm from './templateForm';

export default function TemplateEdit() {
	async function onChange(file) {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('fileName', file.name);
			let response = await fetch(process.env.REACT_APP_BACKEND_URL + '/file', {
				method: 'POST',
				body: formData,
			});
			let res = await response.json();
			return res?.data;
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<Edit
			transform={async (data) => {
				const tempData = {};

				if (data?.labReport?.rawFile)
					tempData.labReport = await onChange(data.labReport.rawFile);
				if (data?.labSticker?.rawFile)
					tempData.labSticker = await onChange(data.labSticker.rawFile);
				if (data?.onlineLabReport?.rawFile)
					tempData.onlineLabReport = await onChange(
						data.onlineLabReport.rawFile
					);
				if (data?.testTubeLabel?.rawFile)
					tempData.testTubeLabel = await onChange(data.testTubeLabel.rawFile);
				return tempData;
			}}
			redirect="list"
		>
			<Box p={2} pb={0}>
				<SectionTitle label="pathology.administrative.template" />
			</Box>
			<SimpleForm>
				<TemplateForm />
			</SimpleForm>
		</Edit>
	);
}
