import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import { Box } from '@mui/material';

export default function PaymentList() {
	return (
		<List sort={{ field: '_id', order: 'DESC' }}>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label={'pharmacy.account.payment'} />
			</Box>
			<Datagrid rowClick="edit">
				<TextField source="date" />
				<TextField source="paymentNo" />
				<TextField source="receiveId.name" label="Patient/Customer" />
				<TextField source="transactionType" />
				<TextField source="chequeNo" />
				<TextField source="voucherNo" />
				<TextField source="type" />
				<TextField source="amount" />
			</Datagrid>
		</List>
	);
}
