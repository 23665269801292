import { Box } from '@mui/material';
import { DateInput, TextInput } from 'react-admin';
import ArrayTable, {
	CustomAutoInput,
} from '../../../../elements/CustomArrayInput';
import dayjs from 'dayjs';

export default function StockOutForm({ state }) {
	return (
		<>
			<Box sx={{ width: '100%' }}>
				<ArrayTable
					inputs={inputs}
					headers={headers}
					state={state}
					inputGridColumn={3}
				/>

				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(3, 1fr)',
						width: '100%',
						columnGap: '1rem',
					}}
					r
				>
					<DateInput
						source="date"
						defaultValue={dayjs(Date.now()).format('MM/DD/YYYY')}
					/>
					<TextInput
						source="remarks"
						multiline
						sx={{ gridColumn: 'span 2 / span 2' }}
					/>
				</Box>
			</Box>
		</>
	);
}

const inputs = [
	{
		key: 1,
		type: 'autoSelect',
		source: 'brand',
		reference: 'brand',
	},
	{
		key: 2,
		type: 'custom',
		render: (values, onValueChange, setValues) =>
			values?.brand?.data ? (
				<CustomAutoInput
					onValueChange={onValueChange}
					input={{
						source: 'batchNumber',
						reference: `store/brand/${values?.brand?.data?.id}`,
						optionText: 'batchNumber',
						onChange: (value, _, setValues) =>
							setValues((prev) => ({ ...prev, rate: value?.ratePUnit })),
					}}
					values={values}
					setValues={setValues}
				/>
			) : (
				<TextInput source="batchNumber" disabled />
			),
	},
	{
		key: 3,
		source: 'quantity',
		type: 'number',
	},
	{
		key: 4,
		source: 'cause',
		type: 'text',
		props: { sx: { gridColumn: 'span 2 / span 2' } },
	},
];

const headers = [
	[
		{
			key: 1,
			label: 'Brand',
			source: 'brand',
			render: (value) => value.brand.data.name,
		},
		{ key: 2, label: 'Quantity', source: 'quantity' },
		{ key: 3, label: 'Cause', source: 'cause' },
	],
];
