import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import TemplateForm from './TemplateForm';

const accept = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const validateForm = (values) => {
	console.log(values);
	const errors = {};
	if (values.bill) {
		if (values.bill.rawFile && !accept.includes(values.bill.rawFile.type))
			errors.bill = 'File must be docs or docx';
	}
	if (values.ticket) {
		if (values.ticket.rawFile && !accept.includes(values.ticket.rawFile.type))
			errors.ticket = 'File must be docs or docx';
	}
	return errors;
};

const DocumentCreate = () => {
	async function onChange(file) {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('fileName', file.name);
			let response = await fetch(process.env.REACT_APP_BACKEND_URL + '/file', {
				method: 'POST',
				body: formData,
			});
			let res = await response.json();
			return res?.data;
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<Create
			redirect="/template"
			transform={async (data) => {
				const tempData = {};
				if (data?.bill?.rawFile)
					tempData.bill = await onChange(data.bill.rawFile);
				if (data?.ticket?.rawFile)
					tempData.ticket = await onChange(data.ticket.rawFile);
				return tempData;
			}}
		>
			<SimpleForm
				sx={{ maxWidth: 500 }}
				mode="onBlur"
				reValidateMode="onBlur"
				validate={validateForm}
			>
				<SectionTitle label="document.template" />
				<TemplateForm accept={accept} />
			</SimpleForm>
		</Create>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default DocumentCreate;
