import { Box } from "@mui/material"
import { TextInput } from "react-admin"

export default function DepartmentForm() {
  return (
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
				<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
					<TextInput source="title" isRequired fullWidth />
				</Box>
				<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
					<TextInput type="number" source="dailyQuota" fullWidth />
				</Box>
			</Box>
  )
}