import { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  colors,
  Button,
} from "@mui/material";
import React from "react";
import SectionTitle from "../../../../elements/SectionTitle";
import { useGetList } from "react-admin";
import { Close, FileDownload } from "@mui/icons-material";
import { NepaliDateInputCommon } from "../../../../elements/NepaliDateInput";
import { ADToBS } from "../../../../elements/DatePicker/AdToBsConverter/AdBsConverter";
import dayjs from "dayjs";

export default function MulDartaRegisterList() {
  const [dateRange, setDateRange] = useState([
    ADToBS(dayjs(new Date()).format("YYYY/MM/DD")),
    ADToBS(dayjs(new Date()).format("YYYY/MM/DD")),
  ]);
  const { data, isLoading } = useGetList(`registrationReport/mulDarta`, {
    filter: {
      ...(dateRange[0] &&
        dateRange[1] && {
          date: { $gte: dateRange[0], $lte: dateRange[1] },
        }),
    },
  });

  function handleDownload() {
    fetch(
      process.env.REACT_APP_BACKEND_URL +
        `registrationReport/mulDarta/pdf?filter=${JSON.stringify({
          ...(dateRange[0] &&
            dateRange[1] && {
              date: { $gte: dateRange[0], $lte: dateRange[1] },
            }),
        })}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("auth"))?.access
          }`,
        },
      }
    )
      .then(async (res) => await res.json())
      .then((res) => {
        console.log(res);
        if (res?.url) {
          const a = document.createElement("a");
          a.target = "_blank";
          a.href = res?.url;
          a.download = "salesSummary";
          a.click();
        }
      });
  }
  function handleDateChange([startDate, endDate]) {
    setDateRange((prev) => [startDate || prev[0], endDate || prev[1]]);
  }
  console.log("dateRange", dateRange);
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <NepaliDateInputCommon
            label="From"
            value={dateRange[0]}
            onChange={(date) => handleDateChange([date, null])}
          />
          <NepaliDateInputCommon
            label="To"
            value={dateRange[1]}
            onChange={(date) => handleDateChange([null, date])}
          />
        </Box>
        <Box sx={{ "& button": { ml: 1 } }}>
          <Button
            startIcon={<Close />}
            onClick={() => setDateRange([null, null])}
          >
            Clear Filter
          </Button>
          <Button
            startIcon={<FileDownload />}
            variant="contained"
            onClick={handleDownload}
          >
            Export to PDF
          </Button>
        </Box>
      </Box>
      <SectionTitle
        // label="registration.reports.mulDartaRegister"
        label="मूल दर्ता रजिस्टर"
        sx={{ textAlign: "center", fontWeight: 600 }}
      />
      <Box>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? colors.common.black
                    : colors.grey[100],
              })}
            >
              <TableRow>
                <TableCell rowSpan={3}>लिङ्ग</TableCell>
                <TableCell colSpan={7} align="center">
                  पुरानो सेवाग्राही (महल नं १)
                </TableCell>
                <TableCell colSpan={7} align="center">
                  नयाँ सेवाग्राही (महल नं २)
                </TableCell>
                <TableCell colSpan={7} align="center">
                  जातीगत आधारमा सेवाग्राही (महल नं ६)
                </TableCell>
                <TableCell rowSpan={3}>जम्मा</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  उमेर समूह
                </TableCell>
                <TableCell rowSpan={2}>जम्मा</TableCell>
                <TableCell colSpan={6} align="center">
                  उमेर समूह
                </TableCell>
                <TableCell rowSpan={2}>जम्मा</TableCell>
                <TableCell colSpan={6} align="center">
                  जात/जाती समूह
                </TableCell>
                <TableCell rowSpan={2}>जम्मा</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>०-९</TableCell>
                <TableCell>१०-१४</TableCell>
                <TableCell>१५-१९</TableCell>
                <TableCell>२०-५९</TableCell>
                <TableCell>६०-६९</TableCell>
                <TableCell>≥७०</TableCell>
                <TableCell>०-९</TableCell>
                <TableCell>१०-१४</TableCell>
                <TableCell>१५-१९</TableCell>
                <TableCell>२०-५९</TableCell>
                <TableCell>६०-६९</TableCell>
                <TableCell>≥७०</TableCell>
                <TableCell>दलित</TableCell>
                <TableCell>जनजाती</TableCell>
                <TableCell>मधेसी</TableCell>
                <TableCell>मुस्लिम</TableCell>
                <TableCell>ब्राह्मण/क्षेत्री</TableCell>
                <TableCell>अन्य</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && (
              <TableBody>
                {Object.entries(data?.[0])
                  .filter(([k]) => !["id", "total"].includes(k))
                  .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>
                        {key === "male"
                          ? "पुरुष"
                          : key === "female"
                          ? "महिला"
                          : key === "others"
                          ? "अन्य"
                          : key}
                      </TableCell>
                      <TableCell>
                        {value?.oldPatient?.ageGroup["0-9"]}
                      </TableCell>
                      <TableCell>
                        {value?.oldPatient?.ageGroup["10-14"]}
                      </TableCell>
                      <TableCell>
                        {value?.oldPatient?.ageGroup["15-19"]}
                      </TableCell>
                      <TableCell>
                        {value?.oldPatient?.ageGroup["20-59"]}
                      </TableCell>
                      <TableCell>
                        {value?.oldPatient?.ageGroup["60-69"]}
                      </TableCell>
                      <TableCell>
                        {value?.oldPatient?.ageGroup["≥70"]}
                      </TableCell>
                      <TableCell>{value?.oldPatient?.total}</TableCell>
                      <TableCell>
                        {value?.newPatient?.ageGroup["0-9"]}
                      </TableCell>
                      <TableCell>
                        {value?.newPatient?.ageGroup["10-14"]}
                      </TableCell>
                      <TableCell>
                        {value?.newPatient?.ageGroup["15-19"]}
                      </TableCell>
                      <TableCell>
                        {value?.newPatient?.ageGroup["20-59"]}
                      </TableCell>
                      <TableCell>
                        {value?.newPatient?.ageGroup["60-69"]}
                      </TableCell>
                      <TableCell>
                        {value?.newPatient?.ageGroup["≥70"]}
                      </TableCell>
                      <TableCell>{value?.newPatient?.total}</TableCell>
                      <TableCell>
                        {value?.casteBasedPatient?.casteGroup?.["दलित"]}
                      </TableCell>
                      <TableCell>
                        {value?.casteBasedPatient?.casteGroup?.["जनजाती"]}
                      </TableCell>
                      <TableCell>
                        {value?.casteBasedPatient?.casteGroup?.["मधेसी"]}
                      </TableCell>
                      <TableCell>
                        {value?.casteBasedPatient?.casteGroup?.["मुस्लिम"]}
                      </TableCell>
                      <TableCell>
                        {
                          value?.casteBasedPatient?.casteGroup?.[
                            "ब्राह्मण/क्षेत्री"
                          ]
                        }
                      </TableCell>
                      <TableCell>
                        {value?.casteBasedPatient?.casteGroup?.["अन्य"]}
                      </TableCell>
                      <TableCell>{value?.casteBasedPatient?.total}</TableCell>
                      <TableCell>{value?.total}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            <TableFooter sx={{ borderTop: `2px solid ${colors.grey[400]}` }}>
              <TableRow>
                <TableCell colSpan={22} sx={{ fontWeight: 700 }}>
                  जम्मा
                </TableCell>
                <TableCell>{data?.total}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
