import ReferralList from './referralList';
import ReferralCreate from './referralCreate';
import ReferralEdit from './referralEdit';

const referral = {
	list: ReferralList,
	create: ReferralCreate,
	edit: ReferralEdit,
};

export default referral;
