import SampleGrpCreate from './sampleGrpCreate';
import SampleGrpEdit from './sampleGrpEdit';
import SampleGrpList from './sampleGrpList';

const sampleGrp = {
	list: SampleGrpList,
	create: SampleGrpCreate,
	edit: SampleGrpEdit,
	icon: null,
};

export default sampleGrp;
