import {
	Button,
	Datagrid,
	FunctionField,
	List,
	TextField,
	useTranslate,
	downloadCSV,
	DateInput,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useMediaQuery, Typography } from '@mui/material';
import handlePrint from '../../../_helper/print';

const SalesReturnList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.salesReturn" />
			<List
				filters={[
					<DateInput label="Start Date" source="date.$gte" />,
					<DateInput label="End Date" source="date.$lte" />,
				]}
				sort={{}}
				perPage={25}
				exporter={(data) => {
					let postsForExport = data.map((post) => {
						let d = { ...post };
						delete d.patient;
						delete d.brands;
						delete d.createdAt;
						delete d.createdBy;
						delete d.id;
						delete d.print;
						delete d.charity;
						delete d.doctor;
						return d;
					});
					const lastRow = {
						basicAmount: data?.reduce(
							(a, c) => +(a || 0) + +(c?.basicAmount || 0),
							0
						),
						discountAmount: data?.reduce(
							(a, c) => +(a || 0) + +(c?.discountAmount || 0),
							0
						),
						total: data?.reduce((a, c) => +(a || 0) + +(c?.total || 0), 0),
						tenderAmount: data?.reduce(
							(a, c) => +(a || 0) + +(c?.tenderAmount || 0),
							0
						),
						changeAmount: data?.reduce(
							(a, c) => +(a || 0) + +(c?.changeAmount || 0),
							0
						),
						date: 'Total',
					};
					postsForExport.push(lastRow);
					jsonExport(
						postsForExport,
						{
							headers: [
								'date',
								'billNo',
								'name',
								'basicAmount',
								'discount',
								'discountAmount',
								'roundOff',
								'total',
								'tenderAmount',
								'changeAmount',
							],
						},
						(err, csv) => {
							downloadCSV(csv, 'billings'); // download as 'posts.csv` file
						}
					);
				}}
			>
				{isXsmall ? null : (
					<Datagrid
						optimized
						// rowClick={(id) => `/purchase/${id}`}
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="date" />
						<TextField source="billNo" />
						<TextField source="basicAmount" label="Sub Total" />
						<TextField source="discountAmount" />
						<TextField source="taxableAmount" />
						<TextField source="vatAmount" />
						<TextField label="Net Total" source="total" />
						<FunctionField
							label="Print"
							render={(record) =>
								record?.print ? (
									<Button onClick={() => handlePrint(record?.print)}>
										Print
									</Button>
								) : (
									<></>
								)
							}
						/>
					</Datagrid>
				)}
			</List>
		</>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default SalesReturnList;
