import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { validateForm } from './CharityCreate';
import CharityForm from './CharityForm';

const CharityEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/charity">
			<SimpleForm validate={validateForm}>
				<div>
					<Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
						<Grid item xs={12} md={8}>
							<Typography variant="h6" gutterBottom>
								{translate('setting.menu.charity')}
							</Typography>
							<CharityForm />
						</Grid>
					</Grid>
				</div>
			</SimpleForm>
		</Edit>
	);
};

export default CharityEdit;
