// icons
import {
  AddCard,
  NoteAdd,
  SettingsRounded,
  Reorder,
  ListAlt,
} from "@mui/icons-material";

// administration
import fiscalYear from "../pages/administration/fiscalYear";
import users from "../pages/administration/Users";

import patients from "../pages/patient";
import services from "../pages/services/service";
import categories from "../pages/services/category";
import charity from "../pages/settings/charity";
import counter from "../pages/settings/counter";
import doctor from "../pages/settings/doctor";
import nurse from "../pages/settings/nurse";
import shift from "../pages/settings/shift";
import ticketType from "../pages/settings/ticketType";
import department from "../pages/settings/department";
import billing from "../pages/billing";
import CashBalance from "../pages/registration/CashBalance";

// pharmacy master data
import brandCategory from "../pages/pharmacy/master/brandCategory";
import brand from "../pages/pharmacy/master/brand";
import genericNames from "../pages/pharmacy/master/genericNames";
import manufacturer from "../pages/pharmacy/master/manufacturer";
import vendors from "../pages/pharmacy/master/vendors";
import customers from "../pages/pharmacy/master/customers";
import PharmacyCashBalance from "../pages/pharmacy/PharmacyCashBalance";

// pharmacy store
import openingStock from "../pages/pharmacy/store/openingstock";
import purchase from "../pages/pharmacy/store/purchase";
import debitNote from "../pages/pharmacy/store/debitnote";
import stockin from "../pages/pharmacy/store/stockin";
import stockout from "../pages/pharmacy/store/stockout";
import expiryMgmt from "../pages/pharmacy/store/expirymgmt";
import batchedit from "../pages/pharmacy/store/batchedit";
import pharmacyTemplate from "../pages/pharmacy/template";
import salesReturn from "../pages/pharmacy/salesReturn";
import roleManagement from "../pages/administration/RoleManagement";

export const admin = {
  nogroup: [
    {
      key: 1,
      icon: <SettingsRounded />,
      title: {
        i18n: "pos.menu.settings",
        text: "menuSettings",
      },
      submenus: [
        {
          key: 1,
          to: "/counter",
          icon: <counter.icon />,
          title: {
            i18n: "setting.menu.counter",
          },
        },
        {
          key: 2,
          to: "/charity",
          icon: <charity.icon />,
          title: {
            i18n: "setting.menu.charity",
          },
        },
        {
          key: 3,
          to: "/department",
          icon: <department.icon />,
          title: {
            i18n: "setting.menu.department",
          },
        },
        {
          key: 4,
          to: "/doctor",
          icon: <doctor.icon />,
          title: {
            i18n: "setting.menu.doctor",
          },
        },
        {
          key: 5,
          to: "/nurse",
          icon: <nurse.icon />,
          title: {
            i18n: "setting.menu.nurse",
          },
        },
        {
          key: 6,
          to: "/shift",
          icon: <shift.icon />,
          title: {
            i18n: "setting.menu.shift",
          },
        },
        {
          key: 7,
          to: "/ticketType",
          icon: <ticketType.icon />,
          title: {
            i18n: "setting.menu.ticketType",
          },
        },
      ],
    },
    {
      key: 2,
      icon: <services.icon />,
      title: {
        i18n: "pos.menu.services",
        text: "menuServices",
      },
      submenus: [
        {
          key: 1,
          to: "/serviceCategory",
          icon: <categories.icon />,
          title: {
            i18n: "setting.menu.serviceCategory",
          },
        },
        {
          key: 2,
          to: "/service",
          icon: <services.icon />,
          title: {
            i18n: "setting.menu.service",
          },
        },
      ],
    },
    {
      key: "3",
      icon: <fiscalYear.icon />,
      to: "/fiscalYear",
      title: {
        i18n: "admin.fiscalYear",
      },
    },
  ],
  users: [
    {
      key: "create",
      to: "users/create",
      icon: <users.createIcon />,
      title: {
        i18n: "admin.users.create",
      },
    },
    {
      key: "list",
      to: "users",
      icon: <users.icon />,
      title: {
        i18n: "admin.users.list",
      },
    },
    {
      key: "roleManagement",
      to: "roleManagement",
      icon: <roleManagement.icon />,
      title: {
        i18n: "admin.users.roleManagement",
      },
    },
  ],
};

export const registration = {
  nogroup: [
    {
      key: 3,
      to: "/ticket/create",
      icon: <patients.icon />,
      title: {
        i18n: "patient.patient",
      },
      permission: "registration_ticketing:w",
    },
    {
      key: 4,
      to: "/ticket/followup",
      icon: <patients.icon />,
      title: {
        i18n: "patient.followup",
      },
      permission: "registration_ticketing:w",
    },
    {
      key: 5,
      to: "/billing/create",
      icon: <AddCard />,
      title: {
        i18n: "billing.billing",
      },
      permission: "registration_billing:w",
    },
    {
      key: 6,
      to: "/billing",
      icon: <billing.icon />,
      title: {
        i18n: "billing.billingList",
      },
      permission: "registration_billing:r",
    },
    {
      key: 8,
      icon: <billing.icon />,
      popover: <CashBalance />,
      title: {
        i18n: "pharmacy.cashBalance.title",
      },
      permission: "registration_billing:r",
    },
    {
      key: 7,
      to: "/template",
      icon: <NoteAdd />,
      title: {
        i18n: "document.template",
      },
      permission: "registration_template:r",
    },
  ],
  reports: [
    {
      key: "mulDartaRegister",
      to: "/registration/reports/mulDartaRegister",
      // icon: <NoteAdd />,
      title: {
        i18n: "registration.reports.mulDartaRegister",
      },
      // permission: 'registration_template:r',
    },
    {
      key: "mulDartaRegister",
      to: "/registration/reports/monthlyReport",
      // icon: <NoteAdd />,
      title: {
        i18n: "registration.reports.monthlyReport",
      },
      // permission: 'registration_template:r',
    },
  ],
};

export const pharmacy = {
  nogroup: [
    {
      key: 1,
      to: "/pharmacyBilling/create",
      icon: <billing.icon />,
      title: {
        i18n: "pharmacy.billing.create",
      },
      permission: "pharmacy_billing:w",
    },
    {
      key: 1,
      to: "/pharmacyBilling",
      icon: <billing.icon />,
      title: {
        i18n: "pharmacy.billing.title",
      },
      permission: "pharmacy_billing:r",
    },
    {
      key: 2,
      icon: <billing.icon />,
      popover: <PharmacyCashBalance />,
      title: {
        i18n: "pharmacy.cashBalance.title",
      },
      permission: "pharmacy_billing:r",
    },
  ],
  store: [
    {
      key: 1,
      icon: <openingStock.icon />,
      title: {
        i18n: "pharmacy.store.entry",
        text: "Store Entry",
      },
      permission: "pharmacy_store:w",
      submenus: [
        {
          key: 1,
          to: "/openingStock/create",
          icon: <openingStock.icon />,
          title: {
            i18n: "pharmacy.store.openingstock",
          },
        },
        {
          key: 2,
          to: "/purchase/create",
          icon: <purchase.icon />,
          title: {
            i18n: "pharmacy.store.purchase",
          },
        },
        {
          key: 3,
          to: "/debitNote/create",
          icon: <debitNote.icon />,
          title: {
            i18n: "pharmacy.store.debitnote",
          },
        },
        // {
        // 	key: 4,
        // 	to: '/stockIn/create',
        // 	icon: <stockin.icon />,
        // 	title: {
        // 		i18n: 'pharmacy.store.stockin',
        // 	},
        // },
        // {
        // 	key: 5,
        // 	to: '/stockOut/create',
        // 	icon: <stockout.icon />,
        // 	title: {
        // 		i18n: 'pharmacy.store.stockout',
        // 	},
        // },
        {
          key: 6,
          to: "/expiryMgmt",
          icon: <expiryMgmt.icon />,
          title: {
            i18n: "pharmacy.store.expirymgmt",
          },
        },
        {
          key: 7,
          to: "/batchEdit",
          icon: <batchedit.icon />,
          title: {
            i18n: "pharmacy.store.batchedit",
          },
        },
      ],
    },
    {
      key: 2,
      icon: <ListAlt />,
      title: {
        i18n: "pharmacy.store.list",
        text: "Store List",
      },
      permission: "pharmacy_store:r",
      submenus: [
        {
          key: 1,
          to: "/openingStock",
          icon: <openingStock.icon />,
          title: {
            i18n: "pharmacy.store.openingstock",
          },
        },
        {
          key: 2,
          to: "/purchase",
          icon: <purchase.icon />,
          title: {
            i18n: "pharmacy.store.purchase",
          },
        },
        {
          key: 3,
          to: "/debitNote",
          icon: <debitNote.icon />,
          title: {
            i18n: "pharmacy.store.debitnote",
          },
        },
        // {
        // 	key: 4,
        // 	to: '/stockIn',
        // 	icon: <stockin.icon />,
        // 	title: {
        // 		i18n: 'pharmacy.store.stockin',
        // 	},
        // },
        // {
        // 	key: 5,
        // 	to: '/stockOut',
        // 	icon: <stockout.icon />,
        // 	title: {
        // 		i18n: 'pharmacy.store.stockout',
        // 	},
        // },
      ],
    },
  ],
  account: [
    {
      key: 1,
      title: {
        i18n: "pharmacy.account.cashDeposit",
        text: "Cash Deposit",
      },
      submenus: [
        {
          key: 1,
          to: "/cashDeposit/create",
          icon: null,
          title: {
            i18n: "pharmacy.account.cashDeposit",
          },
        },
        {
          key: 2,
          to: "/cashDeposit",
          icon: null,
          title: {
            i18n: "pharmacy.account.cashDepositList",
          },
        },
      ],
    },
    {
      key: 2,
      title: {
        i18n: "pharmacy.account.openingBalance",
      },
      icon: null,
      to: "/pharmacyOpeningBalance",
    },
    {
      key: 3,
      title: {
        i18n: "pharmacy.account.receive",
      },
      icon: null,
      to: "/pharmacyReceive",
    },
    {
      key: 4,
      title: {
        i18n: "pharmacy.account.payment",
      },
      icon: null,
      to: "/pharmacyPayment",
    },
  ],
  reports: [
    {
      key: "salesreports",
      title: {
        i18n: "pharmacy.report.sales",
        text: "Sales Reports",
      },
      submenus: [
        {
          key: "billSearch",
          to: "/reports/billSearch",
          icon: null,
          title: {
            i18n: "pharmacy.report.billSearch",
          },
        },
        {
          key: "salesSummary",
          to: "/reports/salesSummary",
          icon: null,
          title: {
            i18n: "pharmacy.report.salesSummary",
          },
        },
        {
          key: "salesRegister",
          to: "/reports/salesRegister",
          icon: null,
          title: {
            i18n: "pharmacy.report.salesRegister",
          },
        },
        {
          key: "productSalesRegister",
          to: "/reports/productSalesRegister",
          icon: null,
          title: {
            i18n: "pharmacy.report.productSalesRegister",
          },
        },
        {
          key: "productSalesReturnRegister",
          to: "/reports/productSalesReturnRegister",
          icon: null,
          title: {
            i18n: "pharmacy.report.productSalesReturnRegister",
          },
        },
        {
          key: "salesReturnRegister",
          to: "/reports/salesReturnRegister",
          icon: null,
          title: {
            i18n: "pharmacy.report.salesReturnRegister",
          },
        },
        {
          key: "narcoticsSalesReport",
          to: "/reports/narcoticsSalesReport",
          icon: null,
          title: {
            i18n: "pharmacy.report.narcoticsSalesReport",
          },
        },
      ],
    },
    {
      key: "stockReports",
      title: {
        i18n: "pharmacy.report.stock",
        text: "Stock Reports",
      },
      submenus: [
        {
          key: "currentStock",
          to: "/reports/currentStock",
          icon: null,
          title: {
            i18n: "pharmacy.report.currentStock",
          },
        },
        {
          key: "stockLedger",
          to: "/reports/stockLedger",
          icon: null,
          title: {
            i18n: "pharmacy.report.stockLedger",
          },
        },
      ],
    },
    {
      key: "accountReports",
      title: {
        i18n: "pharmacy.report.account",
        text: "Account Reports",
      },
      submenus: [
        {
          key: "userwiseSummary",
          to: "/reports/userwiseSummary",
          icon: null,
          title: {
            i18n: "pharmacy.report.userwiseSummary",
          },
        },
        {
          key: "patientCustomerLedger",
          to: "/reports/patientCustomerLedger",
          icon: null,
          title: {
            i18n: "pharmacy.report.patientCustomerLedger",
          },
        },
        {
          key: "accountLedger",
          to: "/reports/accountLedger",
          icon: null,
          title: {
            i18n: "pharmacy.report.accountLedger",
          },
        },
        {
          key: "productLedger",
          to: "/reports/productLedger",
          icon: null,
          title: {
            i18n: "pharmacy.report.productLedger",
          },
        },
        {
          key: "vendorLedger",
          to: "/reports/vendorLedger",
          icon: null,
          title: {
            i18n: "pharmacy.report.vendorLedger",
          },
        },
      ],
    },
    {
      key: "purchaseHistory",
      to: "/reports/purchaseHistory",
      icon: null,
      title: {
        i18n: "pharmacy.report.purchaseHistory",
      },
    },
  ],
  misc: [
    {
      key: 1,
      icon: <Reorder />,
      title: {
        text: "Master Data",
      },
      submenus: [
        {
          key: 1,
          to: "/brandCategory",
          icon: <brandCategory.icon />,
          title: {
            i18n: "pharmacy.brandCategory.title",
          },
        },
        {
          key: 2,
          to: "/brand",
          icon: <brand.icon />,
          title: {
            i18n: "pharmacy.brand.title",
          },
        },
        {
          key: 4,
          to: "/genericNames",
          icon: <genericNames.icon />,
          title: {
            i18n: "pharmacy.genericNames.title",
          },
        },
        {
          key: 5,
          to: "/manufacturer",
          icon: <manufacturer.icon />,
          title: {
            i18n: "pharmacy.manufacturer.title",
          },
        },
        {
          key: 6,
          to: "/vendors",
          icon: <vendors.icon />,
          title: {
            i18n: "pharmacy.vendors.title",
          },
        },
        {
          key: 7,
          to: "/customers",
          icon: <customers.icon />,
          title: {
            i18n: "pharmacy.customers.title",
          },
        },
      ],
    },
    {
      key: 2,
      title: {
        i18n: "pharmacy.salesReturn",
      },
      icon: <salesReturn.icon />,
      to: "/pharmacySalesReturn/create",
    },
    {
      key: "salesReturnList",
      title: {
        i18n: "pharmacy.salesReturnList",
      },
      icon: <salesReturn.icon />,
      to: "/pharmacySalesReturn",
    },
  ],
  administration: [
    {
      key: 1,
      title: {
        i18n: "pharmacy.administration.template",
      },
      icon: <pharmacyTemplate.icon />,
      to: "/template/pharmacy",
    },
  ],
};

export const pathology = {
  sample: [
    {
      key: "sampleCollection",
      title: {
        i18n: "pathology.sample.sampleCollection",
      },
      icon: null,
      to: "pathologySampleCollection",
    },
  ],
  valueEntry: [
    {
      key: "valueEntry",
      title: {
        i18n: "pathology.valueEntry.valueEntry",
      },
      icon: null,
      to: "valueEntry",
    },
    {
      key: "valueEntrySheet",
      title: {
        i18n: "pathology.valueEntry.valueEntrySheet",
      },
      icon: null,
      to: "valueEntrySheet",
    },
  ],
  settings: [
    {
      key: "master",
      title: {
        i18n: "pathology.settings.master",
        text: "Master Data",
      },
      icon: null,
      submenus: [
        {
          key: "referral",
          title: {
            i18n: "pathology.settings.referral",
          },
          to: "/pathologyReferal",
          icon: null,
        },
        {
          key: "labDept",
          title: {
            i18n: "pathology.settings.labDept",
          },
          to: "/pathologyDepartment",
          icon: null,
        },
        {
          key: "labServices",
          title: {
            i18n: "pathology.settings.labServices",
          },
          to: "/pathologyService",
          icon: null,
        },
        {
          key: "pathologyLabItems",
          title: {
            i18n: "pathology.settings.billingLabItems",
          },
          to: "/pathologyLabItems",
          icon: null,
        },
      ],
    },
    {
      key: "sampleGrp",
      title: {
        i18n: "pathology.settings.sampleGrp",
      },
      to: "/pathologySampleGroup",
      icon: null,
    },
  ],

  administrative: [
    {
      key: "template",
      title: {
        i18n: "pathology.administrative.template",
      },
      to: "pathologyTemplate",
      icon: null,
    },
  ],
};
