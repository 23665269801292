import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import PaymentForm from './PaymentForm';
import SectionTitle from '../../../../elements/SectionTitle';
import { Box } from '@mui/material';

export default function PaymentCreate() {
	return (
		<Create redirect="/pharmacyPayment">
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label={'pharmacy.account.payment'} />
			</Box>
			<SimpleForm>
				<PaymentForm />
			</SimpleForm>
		</Create>
	);
}
