import { Box } from '@mui/material';
import React from 'react';
import {
	AutocompleteInput,
	Datagrid,
	DateField,
	DateInput,
	List,
	SearchInput,
	TextField,
} from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';

export default function NarcoticsSalesReport() {
	return (
		<List
			filters={[
				<DateInput source="from" alwaysOn />,
				<DateInput source="to" alwaysOn />,
				<AutocompleteInput source="drugName" choices={[]} alwaysOn />,
				<AutocompleteInput source="doctor" choices={[]} alwaysOn />,
				<SearchInput source="q" alwaysOn />,
			]}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.narcoticsSalesReport" />
			</Box>
			<Datagrid>
				<DateField source="pDate" />
				<TextField source="batch" />
				<TextField source="medicine" />
				<DateField source="mfdData" />
				<DateField source="expData" />
				<TextField source="pQty" />
				<TextField source="supplier" />
				<TextField source="sQty" />
				<TextField source="qtyInStock" />
				<TextField source="sDate" />
				<TextField source="doctor" />
				<TextField source="dispensedBy" />
				<TextField source="patientsName" />
				<TextField source="remarks" />
			</Datagrid>
		</List>
	);
}
