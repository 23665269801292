import { Datagrid, FunctionField, List } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import SectionTitle from '../../../elements/SectionTitle';

const TemplateList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.administration.template" />
			<List
				// resource="/template/pharmacy"
				filters={undefined}
				sort={{}}
				perPage={25}
			>
				{isXsmall ? null : (
					<Datagrid
						optimized
						// rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<FunctionField
							label="Bill"
							render={(template) => (
								<a href={template?.sales} download target="_blank">
									Download
								</a>
							)}
						/>
						<FunctionField
							label="Ticket"
							render={(template) => (
								<a href={template?.salesReturn} download target="_blank">
									Download
								</a>
							)}
						/>
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default TemplateList;
