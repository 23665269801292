import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import TemplateForm from './TemplateForm';

const accept = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const validateForm = (values) => {
	console.log(values);
	const errors = {};
	if (values.sales) {
		if (values.sales.rawFile && !accept.includes(values.sales.rawFile.type))
			errors.sales = 'File must be docs or docx';
	}
	if (values.salesReturn) {
		if (
			values.salesReturn.rawFile &&
			!accept.includes(values.salesReturn.rawFile.type)
		)
			errors.salesReturn = 'File must be docs or docx';
	}
	return errors;
};

const TemplateCreate = () => {
	async function onChange(file) {
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('fileName', file.name);
			let response = await fetch(process.env.REACT_APP_BACKEND_URL + '/file', {
				method: 'POST',
				body: formData,
			});
			let res = await response.json();
			return res?.data;
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<Create
			// resource="template/pharmacy"
			redirect="/template/pharmacy"
			transform={async (data) => {
				const tempData = {};
				if (data?.sales?.rawFile)
					tempData.sales = await onChange(data.sales.rawFile);
				if (data?.salesReturn?.rawFile)
					tempData.salesReturn = await onChange(data.salesReturn.rawFile);
				return tempData;
			}}
		>
			<SimpleForm
				sx={{ maxWidth: 500 }}
				mode="onBlur"
				reValidateMode="onBlur"
				validate={validateForm}
			>
				<SectionTitle label="pharmacy.administration.template" />
				<TemplateForm accept={accept} />
			</SimpleForm>
		</Create>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default TemplateCreate;
