import { Box } from '@mui/material';
import React from 'react';
import { Datagrid, DateInput, List, SearchInput, TextField } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';

export default function UserwiseSummary() {
	return (
		<List
			filters={[
				<DateInput source="from" choices={[]} alwaysOn />,
				<DateInput source="to" choices={[]} alwaysOn />,
				<SearchInput source="q" alwaysOn />,
			]}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.userwiseSummary" />
			</Box>
			<Datagrid>
				<TextField source="user" />
				<TextField source="opening" />
				<TextField source="billing" />
				<TextField source="receive" />
				<TextField source="payment" />
				<TextField source="deposit" />
				<TextField source="billCancel" />
				<TextField source="total" />
			</Datagrid>
		</List>
	);
}
