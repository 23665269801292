import { Create, SimpleForm, useTranslate, useGetList } from 'react-admin';
import { Typography } from '@mui/material';
import PatientAside from './PatientAside';
import { useSidebarContext } from '../../_contexts/SidebarContext';
import { useEffect, useState } from 'react';

import Save from '../../elements/SaveButton';
import PatientForm from './PatientForm';
import handlePrint from '../../_helper/print';

export const validateForm = (values) => {
	const errors = {};
	return errors;
};

const VisitorCreate = () => {
	const [ticketType, setTicketType] = useState(undefined);
	const [search, setSearch] = useState('');
	const tickets = useGetList('ticket', {
		pagination: { page: 1, perPage: 10 },
		sort: { field: 'createdAt', order: 'DESC' },
		debounce: 500,
		filter: { q: search },
	});

	const { dispatch } = useSidebarContext();
	useEffect(() => {
		dispatch && dispatch({ type: 'registration' });
	}, [dispatch]);
	return (
		<Create
			aside={
				<PatientAside {...tickets} setSearch={setSearch} search={search} />
			}
			redirect={false}
			transform={(data) => {
				const tempData = {
					...data,
					amount: ticketType?.rate || 0,
					discount: data.discount || 0,
					tenderAmount: data.tenderAmount || 0,
					changeAmount:
						(+data.tenderAmount || 0) -
							(+(data.amount || +ticketType?.rate) || 0) +
							(+data.discount || 0) || 0,
				};

				return tempData;
			}}
			// mutationOptions={{
			// 	onSuccess: (data) => {
			// 		console.log('new patient', data);
			// 		handlePrint(data?.print);
			// 	},
			// }}
		>
			<SimpleForm
				// sx={{ maxWidth: 500 }}
				// Here for the GQL provider
				defaultValues={{}}
				validate={validateForm}
				toolbar={
					<Save
						onSuccess={(data) => {
							console.log('new patient', data);
							data?.print && handlePrint(data?.print);
							tickets.refetch();
						}}
					/>
				}
				sanitizeEmptyValues
			>
				<SectionTitle label="patient.patient" />
				<PatientForm ticketType={ticketType} setTicketType={setTicketType} />
			</SimpleForm>
		</Create>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default VisitorCreate;
