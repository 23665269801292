
import CashDepositCreate from './CashDepositCreate';
import CashDepositList from './CashDepositList';
import CashDepositEdit from './CashDepositEdit';

const resource = {
	list: CashDepositList,
	create: CashDepositCreate,
	edit: CashDepositEdit,
	icon: null,
};

export default resource;

  