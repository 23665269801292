import { Girl } from '@mui/icons-material';

import NurseList from './NurseList';
import NurseCreate from './NurseCreate';
import NurseEdit from './NurseEdit';

const resource = {
	list: NurseList,
	create: NurseCreate,
	edit: NurseEdit,
	icon: Girl,
};

export default resource;
