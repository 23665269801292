import MedicalServicesRoundedIcon from "@mui/icons-material/MedicalServicesRounded";

import ServiceList from "./ServiceList";
import ServiceCreate from "./ServiceCreate";
import ServiceEdit from "./ServiceEdit";
import ServiceCreateExcel from "./ServiceCreateExcel";

const resource = {
  list: ServiceList,
  create: ServiceCreate,
  edit: ServiceEdit,
  icon: MedicalServicesRoundedIcon,
  excel: ServiceCreateExcel,
};

export default resource;
