import { Box } from '@mui/material';
import React from 'react';
import {
	AutocompleteInput,
	Datagrid,
	DateField,
	DateInput,
	List,
	SearchInput,
	TextField,
} from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';

export default function VendorLedger() {
	return (
		<List
			filters={[
				<AutocompleteInput source="vendor" choices={[]} alwaysOn />,
				<DateInput source="from" alwaysOn />,
				<DateInput source="to" alwaysOn />,
				<AutocompleteInput source="invoiceType" alwaysOn />,
				<SearchInput source="q" alwaysOn />,
			]}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.vendorLedger" />
			</Box>
			<Datagrid>
				<TextField source="type" />
				<TextField source="documentNo" />
				<DateField source="date" />
				<TextField source="remarks" />
				<TextField source="invoiceType" />
				<TextField source="netAmount" />
			</Datagrid>
		</List>
	);
}
