import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import StockInForm from './StockInForm';
import { useState } from 'react';

export const validateForm = (values) => {
	const errors = {};
	return errors;
};

const StockInCreate = () => {
	const [items, setItems] = useState([]);
	return (
		<Create
			resource="pharmacyStockIn"
			redirect="/stockIn"
			transform={(data) => {
				const body = { ...data };
				delete body.brand;
				delete body.quantity;
				delete body.cause;
				body.items = items.map((item) => ({
					...item,
					brand: item.brand.id,
					itemId: item.batchNumber.id,
				}));
				return body;
			}}
		>
			<SimpleForm validate={validateForm}>
				<SectionTitle label="pharmacy.store.stockin" />
				<StockInForm state={[items, setItems]} />
			</SimpleForm>
		</Create>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default StockInCreate;
