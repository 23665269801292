import React, { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const SearchInput = ({ onChange, value: recievedValue }) => {
  const [showClearIcon, setShowClearIcon] = useState(
    recievedValue ? "flex" : "none"
  );
  const [value, setValue] = useState(recievedValue);

  const handleChange = (event) => {
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
    setValue(event.target.value);
  };

  const handleClick = () => {
    // TODO: Clear the search input
    console.log("clicked the clear icon...");
    onChange && onChange(value);
  };

  const handleClear = () => {
    setShowClearIcon("none");
    setValue("");
    onChange && onChange("");
  };

  return (
    <div id="app">
      <TextField
        size="small"
        variant="outlined"
        onChange={handleChange}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ display: showClearIcon }}
              onClick={handleClear}
            >
              <ClearRoundedIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={handleClick}>
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchInput;
