
  import { Box } from '@mui/material';
import { TextInput } from 'react-admin';

export default function ExpiryMgmtForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				width: '100%',
				columnGap: '1rem',
			}}
		>
			<TextInput source="name" isRequired fullWidth />
		</Box>
	);
}

  