import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
	Datagrid,
	List,
	TextField,
	ListContextProvider,
	useGetList,
	SimpleForm,
	useList,
	Button,
	TextInput,
	Create,
	EditButton,
	NumberInput,
	FunctionField,
	ReferenceInput,
	AutocompleteInput,
	Edit,
	useRecordContext,
} from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import { Add, Close } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

export default function LabServiceList() {
	return (
		<List sort={{ field: 'position', order: 'DESC' }}>
			<Box p={2} pb={0}>
				<SectionTitle label="pathology.settings.labServices" />
			</Box>
			<Datagrid
				expand={<ExpandedRow />}
				expandSingle={true}
				rowClick={'expand'}
			>
				<TextField source="name" />
				<TextField source="department.name" />
				<TextField source="sampleGroup.name" />
				<TextField source="position" />
				<TextField source="footer" />
				<FunctionField
					label="Actions"
					render={() => <EditButton>Edit</EditButton>}
				/>
			</Datagrid>
		</List>
	);
}

function ExpandedRow() {
	const [add, setAdd] = useState(false);
	const [edit, setEdit] = useState(false);
	const record = useRecordContext();

	const list = useGetList(`pathologyServiceItems`, {
		filter: { service: record?.id },
	});
	const listContext = useList({ data: list?.data });

	return (
		!list.isLoading && (
			<>
				<Box sx={{ display: 'flex', justifyContent: 'end' }}>
					<Button
						onClick={() => setAdd((prev) => !prev)}
						color={add ? 'error' : 'primary'}
					>
						{add ? (
							<Close sx={{ marginRight: 1 }} />
						) : (
							<Add sx={{ marginRight: 1 }} />
						)}
						{add ? ' Close' : 'Add'}
					</Button>
				</Box>
				{add && !edit && <CreateService selectedRow={record} />}
				{edit && (
					<EditService selectedRow={record} id={edit} setEdit={setEdit} />
				)}
				<ListContextProvider value={listContext}>
					<Box sx={{ backgroundColor: grey[200] }}>
						<Datagrid rowClick={(id) => setEdit(id)}>
							<TextField source="name" />
							<TextField source="group" />
							<TextField source="position" />
							<TextField source="valueType" />
							<TextField source="unit.name" />
							<TextField source="method.name" />
							<TextField source="defaultValue" />
						</Datagrid>
					</Box>
				</ListContextProvider>
			</>
		)
	);
}

function EditService({ selectedRow, id, setEdit }) {
	return (
		<Edit
			resource="pathologyServiceItems"
			id={id}
			redirect={false}
			mutationOptions={{ onSuccess: () => setEdit(false) }}
		>
			<SimpleForm sanitizeEmptyValues>
				<Typography variant="h5" mb={1} fontWeight={600} color="primary.main">
					{selectedRow?.name}
				</Typography>
				<ServiceForm />
			</SimpleForm>
		</Edit>
	);
}

function CreateService({ selectedRow }) {
	return (
		<Create
			resource="pathologyServiceItems"
			transform={(data) => {
				let body = { ...data };
				body.service = selectedRow?.id;
				return body;
			}}
			redirect={false}
		>
			<SimpleForm sanitizeEmptyValues>
				<Typography variant="h5" mb={1} fontWeight={600} color="primary.main">
					{selectedRow?.name}
				</Typography>
				<ServiceForm />
			</SimpleForm>
		</Create>
	);
}

function ServiceForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(4, 1fr)',
				gap: 1,
				width: '100%',
				backgroundColor: grey[200],
				padding: 2,
				borderRadius: 2,
			}}
		>
			<TextInput source="name" />
			<TextInput source="code" />
			<TextInput source="group" />
			<NumberInput source="position" />
			<NumberInput source="prependValue" />
			<AutocompleteInput
				source="valueType"
				choices={[
					{ id: 'Text', name: 'Text' },
					{ id: 'Number', name: 'Number' },
					{ id: 'Comment', name: 'Comment' },
				]}
			/>
			<ReferenceInput source="unit" reference="unit">
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput source="method" reference="method">
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<TextInput source="autoComplete" />
			<TextInput source="normalRange" />
			<TextInput source="defaultValue" />
			<TextInput source="footer" />
		</Box>
	);
}
