import { Edit, SimpleForm, useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { validateForm } from './PurchaseCreate';
import PurchaseForm from './PurchaseForm';
import { useEffect, useState } from 'react';

const PurchaseEdit = () => {
	const translate = useTranslate();
	const [items, setItems] = useState([]);
	return (
		<Edit resource="store" redirect="/purchase">
			<SimpleForm validate={validateForm} toolbar={false}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.store.purchase')}
				</Typography>
				<PurchaseEditForm state={[items, setItems]} />
			</SimpleForm>
		</Edit>
	);
};

export default PurchaseEdit;

function PurchaseEditForm({ state }) {
	const record = useRecordContext();
	useEffect(() => {
		if (record?.items) state[1](record?.items);
	}, []);
	return <PurchaseForm state={state} editMode={record} />;
}
