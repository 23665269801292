import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import StockOutForm from './StockOutForm';
import { useState } from 'react';

export const validateForm = (values) => {
	const errors = {};
	return errors;
};

const StockOutCreate = () => {
	const [items, setItems] = useState([]);
	return (
		<Create resource="pharmacyStockOut" redirect="/stockOut">
			<SimpleForm validate={validateForm}>
				<SectionTitle label="pharmacy.store.stockout" />
				<StockOutForm state={[items, setItems]} />
			</SimpleForm>
		</Create>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default StockOutCreate;
