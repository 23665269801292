import { Box } from '@mui/material';
import { TextInput } from 'react-admin';

export default function ManufacturerForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				width: '100%',
				columnGap: '1rem',
			}}
		>
			<TextInput source="name" isRequired fullWidth />
			<TextInput source="country" fullWidth />
			<TextInput source="address" fullWidth />
			<TextInput source="website" fullWidth />
			<TextInput source="email" fullWidth />
			<TextInput source="phone" fullWidth />
		</Box>
	);
}
