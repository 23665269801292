import { CalendarMonth } from '@mui/icons-material';
import FiscalYearCreate from './FiscalYearCreate';
import FiscalYearEdit from './FiscalYearEdit';
import FiscalYearList from './FiscalYearList';

const resource = {
	list: FiscalYearList,
	create: FiscalYearCreate,
	edit: FiscalYearEdit,
	icon: CalendarMonth,
};

export default resource;
