import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { validateForm } from './BrandCreate';
import BrandForm from './BrandForm';

const BrandEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/brand">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.brand.title')}
				</Typography>
				<BrandForm />
			</SimpleForm>
		</Edit>
	);
};

export default BrandEdit;
