import PurchaseCreate from "./PurchaseCreate";
import PurchaseList from "./PurchaseList";
import PurchaseEdit from "./PurchaseEdit";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
const resource = {
  list: PurchaseList,
  create: PurchaseCreate,
  edit: PurchaseEdit,
  icon: ShoppingCart,
};

export default resource;
