import DocumentCreate from "./DocumentCreate";
import Documents from "./Documents";

const resource = {
  list: Documents,
  create: DocumentCreate,
  edit: DocumentCreate,
};

export default resource;
