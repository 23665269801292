import { Box } from '@mui/material';
import { AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';

export default function CashDepositForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				width: '100%',
				columnGap: '1rem',
			}}
		>
			<ReferenceInput source="user" reference="users">
				<AutocompleteInput optionText="fullname_en" />
			</ReferenceInput>
			<TextInput source="amount" fullWidth />
			<TextInput source="approvedBy" fullWidth />
			<TextInput
				source="remarks"
				sx={{ gridColumn: 'span 3 / span 3' }}
				multiline
				fullWidth
			/>
		</Box>
	);
}
