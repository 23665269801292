import { Datagrid, List, TextField, useTranslate } from 'react-admin';
import { useMediaQuery, Typography } from '@mui/material';

const StockInList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			<SectionTitle label="pharmacy.store.stockin" />
			<List
				resource="pharmacyStockIn"
				filters={undefined}
				sort={{}}
				perPage={25}
			>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<TextField source="date" />
						<TextField source="fiscal.title" />
						<TextField source="remarks" />
					</Datagrid>
				)}
			</List>
		</>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default StockInList;
