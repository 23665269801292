import { Box } from '@mui/material';
import React from 'react';
import { TextInput } from 'react-admin';

export default function SampleGrpForm() {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(4, 1fr)',
				gap: 2,
				width: '100%',
			}}
		>
			<TextInput source="name" />
		</Box>
	);
}
