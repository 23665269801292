import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';
import { Box } from '@mui/material';
import ReceiveForm from './ReceiveForm';

export default function ReceiveEdit() {
	return (
		<Edit>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label={'pharmacy.account.receive'} />
				<SimpleForm>
					<ReceiveForm />
				</SimpleForm>
			</Box>
		</Edit>
	);
}
