import { Box } from '@mui/material';
import React from 'react';
import {
	AutocompleteInput,
	Datagrid,
	DateField,
	DateInput,
	List,
	SearchInput,
	TextField,
	TextInput,
} from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';

export default function SalesReturnRegister() {
	return (
		<List
			filters={[
				<DateInput source="from" alwaysOn />,
				<DateInput source="to" alwaysOn />,
				<AutocompleteInput source="user" choices={[]} alwaysOn />,
				<SearchInput source="q" alwaysOn />,
				<AutocompleteInput source="paymentMode" choices={[]} />,
				<AutocompleteInput source="invoiceFor" choices={[]} />,
				<AutocompleteInput source="charity" choices={[]} />,
				<AutocompleteInput source="prescribedBy" choices={[]} />,
				<TextInput source="invoiceFrom" />,
				<TextInput source="invoiceTo" />,
			]}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.salesReturnRegister" />
			</Box>
			<Datagrid>
				<DateField source="date" />
				<TextField source="invoiceNo" />
				<TextField source="paymentMode" />
				<TextField source="charity" />
				<TextField source="customer" />
				<TextField source="itemsCount" />
				<TextField source="subTotal" />
				<TextField source="discount" />
				<TextField source="netAmount" />
				<TextField source="time" />
			</Datagrid>
		</List>
	);
}
