import { Box } from '@mui/material';
import React from 'react';
import {
	AutocompleteInput,
	Datagrid,
	DateField,
	DateInput,
	List,
	SearchInput,
	TextField,
} from 'react-admin';
import SectionTitle from '../../../elements/SectionTitle';

function PurchaseHistory() {
	return (
		<List
			filters={[
				<AutocompleteInput source="brand" choices={[]} alwaysOn />,
				<AutocompleteInput source="vendor" choices={[]} alwaysOn />,
				<DateInput source="from" alwaysOn />,
				<DateInput source="to" alwaysOn />,
				<SearchInput source="q" alwaysOn />,
			]}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.purchaseHistory" />
			</Box>
			<Datagrid>
				<TextField source="product" />
				<TextField source="batchNo" />
				<TextField source="vendor" />
				<DateField source="purcDate" />
				<DateField source="expiryDate" />
				<TextField source="totalQty" />
				<TextField source="stock" />
				<TextField source="unitRate" />
				<TextField source="MRP" />
			</Datagrid>
		</List>
	);
}

const purchaseHistory = {
	list: PurchaseHistory,
	icon: null,
};

export default purchaseHistory;
