import TemplateCreate from './TemplateCreate';
import TemplateEdit from './TemplateEdit';
import TemplateList from './TemplateList';
import { Article } from '@mui/icons-material';

const resource = {
	list: TemplateList,
	create: TemplateCreate,
	edit: TemplateEdit,
	icon: Article,
};

export default resource;
