import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import BrandCategoryForm from './BrandCategoryForm';

export const validateForm = (values) => {
	const errors = {};
	if (!values.title) {
		errors.title = 'ra.validation.required';
	}
	return errors;
};

const BrandCategoryCreate = () => (
	<Create redirect="/brandCategory">
		<SimpleForm sx={{ maxWidth: 500 }} validate={validateForm}>
			<SectionTitle label="pharmacy.brandCategory.title" />
			<BrandCategoryForm />
		</SimpleForm>
	</Create>
);

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default BrandCategoryCreate;
