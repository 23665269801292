export const PALIKADATA = {
  "Province 1": {
    Taplejung: [
      { id: 9, title: "सिदिङ्वा", title_en: "sidingba" },
      { id: 8, title: "सिरीजङ्घा", title_en: "sirijangha" },
      { id: 7, title: "पाथीभरा याङवरक", title_en: "pathivara yangwarak" },
      { id: 6, title: "फुङलिङ", title_en: "phungling" },
      { id: 5, title: "आठराई त्रिवेणी", title_en: "aathraitribeni" },
      { id: 4, title: "मैवाखोला", title_en: "maiwakhola" },
      { id: 3, title: "मेरिङदेन", title_en: "meringden" },
      { id: 2, title: "मिक्वाखोला", title_en: "mikwakhola" },
      { id: 1, title: "फक्ताङलुङ", title_en: "phaktanglung" },
    ],
    Sankhuwasabha: [
      { id: 19, title: "धर्मदेवी", title_en: "dharmadevi" },
      { id: 18, title: "मादी", title_en: "madi" },
      { id: 17, title: "चैनपुर", title_en: "chainpur" },
      { id: 16, title: "पाँचखपन", title_en: "panchkhapan" },
      { id: 15, title: "खाँदवारी", title_en: "khandbari" },
      { id: 14, title: "सभापोखरी", title_en: "savapokhari" },
      { id: 13, title: "चिचिला", title_en: "chichila" },
      { id: 12, title: "सिलीचोङ", title_en: "silichong" },
      { id: 11, title: "मकालु", title_en: "makalu" },
      { id: 10, title: "भोटखोला", title_en: "bhotkhola" },
    ],
    Solukhumbu: [
      { id: 27, title: "लिखु पिके", title_en: "likhupike" },
      { id: 26, title: "सोलुदुधकुण्ड", title_en: "solududhkunda" },
      { id: 25, title: "नेचासल्यान", title_en: "nechasalyan" },
      { id: 24, title: "थुलुङ दुधकोशी", title_en: "thulung dudhkoshi" },
      { id: 23, title: "माप्य दुधकोशी", title_en: "mapya dudhkoshi" },
      { id: 22, title: "सोताङ", title_en: "sotang" },
      { id: 21, title: "माहाकुलुङ", title_en: "mahakulung" },
      { id: 20, title: "खुम्वु पासाङल्हमु", title_en: "khumbupasanglhamu" },
    ],
    Okhaldhunga: [
      { id: 35, title: "मानेभञ्याङ", title_en: "manebhanjyang" },
      { id: 34, title: "सुनकोशी", title_en: "sunkoshi" },
      { id: 33, title: "चम्पादेवी", title_en: "champadevi" },
      { id: 32, title: "लिखु", title_en: "likhu" },
      { id: 31, title: "खिजिदेम्बा", title_en: "khijidemba" },
      { id: 30, title: "मोलुङ", title_en: "molung" },
      { id: 29, title: "सिद्दिचरण", title_en: "siddhicharan" },
      { id: 28, title: "चिशंखुगढी", title_en: "chishankhugadhi" },
    ],
    Khotang: [
      { id: 45, title: "वराहपोखरी", title_en: "barahapokhari" },
      { id: 44, title: "जन्तेढुंगा", title_en: "jantedhunga" },
      { id: 43, title: "खोटेहाङ", title_en: "khotehang" },
      { id: 42, title: "दिप्रुङ चुइचुम्मा", title_en: "diprung chuichumma" },
      { id: 41, title: "साकेला", title_en: "sakela" },
      {
        id: 40,
        title: "दिक्तेल रुपाकोट",
        title_en: "diktel rupakot majhuwagadhi",
      },
      { id: 39, title: "हलेसी तुवाचुङ", title_en: "halesituwachung" },
      { id: 38, title: "रावा बेसी", title_en: "rawa besi" },
      { id: 37, title: "ऐसेलुखर्क", title_en: "aiselukharka" },
      { id: 36, title: "केपिलासगढी", title_en: "kepilasgadhi" },
    ],
    Bhojpur: [
      { id: 54, title: "आमचोक", title_en: "aamchowk" },
      { id: 53, title: "हतुवागढी", title_en: "hatuwagadhi" },
      { id: 52, title: "रामप्रसाद राई", title_en: "ramprasadrai" },
      { id: 51, title: "पौवादुङमा", title_en: "pauwadungma" },
      { id: 50, title: "अरुण", title_en: "arun" },
      { id: 49, title: "भोजपुर", title_en: "bhojpur" },
      { id: 48, title: "टेम्केमैयुङ", title_en: "temkemaiyum" },
      { id: 47, title: "साल्पासिलिछो", title_en: "salpasilichho" },
      { id: 46, title: "षडानन्द", title_en: "shadananda" },
    ],
    Dhankuta: [
      { id: 61, title: "चौविसे", title_en: "choubise" },
      { id: 60, title: "साँगुरीगढी", title_en: "sangurigadhi" },
      { id: 59, title: "सहिदभूमि", title_en: "shahidbhumi" },
      { id: 58, title: "धनकुटा", title_en: "dhankuta" },
      { id: 57, title: "छथर जोरपाटी", title_en: "chhatharjorpati" },
      { id: 56, title: "पाख्रिबास", title_en: "pakhribas" },
      { id: 55, title: "महालक्ष्मी", title_en: "mahalaxmi" },
    ],
    Tehrathum: [
      { id: 67, title: "छथर", title_en: "chhathar" },
      { id: 66, title: "लालीगुराँस", title_en: "laligurans" },
      { id: 65, title: "म्याङलुङ", title_en: "myanglung" },
      { id: 64, title: "मेन्छयायेम", title_en: "menchhayayem" },
      { id: 63, title: "फेदाप", title_en: "phedap" },
      { id: 62, title: "आठराई", title_en: "aathrai" },
    ],
    Panchthar: [
      { id: 75, title: "मिक्लाजुङ", title_en: "miklajung" },
      { id: 74, title: "तुम्बेवा", title_en: "tumwewa" },
      { id: 73, title: "कुम्मायक", title_en: "kummayak" },
      { id: 72, title: "फाल्गुनन्द", title_en: "phalgunanda" },
      { id: 71, title: "फिदिम", title_en: "phidim" },
      { id: 70, title: "फालेलुंग", title_en: "phalelung" },
      { id: 69, title: "हिलिहाङ", title_en: "hilihang" },
      { id: 68, title: "याङवरक", title_en: "yangwarak" },
    ],
    Ilam: [
      { id: 85, title: "रोङ", title_en: "rong" },
      { id: 84, title: "सूर्योदय", title_en: "suryodaya" },
      { id: 83, title: "माई", title_en: "mai" },
      { id: 82, title: "चुलाचुली", title_en: "chulachuli" },
      { id: 81, title: "माङसेबुङ", title_en: "mansebung" },
      { id: 80, title: "फाकफोकथुम", title_en: "phakphokthum" },
      { id: 79, title: "देउमाई", title_en: "deumai" },
      { id: 78, title: "ईलाम", title_en: "ilam" },
      { id: 77, title: "सन्दकपुर", title_en: "sandakpur" },
      { id: 76, title: "माईजोगमाई", title_en: "maijogmai" },
    ],
    Jhapa: [
      { id: 100, title: "कचनकवल", title_en: "kachankawal" },
      { id: 99, title: "भद्रपुर", title_en: "bhadrapur" },
      { id: 98, title: "हल्दिवारी", title_en: "haldibari" },
      { id: 97, title: "विर्तामोड", title_en: "birtamod" },
      { id: 96, title: "बाह्रदशी", title_en: "bahradashi" },
      { id: 95, title: "झापा", title_en: "jhapa" },
      { id: 94, title: "गौरीगंज", title_en: "gaurigunj" },
      { id: 93, title: "गौरादह", title_en: "gauradaha" },
      { id: 92, title: "दमक", title_en: "damak" },
      { id: 91, title: "कमल", title_en: "kamal" },
      { id: 90, title: "शिवशताक्षी", title_en: "shivasatakshi" },
      { id: 89, title: "कन्काई", title_en: "kankai" },
      { id: 88, title: "अर्जुनधारा", title_en: "arjundhara" },
      { id: 87, title: "बुद्धशान्ति", title_en: "buddhashanti" },
      { id: 86, title: "मेचीनगर", title_en: "mechinagar" },
    ],
    Morang: [
      { id: 117, title: "जहदा", title_en: "jahada" },
      { id: 116, title: "धनपालथान", title_en: "dhanapalthan" },
      { id: 115, title: "कटहरी", title_en: "katahari" },
      { id: 114, title: "विराटनगर महा", title_en: "biratnagar" },
      { id: 113, title: "बुढीगंगा", title_en: "budhiganga" },
      { id: 112, title: "ग्रामथान", title_en: "gramthan" },
      { id: 111, title: "रंगेली", title_en: "rangeli" },
      { id: 110, title: "सुनवर्षि", title_en: "sunawarshi" },
      { id: 109, title: "रतुवामाई", title_en: "ratuwamai" },
      { id: 108, title: "उर्लावारी", title_en: "urlabari" },
      { id: 107, title: "पथरी शनिश्चरे", title_en: "patharisanishchare" },
      { id: 106, title: "कानेपोखरी", title_en: "kanepokhari" },
      { id: 105, title: "बेलवारी", title_en: "belbari" },
      { id: 104, title: "सुन्दरहरैचा", title_en: "sundarharaicha" },
      { id: 103, title: "केरावारी", title_en: "kerabari" },
      { id: 102, title: "लेटाङ", title_en: "letang" },
      { id: 101, title: "मिक्लाजुङ", title_en: "miklajung" },
    ],
    Sunsari: [
      { id: 129, title: "बर्जु", title_en: "barju" },
      { id: 128, title: "देवानगञ्ज", title_en: "dewanganj" },
      { id: 127, title: "हरिनगर", title_en: "harinagar" },
      { id: 126, title: "ईनरुवा", title_en: "inaruwa" },
      { id: 125, title: "गढी", title_en: "gadhi" },
      { id: 124, title: "दुहवी", title_en: "duhabi" },
      { id: 123, title: "ईटहरी उपमहा", title_en: "itahari" },
      { id: 122, title: "रामधुनी", title_en: "ramdhuni" },
      { id: 121, title: "भोक्राहा नरसिंह", title_en: "bhokraha narsingh" },
      { id: 120, title: "कोशी", title_en: "koshi" },
      { id: 119, title: "बराहक्षेत्र", title_en: "barahachhetra" },
      { id: 118, title: "धरान उपमहा", title_en: "dharan" },
    ],
    Udaypur: [
      { id: 137, title: "उदयपुरगढी", title_en: "udayapurgadhi" },
      { id: 136, title: "कटारी", title_en: "katari" },
      { id: 135, title: "ताप्ली", title_en: "tapli" },
      { id: 134, title: "लिम्चुङ्बुङ", title_en: "limchungbung" },
      { id: 133, title: "रौतामाई", title_en: "rautamai" },
      { id: 132, title: "त्रियुगा", title_en: "triyuga" },
      { id: 131, title: "चौदण्डीगढी", title_en: "chaudandigadhi" },
      { id: 130, title: "वेलका", title_en: "belaka" },
    ],
  },
  "Madhesh Province": {
    Saptari: [
      { id: 155, title: "छिन्नमस्ता", title_en: "chhinnamasta" },
      { id: 154, title: "तिलाठी कोईलाडी", title_en: "tilathikoiladi" },
      {
        id: 153,
        title: "हनुमाननगर कङ्‌कालिनी",
        title_en: "hanumannagarkankalini",
      },
      { id: 152, title: "तिरहुत", title_en: "tirahut" },
      { id: 151, title: "महादेवा", title_en: "mahadeva" },
      { id: 150, title: "राजविराज", title_en: "rajbiraj" },
      { id: 149, title: "बिष्णुपुर", title_en: "bishnupur" },
      { id: 148, title: "राजगढ", title_en: "rajgadh" },
      { id: 147, title: "डाक्नेश्वरी", title_en: "dakneshwori" },
      { id: 146, title: "बोदेबरसाईन", title_en: "bodebarsain" },
      { id: 145, title: "बलान-बिहुल", title_en: "balanbihul" },
      { id: 144, title: "सुरुङ्‍गा", title_en: "surunga" },
      { id: 143, title: "खडक", title_en: "khadak" },
      { id: 142, title: "शम्भुनाथ", title_en: "shambhunath" },
      { id: 141, title: "रुपनी", title_en: "rupani" },
      {
        id: 140,
        title: "अग्निसाइर कृष्णासवरन",
        title_en: "agnisairkrishnasawaran",
      },
      { id: 139, title: "कञ्चनरुप", title_en: "kanchanrup" },
      { id: 138, title: "सप्तकोशी", title_en: "saptakoshi" },
    ],
    Siraha: [
      { id: 172, title: "सिरहा", title_en: "siraha" },
      { id: 171, title: "औरही", title_en: "aurahi" },
      { id: 170, title: "बरियारपट्टी", title_en: "bariyapatti" },
      { id: 169, title: "नवराजपुर", title_en: "nawarajpur" },
      { id: 168, title: "भगवानपुर", title_en: "bhagwanpur" },
      { id: 167, title: "सखुवानान्कारकट्टी", title_en: "sakhuwanankarkatti" },
      { id: 166, title: "लक्ष्मीपुर पतारी", title_en: "laxmipurpatari" },
      { id: 165, title: "सुखीपुर", title_en: "sukhipur" },
      { id: 164, title: "अर्नमा", title_en: "anarma" },
      { id: 163, title: "विष्णुपुर", title_en: "bishnupur" },
      { id: 162, title: "नरहा", title_en: "naraha" },
      { id: 161, title: "कल्याणपुर", title_en: "kalyanpur" },
      { id: 160, title: "कर्जन्हा", title_en: "karjanha" },
      { id: 159, title: "मिर्चैयाँ", title_en: "mirchaiya" },
      { id: 158, title: "गोलबजार", title_en: "golbazar" },
      { id: 157, title: "धनगढीमाई", title_en: "dhangadhimai" },
      { id: 156, title: "लहान", title_en: "lahan" },
    ],
    Dhanusha: [
      {
        id: 190,
        title: "मुखियापट्टी मुसहरमिया",
        title_en: "mukhiyapattimusaharmiya",
      },
      { id: 189, title: "नगराइन", title_en: "nagrain" },
      { id: 188, title: "धनौजी", title_en: "dhanauji" },
      { id: 187, title: "जनकपुरधाम उपमहा", title_en: "janakpurdham" },
      { id: 186, title: "औरही", title_en: "aurahi" },
      { id: 185, title: "विदेह", title_en: "bideha" },
      { id: 184, title: "जनकनन्दिनी", title_en: "janaknandini" },
      { id: 183, title: "कमला", title_en: "kamala" },
      { id: 182, title: "शहीदनगर", title_en: "shahidnagar" },
      { id: 181, title: "सबैला", title_en: "sabaila" },
      { id: 180, title: "हंसपुर", title_en: "hansapur" },
      { id: 179, title: "मिथिला बिहारी", title_en: "mithilabihari" },
      { id: 178, title: "लक्ष्मीनिया", title_en: "laxminiya" },
      { id: 177, title: "क्षिरेश्वरनाथ", title_en: "kshireshwornath" },
      { id: 176, title: "बटेश्वर", title_en: "bateshwor" },
      { id: 175, title: "मिथिला", title_en: "mithila" },
      { id: 174, title: "धनुषाधाम", title_en: "dhanushadham" },
      { id: 173, title: "गणेशमान चारनाथ", title_en: "ganeshmancharnath" },
    ],
    Mahottari: [
      { id: 205, title: "जलेश्वर", title_en: "jaleshwor" },
      { id: 204, title: "मटिहानी", title_en: "matihani" },
      { id: 203, title: "पिपरा", title_en: "pipra" },
      { id: 202, title: "महोत्तरी", title_en: "mahottari" },
      { id: 201, title: "एकडारा", title_en: "ekdara" },
      { id: 200, title: "मनरा शिसवा", title_en: "manrashiswa" },
      { id: 199, title: "साम्सी", title_en: "samsi" },
      { id: 198, title: "रामगोपालपुर", title_en: "ramgopalpur" },
      { id: 197, title: "बलवा", title_en: "balwa" },
      { id: 196, title: "लोहरपट्टी", title_en: "loharpatti" },
      { id: 195, title: "भँगाहा", title_en: "bhagaha" },
      { id: 194, title: "औरही", title_en: "aurahi" },
      { id: 193, title: "सोनमा", title_en: "sonma" },
      { id: 192, title: "गौशाला", title_en: "gaushala" },
      { id: 191, title: "बर्दिबास", title_en: "bardibas" },
    ],
    Sarlahi: [
      { id: 225, title: "मलंगवा", title_en: "malangwa" },
      { id: 224, title: "कौडेना", title_en: "kaudena" },
      { id: 223, title: "विष्णु", title_en: "bishnu" },
      { id: 222, title: "गोडैटा", title_en: "godaita" },
      { id: 221, title: "बलरा", title_en: "balra" },
      { id: 220, title: "रामनगर", title_en: "ramnagar" },
      { id: 219, title: "धनकौल", title_en: "dhankaul" },
      { id: 218, title: "बसबरीया", title_en: "basbariya" },
      { id: 217, title: "चक्रघट्टा", title_en: "chakraghatta" },
      { id: 216, title: "कविलासी", title_en: "kawilasi" },
      { id: 215, title: "चन्द्रनगर", title_en: "chandranagar" },
      { id: 214, title: "ब्रह्मपुरी", title_en: "brahmapuri" },
      { id: 213, title: "पर्सा", title_en: "parsa" },
      { id: 212, title: "हरिपुर्वा", title_en: "haripurwa" },
      { id: 211, title: "ईश्वरपुर", title_en: "ishworpur" },
      { id: 210, title: "हरिपुर", title_en: "haripur" },
      { id: 209, title: "बरहथवा", title_en: "barhathwa" },
      { id: 208, title: "बागमती", title_en: "bagmati" },
      { id: 207, title: "हरिवन", title_en: "harion" },
      { id: 206, title: "लालबन्दी", title_en: "lalbandi" },
    ],
    Rautahat: [
      { id: 243, title: "ईशनाथ", title_en: "ishnath" },
      { id: 242, title: "गौर", title_en: "gaur" },
      { id: 241, title: "राजदेवी", title_en: "rajdevi" },
      { id: 240, title: "दुर्गा भगवती", title_en: "durgabhagawati" },
      { id: 239, title: "यमुनामाई", title_en: "yamunamai" },
      { id: 238, title: "राजपुर", title_en: "rajpur" },
      { id: 237, title: "परोहा", title_en: "paroha" },
      { id: 236, title: "बौधीमाई", title_en: "baudhimai" },
      { id: 235, title: "मौलापुर", title_en: "maulapur" },
      { id: 234, title: "देवाही गोनाही", title_en: "dewahigonahi" },
      { id: 233, title: "गरुडा", title_en: "garuda" },
      { id: 232, title: "माधव नारायण", title_en: "madhavnarayan" },
      { id: 231, title: "गढीमाई", title_en: "gadhimai" },
      { id: 230, title: "बृन्दावन", title_en: "brindawan" },
      { id: 229, title: "कटहरिया", title_en: "katahariya" },
      { id: 228, title: "फतुवाबिजयपुर", title_en: "phatuwabijaypur" },
      { id: 227, title: "गुजरा", title_en: "gujara" },
      { id: 226, title: "चन्द्रपुर", title_en: "chandrapur" },
    ],
    Bara: [
      { id: 259, title: "सुवर्ण", title_en: "suwarna" },
      { id: 258, title: "देवताल", title_en: "devtal" },
      { id: 257, title: "महागढीमाई", title_en: "mahagadhimai" },
      { id: 256, title: "पचरौता", title_en: "pachrauta" },
      { id: 255, title: "सिम्रौनगढ", title_en: "simraungadh" },
      { id: 254, title: "आदर्श कोटवाल", title_en: "aadarshakotwal" },
      { id: 253, title: "बारागढी", title_en: "baragadhi" },
      { id: 252, title: "करैयामाई", title_en: "karaiyamai" },
      { id: 251, title: "कलैया उपमहा", title_en: "kalaiya" },
      { id: 250, title: "फेटा", title_en: "pheta" },
      { id: 249, title: "विश्रामपुर", title_en: "bishrampur" },
      { id: 248, title: "प्रसौनी", title_en: "prasauni" },
      { id: 247, title: "परवानीपुर", title_en: "parwanipur" },
      { id: 246, title: "जीतपुर सिमरा उपमहा", title_en: "jeetpursimara" },
      { id: 245, title: "कोल्हवी", title_en: "kolhabi" },
      { id: 244, title: "निजगढ", title_en: "nijgadh" },
    ],
    Parsa: [
      { id: 273, title: "बिन्दबासिनी", title_en: "bindabasini" },
      { id: 272, title: "पकाहा मैनपुर", title_en: "pakahamainpur" },
      { id: 271, title: "छिपहरमाई", title_en: "chhipaharmai" },
      { id: 270, title: "धोबीनी", title_en: "dhobini" },
      { id: 269, title: "कालिकामाई", title_en: "kalikamai" },
      { id: 268, title: "पोखरिया", title_en: "pokhariya" },
      { id: 267, title: "बहुदरमाई", title_en: "bahudarmai" },
      { id: 266, title: "बिरगंज महा", title_en: "birgunj" },
      { id: 265, title: "पर्सागढी", title_en: "parsagadhi" },
      { id: 264, title: "सखुवा प्रसौनी", title_en: "sakhuwaprasauni" },
      { id: 263, title: "पटेर्वा सुगौली", title_en: "paterwasugauli" },
      { id: 262, title: "जगरनाथपुर", title_en: "jagarnathpur" },
      { id: 261, title: "जिरा भवानी", title_en: "jirabhawani" },
      { id: 260, title: "ठोरी", title_en: "thori" },
    ],
  },
  "Bagamati Province": {
    Dolakha: [
      { id: 282, title: "भिमेश्वर", title_en: "bhimeshwor" },
      { id: 281, title: "शैलुङ्ग", title_en: "shailung" },
      { id: 280, title: "मेलुङ्ग", title_en: "melung" },
      { id: 279, title: "तामाकोशी", title_en: "tamakoshi" },
      { id: 278, title: "जिरी", title_en: "jiri" },
      { id: 277, title: "वैतेश्वर", title_en: "baiteshwor" },
      { id: 276, title: "कालिन्चोक", title_en: "kalinchowk" },
      { id: 275, title: "विगु", title_en: "bigu" },
      { id: 274, title: "गौरीशङ्कर", title_en: "gaurishankar" },
    ],
    Sindhupalchowk: [
      { id: 294, title: "सुनकोशी", title_en: "sunkoshi" },
      { id: 293, title: "लिसङ्खु पाखर", title_en: "lisankhupakhar" },
      { id: 292, title: "त्रिपुरासुन्दरी", title_en: "tripurasundari" },
      { id: 291, title: "बाह्रविसे", title_en: "bahrabise" },
      { id: 290, title: "बलेफी", title_en: "balephi" },
      {
        id: 289,
        title: "चौतारा साँगाचोकगढी",
        title_en: "chautarasangachowkgadhi",
      },
      { id: 288, title: "ईन्द्रावती", title_en: "indrawati" },
      { id: 287, title: "मेलम्ची", title_en: "melamchi" },
      { id: 286, title: "हेलम्बु", title_en: "helambu" },
      { id: 285, title: "पाँचपोखरी थाङपाल", title_en: "panchpokharithangpal" },
      { id: 284, title: "जुगल", title_en: "jugal" },
      { id: 283, title: "भोटेकोशी", title_en: "bhotekoshi" },
    ],
    Rasuwa: [
      { id: 299, title: "नौकुण्ड", title_en: "naukunda" },
      { id: 298, title: "कालिका", title_en: "kalika" },
      { id: 297, title: "उत्तरगया", title_en: "uttargaya" },
      { id: 296, title: "आमाछोदिङमो", title_en: "aamachhodingmo" },
      { id: 295, title: "गोसाईकुण्ड", title_en: "gosaikunda" },
    ],
    Dhading: [
      { id: 312, title: "धुनीबेंशी", title_en: "dhunibeshi" },
      { id: 311, title: "थाक्रे", title_en: "thakre" },
      { id: 310, title: "गल्छी", title_en: "galchhi" },
      { id: 309, title: "गजुरी", title_en: "gajuri" },
      { id: 308, title: "बेनीघाट रोराङ्ग", title_en: "benighatrorang" },
      { id: 307, title: "सिद्धलेक", title_en: "siddhalek" },
      { id: 306, title: "ज्वालामूखी", title_en: "jwalamukhi" },
      { id: 305, title: "निलकण्ठ", title_en: "neelakantha" },
      { id: 304, title: "नेत्रावती डबजोङ", title_en: "netrawati dabjong" },
      { id: 303, title: "त्रिपुरासुन्दरी", title_en: "tripurasundari" },
      { id: 302, title: "गङ्गाजमुना", title_en: "gangajamuna" },
      { id: 301, title: "खनियाबास", title_en: "khaniyabas" },
      { id: 300, title: "रुवी भ्याली", title_en: "rubivalley" },
    ],
    Nuwakot: [
      { id: 324, title: "ककनी", title_en: "kakani" },
      { id: 323, title: "शिवपुरी", title_en: "shivapuri" },
      { id: 322, title: "पञ्चकन्या", title_en: "panchakanya" },
      { id: 321, title: "लिखु", title_en: "likhu" },
      { id: 320, title: "बेलकोटगढी", title_en: "belkotgadhi" },
      { id: 319, title: "तारकेश्वर", title_en: "tarakeshwor" },
      { id: 318, title: "म्यगङ", title_en: "myagang" },
      { id: 317, title: "किस्पाङ", title_en: "kispang" },
      { id: 316, title: "विदुर", title_en: "bidur" },
      { id: 315, title: "सुर्यगढी", title_en: "suryagadhi" },
      { id: 314, title: "तादी", title_en: "tadi" },
      { id: 313, title: "दुप्चेश्वर", title_en: "dupcheshwor" },
    ],
    Kathmandu: [
      { id: 335, title: "दक्षिणकाली", title_en: "dakshinkali" },
      { id: 334, title: "चन्द्रागिरी", title_en: "chandragiri" },
      { id: 333, title: "कीर्तिपुर", title_en: "kirtipur" },
      { id: 332, title: "काठमाण्डौं महा", title_en: "kathmandu" },
      { id: 331, title: "नागार्जुन", title_en: "nagarjun" },
      { id: 330, title: "तारकेश्वर", title_en: "tarakeshwor" },
      { id: 329, title: "टोखा", title_en: "tokha" },
      { id: 328, title: "बुढानिलकण्ठ", title_en: "budhanilkantha" },
      { id: 327, title: "गोकर्णेश्वर", title_en: "gokarneshwor" },
      { id: 326, title: "कागेश्वरी मनोहरा", title_en: "kageshworimanohara" },
      { id: 325, title: "शङ्खरापुर", title_en: "shankharapur" },
    ],
    Bhaktapur: [
      { id: 339, title: "सूर्यविनायक", title_en: "suryabinayak" },
      { id: 338, title: "मध्यपुर थिमी", title_en: "madhyapurthimi" },
      { id: 337, title: "भक्तपुर", title_en: "bhaktapur" },
      { id: 336, title: "चाँगुनारायण", title_en: "changunarayan" },
    ],
    Lalitpur: [
      { id: 345, title: "बागमती", title_en: "bagmati" },
      { id: 344, title: "महाङ्काल", title_en: "mahankal" },
      { id: 343, title: "कोन्ज्योसोम", title_en: "konjyosom" },
      { id: 342, title: "गोदावरी", title_en: "godawari" },
      { id: 341, title: "ललितपुर महा", title_en: "lalitpur" },
      { id: 340, title: "महालक्ष्मी", title_en: "mahalaxmi" },
    ],
    Kavrepalanchowk: [
      { id: 358, title: "खानीखोला", title_en: "khanikhola" },
      { id: 357, title: "महाभारत", title_en: "mahabharat" },
      { id: 356, title: "रोशी", title_en: "roshi" },
      { id: 355, title: "बेथानचोक", title_en: "bethanchowk" },
      { id: 354, title: "पनौती", title_en: "panauti" },
      { id: 353, title: "नमोबुद्ध", title_en: "namobuddha" },
      { id: 352, title: "तेमाल", title_en: "temal" },
      { id: 351, title: "पाँचखाल", title_en: "panchkhal" },
      { id: 350, title: "धुलिखेल", title_en: "dhulikhel" },
      { id: 349, title: "बनेपा", title_en: "banepa" },
      { id: 348, title: "मण्डनदेउपुर", title_en: "mandandeupur" },
      { id: 347, title: "भुम्लु", title_en: "bhumlu" },
      { id: 346, title: "चौंरीदेउराली", title_en: "chaurideurali" },
    ],
    Ramechhap: [
      { id: 366, title: "सुनापति", title_en: "sunapati" },
      { id: 365, title: "दोरम्बा", title_en: "doramba" },
      { id: 364, title: "खाँडादेवी", title_en: "khandadevi" },
      { id: 363, title: "मन्थली", title_en: "manthali" },
      { id: 362, title: "रामेछाप", title_en: "ramechhap" },
      { id: 361, title: "लिखु तामाकोशी", title_en: "likhu tamakoshi" },
      { id: 360, title: "गोकुलगङ्गा", title_en: "gokulganga" },
      { id: 359, title: "उमाकुण्ड", title_en: "umakunda" },
    ],
    Sindhuli: [
      { id: 375, title: "हरिहरपुरगढी", title_en: "hariharpurgadhi" },
      { id: 374, title: "मरिण", title_en: "marin" },
      { id: 373, title: "घ्याङलेख", title_en: "ghyanglekh" },
      { id: 372, title: "सुनकोशी", title_en: "sunkoshi" },
      { id: 371, title: "कमलामाई", title_en: "kamalamai" },
      { id: 370, title: "गोलन्जर", title_en: "golanjor" },
      { id: 369, title: "तीनपाटन", title_en: "tinpatan" },
      { id: 368, title: "फिक्कल", title_en: "phikkal" },
      { id: 367, title: "दुधौली", title_en: "dudhauli" },
    ],
    Makwanpur: [
      { id: 385, title: "बाग्मति", title_en: "bagmati" },
      { id: 384, title: "बकैया", title_en: "bakaiya" },
      { id: 383, title: "मकवानपुरगढी", title_en: "makawanpurgadhi" },
      { id: 382, title: "भिमफेदी", title_en: "bhimphedi" },
      { id: 381, title: "हेटौडा उपमहा", title_en: "hetauda" },
      { id: 380, title: "मनहरी", title_en: "manahari" },
      { id: 379, title: "राक्सिराङ्ग", title_en: "raksirang" },
      { id: 378, title: "कैलाश", title_en: "kailash" },
      { id: 377, title: "थाहा", title_en: "thaha" },
      { id: 376, title: "इन्द्रसरोबर", title_en: "indrasarowar" },
    ],
    Chitwan: [
      { id: 392, title: "माडी", title_en: "madi" },
      { id: 391, title: "खैरहनी", title_en: "khairhani" },
      { id: 390, title: "रत्ननगर", title_en: "ratnanagar" },
      { id: 389, title: "भरतपुर महा", title_en: "bharatpur" },
      { id: 388, title: "इच्छाकामना", title_en: "ichchhakamana" },
      { id: 387, title: "कालिका", title_en: "kalika" },
      { id: 386, title: "राप्ती", title_en: "rapti" },
    ],
  },
  "Gandaki Province": {
    Gorkha: [
      { id: 403, title: "गण्डकी", title_en: "gandaki" },
      { id: 402, title: "शहिद लखन", title_en: "shahidlakhan" },
      { id: 401, title: "गोरखा", title_en: "gorkha" },
      { id: 400, title: "पालुङटार", title_en: "palungtar" },
      { id: 399, title: "सिरानचोक", title_en: "siranchowk" },
      { id: 398, title: "भिमसेनथापा", title_en: "bhimsenthapa" },
      { id: 397, title: "आरूघाट", title_en: "aarughat" },
      { id: 396, title: "धार्चे", title_en: "dharche" },
      { id: 395, title: "बारपाक सुलिकोट", title_en: "barpak sulikot" },
      { id: 394, title: "अजिरकोट", title_en: "ajirkot" },
      { id: 393, title: "चुमनुव्री", title_en: "chumanuwri" },
    ],
    Manang: [
      { id: 407, title: "नासोँ", title_en: "nason" },
      { id: 406, title: "चामे", title_en: "chame" },
      { id: 405, title: "मनाङ ङिस्याङ", title_en: "manang ngisyang" },
      { id: 404, title: "नार्पा भूमि", title_en: "narpa bhumi" },
    ],
    Mustang: [
      { id: 412, title: "थासाङ", title_en: "thasang" },
      { id: 411, title: "लोमन्थाङ", title_en: "lomanthang" },
      {
        id: 410,
        title: "वारागुङ मुक्तिक्षेत्र",
        title_en: "varagung muktichhetra",
      },
      { id: 409, title: "घरपझोङ", title_en: "gharpajhong" },
      {
        id: 408,
        title: "लो-घेकर दामोदरकुण्ड",
        title_en: "lo ghekar damodarkunda",
      },
    ],
    Myagdi: [
      { id: 418, title: "बेनी", title_en: "beni" },
      { id: 417, title: "मंगला", title_en: "mangala" },
      { id: 416, title: "मालिका", title_en: "malika" },
      { id: 415, title: "धवलागिरी", title_en: "dhawalagiri" },
      { id: 414, title: "रघुगंगा", title_en: "raghuganga" },
      { id: 413, title: "अन्नपूर्ण", title_en: "annapurna" },
    ],
    Kaski: [
      { id: 423, title: "रूपा", title_en: "rupa" },
      { id: 422, title: "पोखरा महा", title_en: "pokhara" },
      { id: 421, title: "अन्नपूर्ण", title_en: "annapurna" },
      { id: 420, title: "माछापुच्छ्रे", title_en: "machhapuchhre" },
      { id: 419, title: "मादी", title_en: "madi" },
    ],
    Lamjung: [
      { id: 431, title: "दूधपोखरी", title_en: "dudhpokhari" },
      { id: 430, title: "रार्इनास", title_en: "rainas" },
      { id: 429, title: "सुन्दरबजार", title_en: "sundarbazar" },
      { id: 428, title: "बेसीशहर", title_en: "besishahar" },
      { id: 427, title: "मध्यनेपाल", title_en: "madhyanepal" },
      { id: 426, title: "क्व्होलासोथार", title_en: "kwholasothar" },
      { id: 425, title: "मर्स्याङदी", title_en: "marsyangdi" },
      { id: 424, title: "दोर्दी", title_en: "dordi" },
    ],
    Tanahun: [
      { id: 441, title: "आँबुखैरेनी", title_en: "aanbookhaireni" },
      { id: 440, title: "वन्दिपुर", title_en: "bandipur" },
      { id: 439, title: "देवघाट", title_en: "devghat" },
      { id: 438, title: "ऋषिङ्ग", title_en: "rishing" },
      { id: 437, title: "घिरिङ", title_en: "ghiring" },
      { id: 436, title: "भिमाद", title_en: "bhimad" },
      { id: 435, title: "शुक्लागण्डकी", title_en: "shuklagandaki" },
      { id: 434, title: "म्याग्दे", title_en: "myagde" },
      { id: 433, title: "व्यास", title_en: "vyas" },
      { id: 432, title: "भानु", title_en: "bhanu" },
    ],
    "Nawalparasi (East)": [
      { id: 449, title: "विनयी त्रिवेणी", title_en: "binayitribeni" },
      { id: 448, title: "मध्यविन्दु", title_en: "madhyabindu" },
      { id: 447, title: "कावासोती", title_en: "kawasoti" },
      { id: 446, title: "देवचुली", title_en: "devchuli" },
      { id: 445, title: "हुप्सेकोट", title_en: "hupsekot" },
      { id: 444, title: "बौदीकाली", title_en: "baudikali" },
      { id: 443, title: "बुलिङटार", title_en: "bulingtar" },
      { id: 442, title: "गैडाकोट", title_en: "gaindakot" },
    ],
    Syangja: [
      { id: 460, title: "कालीगण्डकी", title_en: "kaligandaki" },
      { id: 459, title: "गल्याङ", title_en: "galyang" },
      { id: 458, title: "वालिङ", title_en: "waling" },
      { id: 457, title: "चापाकोट", title_en: "chapakot" },
      { id: 456, title: "हरिनास", title_en: "harinas" },
      { id: 455, title: "बिरुवा", title_en: "biruwa" },
      { id: 454, title: "भीरकोट", title_en: "bheerkot" },
      { id: 453, title: "अर्जुनचौपारी", title_en: "arjunchaupari" },
      { id: 452, title: "आँधिखोला", title_en: "aandhikhola" },
      { id: 451, title: "फेदीखोला", title_en: "phedikhola" },
      { id: 450, title: "पुतलीबजार", title_en: "putalibazar" },
    ],
    Parbat: [
      { id: 467, title: "पैयूं", title_en: "paiyun" },
      { id: 466, title: "विहादी", title_en: "bihadi" },
      { id: 465, title: "महाशिला", title_en: "mahashila" },
      { id: 464, title: "फलेवास", title_en: "phalewas" },
      { id: 463, title: "कुश्मा", title_en: "kushma" },
      { id: 462, title: "जलजला", title_en: "jaljala" },
      { id: 461, title: "मोदी", title_en: "modi" },
    ],
    Baglung: [
      { id: 477, title: "जैमूनी", title_en: "jaimini" },
      { id: 476, title: "वरेङ", title_en: "bareng" },
      { id: 475, title: "गल्कोट", title_en: "galkot" },
      { id: 474, title: "वडिगाड", title_en: "badigad" },
      { id: 473, title: "निसीखोला", title_en: "nisikhola" },
      { id: 472, title: "ढोरपाटन", title_en: "dhorpatan" },
      { id: 471, title: "तमानखोला", title_en: "tamankhola" },
      { id: 470, title: "ताराखोला", title_en: "tarakhola" },
      { id: 469, title: "काठेखोला", title_en: "kathekhola" },
      { id: 468, title: "बागलुङ", title_en: "baglung" },
    ],
  },
  "Lumbin Province": {
    "Rukum (East)": [
      { id: 480, title: "भूमे", title_en: "bhume" },
      { id: 479, title: "सिस्ने", title_en: "sisne" },
      { id: 478, title: "पुथा उत्तरगंगा", title_en: "puthauttarganga" },
    ],
    Rolpa: [
      { id: 490, title: "लुङग्री", title_en: "lungri" },
      { id: 489, title: "सुनिल स्मृति", title_en: "sunil smriti" },
      { id: 488, title: "रुन्टीगढी", title_en: "runtigadhi" },
      { id: 487, title: "रोल्पा", title_en: "rolpa" },
      { id: 486, title: "त्रिवेणी", title_en: "triveni" },
      { id: 485, title: "माडी", title_en: "madi" },
      { id: 484, title: "गंगादेव", title_en: "gangadev" },
      { id: 483, title: "परिवर्तन", title_en: "pariwartan" },
      { id: 482, title: "थवाङ", title_en: "thabang" },
      { id: 481, title: "सुनछहरी", title_en: "sunchhahari" },
    ],
    Pyuthan: [
      { id: 499, title: "सरुमारानी", title_en: "sarumarani" },
      { id: 498, title: "ऐरावती", title_en: "airawati" },
      { id: 497, title: "मल्लरानी", title_en: "mallarani" },
      { id: 496, title: "माण्डवी", title_en: "mandavi" },
      { id: 495, title: "स्वर्गद्वारी", title_en: "swargadwari" },
      { id: 494, title: "प्यूठान", title_en: "pyuthan" },
      { id: 493, title: "झिमरुक", title_en: "jhimruk" },
      { id: 492, title: "नौवहिनी", title_en: "naubahini" },
      { id: 491, title: "गौमुखी", title_en: "gaumukhi" },
    ],
    Gulmi: [
      { id: 511, title: "रुरुक्षेत्र", title_en: "rurukshetra" },
      { id: 510, title: "छत्रकोट", title_en: "chhatrakot" },
      { id: 509, title: "गुल्मी दरबार", title_en: "gulmidarbar" },
      { id: 508, title: "रेसुङ्गा", title_en: "resunga" },
      { id: 507, title: "धुर्कोट", title_en: "dhurkot" },
      { id: 506, title: "मदाने", title_en: "madane" },
      { id: 505, title: "मालिका", title_en: "malika" },
      { id: 504, title: "ईस्मा", title_en: "ishma" },
      { id: 503, title: "मुसिकोट", title_en: "musikot" },
      { id: 502, title: "चन्द्रकोट", title_en: "chandrakot" },
      { id: 501, title: "सत्यवती", title_en: "satyawati" },
      { id: 500, title: "कालीगण्डकी", title_en: "kaligandaki" },
    ],
    Arghakhanchi: [
      { id: 517, title: "शितगंगा", title_en: "shitaganga" },
      { id: 516, title: "पाणिनी", title_en: "panini" },
      { id: 515, title: "सन्धिखर्क", title_en: "sandhikharka" },
      { id: 514, title: "भूमिकास्थान", title_en: "bhumikasthan" },
      { id: 513, title: "मालारानी", title_en: "malarani" },
      { id: 512, title: "छत्रदेव", title_en: "chhatradev" },
    ],
    Palpa: [
      { id: 527, title: "निस्दी", title_en: "nisdi" },
      { id: 526, title: "माथागढी", title_en: "mathagadhi" },
      { id: 525, title: "तिनाउ", title_en: "tinau" },
      { id: 524, title: "रैनादेवी छहरा", title_en: "rainadevichhahara" },
      { id: 523, title: "रिब्दिकोट", title_en: "ribdikot" },
      { id: 522, title: "तानसेन", title_en: "tansen" },
      { id: 521, title: "बगनासकाली", title_en: "baganaskali" },
      { id: 520, title: "रम्भा", title_en: "rambha" },
      { id: 519, title: "पूर्वखोला", title_en: "purbakhola" },
      { id: 518, title: "रामपुर", title_en: "rampur" },
    ],
    "Nawalparasi (West)": [
      { id: 534, title: "सुस्ता", title_en: "susta" },
      { id: 533, title: "प्रतापपुर", title_en: "pratappur" },
      { id: 532, title: "सरावल", title_en: "sarawal" },
      { id: 531, title: "पाल्हीनन्दन", title_en: "palhinandan" },
      { id: 530, title: "रामग्राम", title_en: "ramgram" },
      { id: 529, title: "सुनवल", title_en: "sunwal" },
      { id: 528, title: "बर्दघाट", title_en: "bardghat" },
    ],
    Rupandehi: [
      { id: 550, title: "मर्चवारी", title_en: "marchawari" },
      { id: 549, title: "सम्मरीमाई", title_en: "sammarimai" },
      { id: 548, title: "कोटहीमाई", title_en: "kotahimai" },
      { id: 547, title: "लुम्बिनी सांस्कृतिक", title_en: "lumbinisanskritik" },
      { id: 546, title: "मायादेवी", title_en: "mayadevi" },
      { id: 545, title: "सिद्धार्थनगर", title_en: "siddharthanagar" },
      { id: 544, title: "रोहिणी", title_en: "rohini" },
      { id: 543, title: "ओमसतिया", title_en: "omsatiya" },
      { id: 542, title: "तिलोत्तमा", title_en: "tilottama" },
      { id: 541, title: "सियारी", title_en: "siyari" },
      { id: 540, title: "शुद्धोधन", title_en: "shuddhodhan" },
      { id: 539, title: "गैडहवा", title_en: "gaidahawa" },
      { id: 538, title: "कन्चन", title_en: "kanchan" },
      { id: 537, title: "सैनामैना", title_en: "sainamaina" },
      { id: 536, title: "बुटवल उपमहा", title_en: "butwal" },
      { id: 535, title: "देवदह", title_en: "devdaha" },
    ],
    Kapilvastu: [
      { id: 560, title: "सुद्धोधन", title_en: "shuddhodhan" },
      { id: 559, title: "मायादेवी", title_en: "mayadevi" },
      { id: 558, title: "यसोधरा", title_en: "yasodhara" },
      { id: 557, title: "कपिलवस्तु", title_en: "kapilvastu" },
      { id: 556, title: "महाराजगंज", title_en: "maharajgunj" },
      { id: 555, title: "कृष्णनगर", title_en: "krishnanagar" },
      { id: 554, title: "विजयनगर", title_en: "bijaynagar" },
      { id: 553, title: "शिवराज", title_en: "shivraj" },
      { id: 552, title: "बुद्धभूमी", title_en: "buddhabhumi" },
      { id: 551, title: "बाणगंगा", title_en: "banganga" },
    ],
    Dang: [
      { id: 570, title: "राजपुर", title_en: "rajpur" },
      { id: 569, title: "गढवा", title_en: "gadhawa" },
      { id: 568, title: "राप्ती", title_en: "rapti" },
      { id: 567, title: "लमही", title_en: "lamahi" },
      { id: 566, title: "दंगीशरण", title_en: "dangisharan" },
      { id: 565, title: "बबई", title_en: "babai" },
      { id: 564, title: "शान्तिनगर", title_en: "shantinagar" },
      { id: 563, title: "तुल्सीपुर उपमहा", title_en: "tulsipur" },
      { id: 562, title: "घोराही उपमहा", title_en: "ghorahi" },
      { id: 561, title: "बंगलाचुली", title_en: "bangalachuli" },
    ],
    Banke: [
      { id: 578, title: "नरैनापुर", title_en: "narainapur" },
      { id: 577, title: "डुडुवा", title_en: "duduwa" },
      { id: 576, title: "नेपालगंज उपमहा", title_en: "nepalgunj" },
      { id: 575, title: "जानकी", title_en: "janaki" },
      { id: 574, title: "खजुरा", title_en: "khajura" },
      { id: 573, title: "बैजनाथ", title_en: "baijanath" },
      { id: 572, title: "कोहलपुर", title_en: "kohalpur" },
      { id: 571, title: "राप्तीसोनारी", title_en: "raptisonari" },
    ],
    Bardiya: [
      { id: 586, title: "बढैयाताल", title_en: "badhaiyatal" },
      { id: 585, title: "गुलरिया", title_en: "gulariya" },
      { id: 584, title: "मधुवन", title_en: "madhuwan" },
      { id: 583, title: "राजापुर", title_en: "rajapur" },
      { id: 582, title: "गेरुवा", title_en: "geruwa" },
      { id: 581, title: "ठाकुरबाबा", title_en: "thakurbaba" },
      { id: 580, title: "बारबर्दिया", title_en: "barbardiya" },
      { id: 579, title: "बाँसगढी", title_en: "bansgadhi" },
    ],
  },
  "Karnali Province": {
    Dolpa: [
      { id: 594, title: "छार्का ताङसोङ", title_en: "chharkatangsong" },
      { id: 593, title: "काईके", title_en: "kaike" },
      { id: 592, title: "ठुली भेरी", title_en: "thulibheri" },
      { id: 591, title: "त्रिपुरासुन्दरी", title_en: "tripurasundari" },
      { id: 590, title: "मुड्केचुला", title_en: "mudkechula" },
      { id: 589, title: "जगदुल्ला", title_en: "jagdulla" },
      { id: 588, title: "शे फोक्सुन्डो", title_en: "shephoksundo" },
      { id: 587, title: "डोल्पो बुद्ध", title_en: "dolpobuddha" },
    ],
    Mugu: [
      { id: 598, title: "खत्याड", title_en: "khatyad" },
      { id: 597, title: "सोरु", title_en: "soru" },
      { id: 596, title: "छायाँनाथ रारा", title_en: "chhayanathrara" },
      { id: 595, title: "मुगुम कार्मारोंग", title_en: "mugumkarmarong" },
    ],
    Humla: [
      { id: 605, title: "ताँजाकोट", title_en: "tajakot" },
      { id: 604, title: "अदानचुली", title_en: "adanchuli" },
      { id: 603, title: "सर्केगाड", title_en: "sarkegad" },
      { id: 602, title: "नाम्खा", title_en: "namkha" },
      { id: 601, title: "सिमकोट", title_en: "simkot" },
      { id: 600, title: "खार्पुनाथ", title_en: "kharpunath" },
      { id: 599, title: "चंखेली", title_en: "chankheli" },
    ],
    Jumla: [
      { id: 613, title: "हिमा", title_en: "hima" },
      { id: 612, title: "तिला", title_en: "tila" },
      { id: 611, title: "तातोपानी", title_en: "tatopani" },
      { id: 610, title: "गुठिचौर", title_en: "guthichaur" },
      { id: 609, title: "चन्दननाथ", title_en: "chandannath" },
      { id: 608, title: "सिंजा", title_en: "sinja" },
      { id: 607, title: "कनकासुन्दरी", title_en: "kankasundari" },
      { id: 606, title: "पातारासी", title_en: "patarasi" },
    ],
    Kalikot: [
      { id: 622, title: "शुभ कालीका", title_en: "shuva kalika" },
      { id: 621, title: "महावै", title_en: "mahawai" },
      { id: 620, title: "तिलागुफा", title_en: "tilagufa" },
      { id: 619, title: "खाँडाचक्र", title_en: "khandachakra" },
      { id: 618, title: "नरहरिनाथ", title_en: "narharinath" },
      { id: 617, title: "सान्नी त्रिवेणी", title_en: "sannitriveni" },
      { id: 616, title: "रास्कोट", title_en: "raskot" },
      { id: 615, title: "पचालझरना", title_en: "pachaljharana" },
      { id: 614, title: "पलाता", title_en: "palata" },
    ],
    Dailekh: [
      { id: 633, title: "गुराँस", title_en: "gurans" },
      { id: 632, title: "डुंगेश्वर", title_en: "dungeshwor" },
      { id: 631, title: "भगवतीमाई", title_en: "bhagawatimai" },
      { id: 630, title: "नारायण", title_en: "narayan" },
      { id: 629, title: "दुल्लु", title_en: "dullu" },
      {
        id: 628,
        title: "चामुण्डा विन्द्रासैनी",
        title_en: "chamundabindrasaini",
      },
      { id: 627, title: "आठबीस", title_en: "aathabis" },
      { id: 626, title: "ठाँटीकाँध", title_en: "thantikandh" },
      { id: 625, title: "भैरवी", title_en: "bhairabi" },
      { id: 624, title: "महावु", title_en: "mahabu" },
      { id: 623, title: "नौमुले", title_en: "naumule" },
    ],
    Jajarkot: [
      { id: 640, title: "नलगाड", title_en: "nalgad" },
      { id: 639, title: "भेरी", title_en: "bheri" },
      { id: 638, title: "शिवालय", title_en: "shibalaya" },
      { id: 637, title: "छेडागाड", title_en: "chhedagad" },
      { id: 636, title: "जुनीचाँदे", title_en: "junichaande" },
      { id: 635, title: "कुसे", title_en: "kushe" },
      { id: 634, title: "बारेकोट", title_en: "barekot" },
    ],
    "Rukum (West)": [
      { id: 646, title: "चौरजहारी", title_en: "chaurjahari" },
      { id: 645, title: "त्रिवेणी", title_en: "triveni" },
      { id: 644, title: "मुसिकोट", title_en: "musikot" },
      { id: 643, title: "बाँफिकोट", title_en: "banphikot" },
      { id: 642, title: "सानी भेरी", title_en: "sanibheri" },
      { id: 641, title: "आठबिसकोट", title_en: "aathbiskot" },
    ],
    Salyan: [
      { id: 656, title: "कपुरकोट", title_en: "kapurkot" },
      { id: 655, title: "त्रिवेणी", title_en: "triveni" },
      { id: 654, title: "कालिमाटी", title_en: "kalimati" },
      { id: 653, title: "शारदा", title_en: "sharada" },
      { id: 652, title: "छत्रेश्वरी", title_en: "chhatreshwori" },
      { id: 651, title: "बागचौर", title_en: "bagchaur" },
      { id: 650, title: "सिद्ध कुमाख", title_en: "siddha kumakh" },
      { id: 649, title: "बनगाड कुपिण्डे", title_en: "bangadkupinde" },
      { id: 648, title: "कुमाख", title_en: "kumakh" },
      { id: 647, title: "दार्मा", title_en: "darma" },
    ],
    Surkhet: [
      { id: 665, title: "चौकुने", title_en: "chaukune" },
      { id: 664, title: "पञ्चपुरी", title_en: "panchapuri" },
      { id: 663, title: "बराहताल", title_en: "barahatal" },
      { id: 662, title: "बीरेन्द्रनगर", title_en: "birendranagar" },
      { id: 661, title: "भेरीगंगा", title_en: "bheriganga" },
      { id: 660, title: "गुर्भाकोट", title_en: "gurbhakot" },
      { id: 659, title: "लेकवेशी", title_en: "lekbeshi" },
      { id: 658, title: "चिङ्गाड", title_en: "chingad" },
      { id: 657, title: "सिम्ता", title_en: "simta" },
    ],
  },
  "Sudurpaschim Province": {
    Bajura: [
      { id: 674, title: "त्रिवेणी", title_en: "triveni" },
      { id: 673, title: "बुढीगंगा", title_en: "budhiganga" },
      { id: 672, title: "खप्तड छेडेदह", title_en: "khaptad chhededaha" },
      { id: 671, title: "बडीमालिका", title_en: "badimalika" },
      { id: 670, title: "जगन्‍नाथ", title_en: "jagannath" },
      { id: 669, title: "स्वामीकार्तिक खापर", title_en: "swamikartik khapar" },
      { id: 668, title: "बुढीनन्दा", title_en: "budhinanda" },
      { id: 667, title: "गौमुल", title_en: "gaumul" },
      { id: 666, title: "हिमाली", title_en: "himali" },
    ],
    Bajhang: [
      { id: 686, title: "खप्तडछान्ना", title_en: "khaptadchhanna" },
      { id: 685, title: "थलारा", title_en: "thalara" },
      { id: 684, title: "वित्थडचिर", title_en: "bitthadchir" },
      { id: 683, title: "केदारस्युँ", title_en: "kedarasyu" },
      { id: 682, title: "दुर्गाथली", title_en: "durgathali" },
      { id: 681, title: "छबिसपाथिभेरा", title_en: "chhabispathivera" },
      { id: 680, title: "जयपृथ्वी", title_en: "jayaprithvi" },
      { id: 679, title: "मष्टा", title_en: "masta" },
      { id: 678, title: "तलकोट", title_en: "talkot" },
      { id: 677, title: "सूर्मा", title_en: "surma" },
      { id: 676, title: "बुंगल", title_en: "bungal" },
      { id: 675, title: "साइपाल", title_en: "saipal" },
    ],
    Darchula: [
      { id: 695, title: "लेकम", title_en: "lekam" },
      { id: 694, title: "मालिकार्जुन", title_en: "malikarjun" },
      { id: 693, title: "शैल्यशिखर", title_en: "shailyashikhar" },
      { id: 692, title: "मार्मा", title_en: "marma" },
      { id: 691, title: "अपिहिमाल", title_en: "apihimal" },
      { id: 690, title: "नौगाड", title_en: "naugad" },
      { id: 689, title: "महाकाली", title_en: "mahakali" },
      { id: 688, title: "दुहुँ", title_en: "duhun" },
      { id: 687, title: "ब्याँस", title_en: "vyans" },
    ],
    Baitadi: [
      { id: 705, title: "सिगास", title_en: "sigas" },
      { id: 704, title: "पाटन", title_en: "patan" },
      { id: 703, title: "मेलौली", title_en: "melauli" },
      { id: 702, title: "शिवनाथ", title_en: "shivanath" },
      { id: 701, title: "पञ्चेश्वर", title_en: "pancheshwor" },
      { id: 700, title: "दशरथचन्द", title_en: "dasharathchand" },
      { id: 699, title: "सुर्नया", title_en: "sunarya" },
      { id: 698, title: "पुर्चौडी", title_en: "purchaudi" },
      { id: 697, title: "दोगडाकेदार", title_en: "dogdakedar" },
      { id: 696, title: "डीलासैनी", title_en: "dilasaini" },
    ],
    Dadeldhura: [
      { id: 712, title: "गन्यापधुरा", title_en: "ganyapadhura" },
      { id: 711, title: "आलिताल", title_en: "aalital" },
      { id: 710, title: "परशुराम", title_en: "parshuram" },
      { id: 709, title: "भागेश्वर", title_en: "bhageshwor" },
      { id: 708, title: "अजयमेरु", title_en: "ajayameru" },
      { id: 707, title: "अमरगढी", title_en: "amargadhi" },
      { id: 706, title: "नवदुर्गा", title_en: "navadurga" },
    ],
    Doti: [
      { id: 721, title: "जोरायल", title_en: "jorayal" },
      { id: 720, title: "बडीकेदार", title_en: "badikedar" },
      { id: 719, title: "बोगटान फुड्सिल", title_en: "bogatan phudsil" },
      { id: 718, title: "के.आई.सिं.", title_en: "kisingh" },
      { id: 717, title: "दिपायल सिलगढी", title_en: "dipayalsilgadhi" },
      { id: 716, title: "शिखर", title_en: "shikhar" },
      { id: 715, title: "आदर्श", title_en: "aadarsha" },
      { id: 714, title: "सायल", title_en: "sayal" },
      { id: 713, title: "पूर्वीचौकी", title_en: "purbichauki" },
    ],
    Achham: [
      { id: 731, title: "तुर्माखाँद", title_en: "turmakhad" },
      { id: 730, title: "ढकारी", title_en: "dhakari" },
      { id: 729, title: "कमलबजार", title_en: "kamalbazar" },
      { id: 728, title: "बान्निगढी जयगढ", title_en: "bannigadhijaygadh" },
      { id: 727, title: "मंगलसेन", title_en: "mangalsen" },
      { id: 726, title: "चौरपाटी", title_en: "chaurpati" },
      { id: 725, title: "साँफेबगर", title_en: "sanfebagar" },
      { id: 724, title: "मेल्लेख", title_en: "mellekh" },
      { id: 723, title: "रामारोशन", title_en: "ramaroshan" },
      { id: 722, title: "पन्चदेवल विनायक", title_en: "panchadewalbinayak" },
    ],
    Kailali: [
      { id: 744, title: "धनगढी उपमहा", title_en: "dhangadhi" },
      { id: 743, title: "कैलारी", title_en: "kailari" },
      { id: 742, title: "भजनी", title_en: "bhajani" },
      { id: 741, title: "टिकापुर", title_en: "tikapur" },
      { id: 740, title: "जोशीपुर", title_en: "joshipur" },
      { id: 739, title: "जानकी", title_en: "janaki" },
      { id: 738, title: "लम्कीचुहा", title_en: "lamkichuha" },
      { id: 737, title: "बर्दगोरिया", title_en: "bardgoriya" },
      { id: 736, title: "घोडाघोडी", title_en: "ghodaghodi" },
      { id: 735, title: "गौरीगंगा", title_en: "gauriganga" },
      { id: 734, title: "गोदावरी", title_en: "godawari" },
      { id: 733, title: "चुरे", title_en: "chure" },
      { id: 732, title: "मोहन्याल", title_en: "mohanyal" },
    ],
    Kanchanpur: [
      { id: 753, title: "बेलडाडी", title_en: "beldandi" },
      { id: 752, title: "बेलौरी", title_en: "belauri" },
      { id: 751, title: "पुर्नवास", title_en: "punarbas" },
      { id: 750, title: "लालझाडी", title_en: "laljhadi" },
      { id: 749, title: "महाकाली", title_en: "mahakali" },
      { id: 748, title: "भीमदत्त", title_en: "bheemdatta" },
      { id: 747, title: "वेदकोट", title_en: "bedkot" },
      { id: 746, title: "शुक्लाफाँटा", title_en: "shuklaphanta" },
      { id: 745, title: "कृष्णपुर", title_en: "krishnapur" },
    ],
  },
};
