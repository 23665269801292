import AvTimerIcon from "@mui/icons-material/AvTimer";

import ShiftList from "./ShiftList";
import ShiftCreate from "./ShiftCreate";
import ShiftEdit from "./ShiftEdit";

const resource = {
  list: ShiftList,
  create: ShiftCreate,
  edit: ShiftEdit,
  icon: AvTimerIcon,
};

export default resource;
