import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
	Datagrid,
	List,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	useGetOne,
	TextField,
	Create,
	useGetList,
} from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';

export default function OpeningBalanceList() {
	const [edit, setEdit] = useState(null);
	const { isLoading, data } = useGetOne('fiscalYear/active', { id: '' });
	const { data: fiscalYears } = useGetList('fiscalYear');
	return (
		!isLoading && (
			<Box>
				{edit && (
					<EditOpeningBalance
						edit={edit}
						setEdit={setEdit}
						activeFiscal={data}
					/>
				)}
				<List
					empty={false}
					filterDefaultValues={{ fiscal: data?.id }}
					filters={[
						<AutocompleteInput
							source="fiscal"
							choices={fiscalYears}
							optionValue="id"
							optionText="title"
							alwaysOn
						/>,
					]}
				>
					<Box sx={{ p: 2, pb: 0 }}>
						<SectionTitle label="pharmacy.account.openingBalance" />
					</Box>
					<Datagrid
						rowClick={(id, resource, record) => setEdit(record)}
						bulkActionButtons={false}
					>
						<TextField source="name" label="Vendor" />
						<TextField source="pan" />
						<TextField source="address" />
						<TextField source="opening.debit" label="Debit" />
						<TextField source="opening.credit" label="Credit" />
					</Datagrid>
				</List>
			</Box>
		)
	);
}

function EditOpeningBalance({ edit, setEdit, activeFiscal }) {
	return (
		<Box>
			<Create
				transform={(data) => {
					return { vendor: edit?.id, ...data };
				}}
				mutationOptions={{
					onSuccess: () => setEdit(null),
				}}
				resource="pharmacyOpeningBalance"
			>
				<Box sx={{ p: 2, pb: 0 }}>
					<Typography variant="h6">{edit?.name}</Typography>
				</Box>
				<SimpleForm>
					<EditForm editData={edit} activeFiscal={activeFiscal} />
				</SimpleForm>
			</Create>
		</Box>
	);
}

function EditForm({ editData, activeFiscal }) {
	return (
		<Box
			sx={{
				width: '100%',
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				gap: 2,
			}}
		>
			<ReferenceInput source="fiscal" reference="fiscalYear">
				<AutocompleteInput optionText="title" defaultValue={activeFiscal?.id} />
			</ReferenceInput>
			<TextInput source="debit" defaultValue={editData?.opening?.debit} />
			<TextInput source="credit" defaultValue={editData?.opening?.credit} />
		</Box>
	);
}
