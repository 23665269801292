import { Typography } from '@mui/material';
import { useGetList } from 'react-admin';

export default function CashBalance() {
	const { data } = useGetList('pharmacy/balance');
	return (
		<>
			<div>
				<Typography variant="h6">My Cash Balance : </Typography>
				<Typography
					variant="h4"
					sx={{ color: (theme) => theme?.palette?.primary?.main }}
				>
					{data
						? 'Rs. ' +
						  (
								(+data[0]?.pharmacyBillingBalance?.totalValue || 0) -
								(+data[0]?.pharmacyReturnBillingBalance?.totalValue || 0) -
								(+data[0]?.cashDepositBalance?.totalValue || 0)
						  ).toFixed(2) +
						  ' /-'
						: 'N/A'}
				</Typography>
			</div>
		</>
	);
}
