import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { validateForm } from './ManufacturerCreate';
import ManufacturerForm from './ManufacturerForm';

const ManufacturerEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/manufacturer">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.manufacturer.title')}
				</Typography>
				<ManufacturerForm />
			</SimpleForm>
		</Edit>
	);
};

export default ManufacturerEdit;
