import PaymentList from './PaymentList';
import PaymentCreate from './PaymentCreate';
import PaymentEdit from './PaymentEdit';

const payment = {
	list: PaymentList,
	create: PaymentCreate,
	edit: PaymentEdit,
	icon: null,
};

export default payment;
