import { Create, useGetList, SimpleForm } from "react-admin";
import BillingAside from "./BillingAside";
import { useState, useMemo } from "react";

import handlePrint from "../../_helper/print";
import BillingCreateForm from "./BillingCreateForm";

import Save from "../../elements/SaveButton";
import { Print } from "../../_helper";

export const validateForm = (values) => {
  const errors = {};
  return errors;
};

const BillingCreate = () => {
  const [patientDetails, setPatientDetails] = useState({});
  const [services, setServices] = useState([]);
  const [search, setSearch] = useState("");

  const bills = useGetList("billing", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "createdAt", order: "DESC" },
    filter: { q: search },
  });

  function sumArray(obj, property) {
    const sum = obj.reduce(function (previousVal, currentVal) {
      return previousVal + +currentVal[property];
    }, 0);
    return sum;
  }

  const total = useMemo(() => {
    const quantity = sumArray(services, "quantity");
    const basicAmount = sumArray(services, "basicAmount");
    const discount = sumArray(services, "discount");
    return {
      items: services.length,
      quantity,
      basicAmount,
      discount,
      total: basicAmount - discount,
    };
  }, [services]);

  return (
    <Create
      aside={<BillingAside {...bills} search={search} setSearch={setSearch} />}
      redirect="/billing/create"
      transform={(data) => {
        const tempData = {
          ...data,
          ...(Object.keys(patientDetails).length > 0 && {
            name: patientDetails.name,
            age: patientDetails.age,
            ageType: patientDetails.ageType,
            gender: patientDetails.gender,
          }),
        };
        if (!data.charity) {
          delete data.charity;
        }
        delete tempData.quantity;
        delete tempData.rate;
        delete tempData.discount;
        delete tempData.basicAmount;
        tempData.services = services;
        tempData.basicAmount = total.basicAmount || 0;
        tempData.discount = total.discount || 0;
        tempData.total = total.total || 0;
        tempData.patientId = patientDetails.patientId;
        return tempData;
      }}
      mutationOptions={{
        onSuccess: (data) => {
          handlePrint(data?.print);
        },
      }}
    >
      <SimpleForm
        // sx={{ maxWidth: 500 }}
        defaultValues={{}}
        validate={validateForm}
        toolbar={
          <Save
            onSuccess={(data) => {
              bills.refetch();
              Print(data.print);

              setServices([]);
              setPatientDetails({});
            }}
            search={search}
            setSearch={setSearch}
          />
        }
        sanitizeEmptyValues
      >
        <BillingCreateForm
          setPatientDetails={setPatientDetails}
          setServices={setServices}
          patientDetails={patientDetails}
          services={services}
          total={total}
        />
      </SimpleForm>
    </Create>
  );
};

export default BillingCreate;
