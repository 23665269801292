import { Box } from '@mui/material';
import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import SectionTitle from '../../../../../elements/SectionTitle';
import LabDeptForm from './labDeptForm';

export default function LabDeptCreate() {
	return (
		<Create redirect="/pathologyDepartment">
			<Box p={3} pb={0}>
				<SectionTitle label="pathology.settings.labDept" />
			</Box>
			<SimpleForm>
				<LabDeptForm />
			</SimpleForm>
		</Create>
	);
}
