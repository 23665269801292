import ExpiryMgmtCreate from "./ExpiryMgmtCreate";
import ExpiryMgmtList from "./ExpiryMgmtList";
import ExpiryMgmtEdit from "./ExpiryMgmtEdit";
import { ManageHistory } from "@mui/icons-material";

const resource = {
  list: ExpiryMgmtList,
  create: ExpiryMgmtCreate,
  edit: ExpiryMgmtEdit,
  icon: ManageHistory,
};

export default resource;
