import { Create, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import BrandBulkForm from './BrandBulkForm';

export const validateForm = (values) => {
	const errors = {};
	if (!values.name) {
		errors.name = 'ra.validation.required';
	}
	return errors;
};

const BrandBulkCreate = () => (
	<Create redirect="/bulkBrand">
		<SimpleForm
			sx={{ maxWidth: 500 }}
			defaultValues={{
				name: '',
			}}
			validate={validateForm}
		>
			<SectionTitle label="pharmacy.bulkBrand.title" />
			<BrandBulkForm />
		</SimpleForm>
	</Create>
);

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

export default BrandBulkCreate;
