import { Factory } from '@mui/icons-material';
import ManufacturerCreate from './ManufacturerCreate';
import ManufacturerList from './ManufacturerList';
import ManufacturerEdit from './MaunufacturerEdit';

const resource = {
	list: ManufacturerList,
	create: ManufacturerCreate,
	edit: ManufacturerEdit,
	icon: Factory,
};

export default resource;
