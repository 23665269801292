import { Groups } from '@mui/icons-material';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';
import CustomersList from './CustomerList';

const resource = {
	list: CustomersList,
	create: CustomerCreate,
	edit: CustomerEdit,
	icon: Groups,
};

export default resource;
