import { Box } from "@mui/material";
import { BooleanInput, TextInput } from "react-admin";

export default function FiscalYearForm() {
  return (
    <Box sx={{ width: "100%" }}>
      <TextInput source="title" isRequired fullWidth />
      <BooleanInput source="active" label="Is Active?" />
    </Box>
  );
}
