import React from "react";
import NepaliDatePicker from "./DatePicker";
import styled from "@emotion/styled";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { useInput, useTheme } from "react-admin";
import { camelToFlat } from "../_helper";
import { grey } from "@mui/material/colors";
import { ADToBS } from "./DatePicker/AdToBsConverter/AdBsConverter";
import dayjs from "dayjs";
export default function NepaliDateInput({
  source,
  label,
  errors,
  defaultValue = ADToBS(dayjs(Date.now()).format("YYYY/MM/DD")),
  setValue,
  ...rest
}) {
  const [theme] = useTheme();
  const { id, field, fieldState } = useInput({
    source,
    defaultValue: defaultValue?.replace(new RegExp("/", "g"), "-"),
    ...rest,
  });

  const onDateChange = (name, dateInMilli, bsDate, adDate) => {
    if (bsDate) {
      if (field?.onChange) {
        let date = bsDate.replace(new RegExp("/", "g"), "-");
        field.onChange(date);
      }
    }
  };
  return (
    <StyledDateContainer>
      <div className="np_input_div">
        <label
          className={["nepali-date-label", "label-" + theme].join(" ")}
          htmlFor={id}
        >
          {label || camelToFlat(source)}
        </label>
        <NepaliDatePicker
          id={id}
          onChange={onDateChange}
          value={
            field.value
              ? field.value.replace(new RegExp("-", "g"), "/")
              : undefined
          }
          placeholder="YYYY/MM/DD"
        />
        <CalendarMonthOutlined className="icon" />
        <input type="text" id={id} style={{ display: "none" }} {...field} />
      </div>
      <div className="error-message">{fieldState.error?.message}</div>
    </StyledDateContainer>
  );
}
export function NepaliDateInputCommon({
  id,
  label = "Date",
  errors,
  defaultValue,
  setValue,
  onChange,
  value,
}) {
  const [theme] = useTheme();

  const onDateChange = (name, dateInMilli, bsDate, adDate) => {
    if (bsDate) {
      if (onChange) {
        let date = bsDate.replace(new RegExp("/", "g"), "-");
        onChange(date);
      }
    }
  };
  return (
    <StyledDateContainer>
      <div className="np_input_div">
        <label
          className={["nepali-date-label", "label-" + theme].join(" ")}
          htmlFor={id}
        >
          {label}
        </label>
        <NepaliDatePicker
          id={id}
          onChange={onDateChange}
          value={value?.replace(new RegExp("-", "g"), "/")}
          placeholder="YYYY/MM/DD"
        />
        <CalendarMonthOutlined className="icon" />
      </div>
    </StyledDateContainer>
  );
}

const StyledDateContainer = styled.div`
  position: relative;
  .np_input_div {
    position: relative;

    .icon {
      position: absolute;
      font-size: 16px;
      top: 50%;
      transform: translate(0, -50%);
      right: 10px;
      pointer-events: none;
      cursor: pointer;
    }
    .nepali-date-label {
      position: absolute;
      left: 0.5rem;
      top: -0.5rem;
      z-index: 9999;
      font-size: 12px;
      padding-inline: 0.5rem;
      color: ${grey[600]};
      border-radius: 0.5rem;
    }
    .label-light {
      background-color: white;
    }
    .label-dark {
      background-color: #444;
      color: white;
    }
  }
  .nepali-date-input {
    border-radius: 8px;
    font-size: 14px;
    &::placeholder {
      font-size: 12px;
    }
  }

  .error-message {
    min-height: 1rem;
  }
`;
