import { Box, TextField } from '@mui/material';
import React from 'react';
import {
	AutocompleteInput,
	Datagrid,
	DateField,
	DateInput,
	List,
	SearchInput,
} from 'react-admin';
import SectionTitle from '../../../../elements/SectionTitle';

export default function ProductLedger() {
	return (
		<List
			filters={[
				<AutocompleteInput source="brand" choices={[]} alwaysOn />,
				<DateInput source="from" alwaysOn />,
				<DateInput source="to" alwaysOn />,
				<SearchInput source="q" alwaysOn />,
			]}
			empty={false}
		>
			<Box sx={{ p: 2, pb: 0 }}>
				<SectionTitle label="pharmacy.report.productLedger" />
			</Box>
			<Datagrid>
				<TextField source="batchNo" />
				<DateField source="expiryDate" />
				<DateField source="date" />
				<TextField source="type" />
				<TextField source="effect" />
				<TextField source="description" />
				<TextField source="unit" />
			</Datagrid>
		</List>
	);
}
