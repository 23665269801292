import LabServiceCreate from './labServiceCreate';
import LabServiceList from './labServiceList';
import LabServiceEdit from './labServiceEdit';

const labServices = {
	list: LabServiceList,
	create: LabServiceCreate,
	edit: LabServiceEdit,
	icon: null,
};

export default labServices;
