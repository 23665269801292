import React from "react";
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  useTranslate,
} from "react-admin";
import CustomTextInput from "../../elements/CustomTextInput";
import CustomAutoInput from "../../elements/CustomAutoInput";
import { Box, TextField, Typography } from "@mui/material";
import { PALIKADATA } from "../../_data/district";
import ShiftSelect from "../../elements/ShiftSelect";

const province = Object.keys(PALIKADATA).map((dis) => ({ id: dis, name: dis }));

const getDistricts = (province) => {
  if (province) {
    return Object.keys(PALIKADATA[province]).map((palika) => ({
      id: palika,
      name: palika,
    }));
  }
};
const getLocalBodies = (province, district) => {
  if (province && district) {
    return PALIKADATA[province][district].map((palika) => ({
      id: palika["title_en"],
      name: palika["title_en"],
    }));
  } else {
    return [];
  }
};

export default function PatientForm({ ticketType, setTicketType }) {
  return (
    <>
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput
            label="Ticket Type"
            source="ticketType"
            reference="ticketType"
          >
            <CustomAutoInput
              label="Ticket Type"
              optionText="title"
              optionValue="id"
              fullWidth
              isRequired
              size="small"
              title="title"
              onSelect={(e) => {
                setTicketType(e);
              }}
              // value={ticketType}
            />
          </ReferenceInput>
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ShiftSelect />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="nshiNo" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput
            label="Department"
            source="department"
            reference="department"
          >
            <AutocompleteInput
              optionText="title"
              optionValue="id"
              fullWidth
              isRequired
              size="small"
            />
          </ReferenceInput>
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput label="Charity" source="charity" reference="charity">
            <AutocompleteInput
              optionText="title"
              optionValue="id"
              fullWidth
              size="small"
            />
          </ReferenceInput>
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput disabled source="insuranceClaimCode" fullWidth />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="patient.firstName"
            label="First Name"
            isRequired
            fullWidth
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="patient.middleName"
            label="Middle Name"
            fullWidth
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="patient.lastName"
            label="Last Name"
            isRequired
            fullWidth
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <NumberInput source="patient.age" label="Age" isRequired fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <AutocompleteInput
            source="patient.ageType"
            label="Age Type"
            isRequired
            fullWidth
            choices={[
              { id: "years", name: "years" },
              { id: "months", name: "months" },
              { id: "days", name: "days" },
            ]}
            size="small"
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <AutocompleteInput
            source="patient.gender"
            label="Gender"
            isRequired
            fullWidth
            choices={[
              { id: "male", name: "male" },
              { id: "female", name: "female" },
              { id: "others", name: "others" },
            ]}
            size="small"
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="patient.mulDartaNo"
            label="Mul Darta Number"
            fullWidth
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="patient.opdDartaNo"
            label="OPD Darta Number"
            fullWidth
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <AutocompleteInput
            source="patient.casteCode"
            choices={[
              { id: 1, name: "1" },
              { id: 2, name: "2" },
              { id: 3, name: "3" },
              { id: 4, name: "4" },
              { id: 5, name: "5" },
              { id: 6, name: "6" },
            ]}
          />
        </Box>
      </Box>

      <Separator />

      <SectionTitle label="resources.customers.fieldGroups.address" />
      <Box
        display={{
          xs: "block",
          sm: "flex",
          width: "100%",
          marginTop: "15px",
        }}
      >
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <AutocompleteInput
            source="patient.province"
            label="Province"
            choices={province}
            fullWidth
            size="small"
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <AutocompleteInput
                source="patient.district"
                label="District"
                choices={getDistricts(formData?.patient?.province)}
                isRequired
                {...rest}
                size="small"
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <AutocompleteInput
                source="patient.local"
                label="Local"
                choices={getLocalBodies(
                  formData?.patient?.province,
                  formData?.patient?.district
                )}
                isRequired
                {...rest}
                size="small"
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box flex={1}>
          <TextInput source="patient.ward" label="Ward" isRequired fullWidth />
        </Box>
      </Box>
      <Separator />

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="patient.mobile" label="Mobile" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput label="doctor" source="doctor" reference="doctor">
            <AutocompleteInput
              optionText="name"
              optionValue="id"
              fullWidth
              size="small"
            />
          </ReferenceInput>
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <CustomTextInput
            disabled
            type="number"
            value={ticketType?.rate}
            source="amount"
            fullWidth
            onChange={(e) =>
              setTicketType((prev) => ({ ...prev, rate: e.target.value }))
            }
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <NumberInput source="discount" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <NumberInput source="tenderAmount" fullWidth />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <CustomTextInput
                disabled
                type="number"
                label="Change Amount"
                source="changeAmount"
                fullWidth
                value={
                  (+formData.tenderAmount || 0) -
                  (+(formData.amount || +ticketType?.rate) || 0) +
                  (+formData.discount || 0)
                }
              />
            )}
          </FormDataConsumer>
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <AutocompleteInput
            source="invoiceType"
            isRequired
            fullWidth
            choices={[
              { id: "cash", name: "cash" },
              { id: "credit", name: "credit" },
            ]}
            size="small"
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput label="counter" source="counter" reference="counter">
            <AutocompleteInput
              optionText="title"
              optionValue="id"
              fullWidth
              isRequired
              size="small"
            />
          </ReferenceInput>
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="remarks" fullWidth />
        </Box>
      </Box>
    </>
  );
}

const Separator = () => <Box pt="1em" />;

const SectionTitle = ({ label }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
