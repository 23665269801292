import { useState } from 'react';
import Box from '@mui/material/Box';
import { useSidebarContext } from '../_contexts/SidebarContext';
import {
	useTranslate,
	MenuItemLink,
	useSidebarState,
	usePermissions,
} from 'react-admin';

import SubMenu from './SubMenu';
import {
	MenuItem,
	Typography,
	ListItemIcon,
	ListItemText,
	Popover,
} from '@mui/material';
import { camelToFlat } from '../_helper';

const Menu = ({ dense = false }) => {
	const { menu } = useSidebarContext();
	const [state, setState] = useState({});
	const translate = useTranslate();
	const [open] = useSidebarState();

	const handleToggle = (menu) => {
		setState((state) => ({ ...state, [menu]: !state[menu] }));
	};

	const { permissions } = usePermissions();

	return (
		<Box
			sx={{
				width: open ? 200 : 50,
				marginTop: 1,
				marginBottom: 1,
				transition: (theme) =>
					theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
			}}
		>
			{/* <DashboardMenuItem /> */}
			{Object.keys(menu).map((me) => (
				<>
					{me?.includes('nogroup')
						? undefined
						: menu?.[me]?.filter((value) => {
								return authCheck(value, permissions);
						  })?.length > 0 && (
								<Typography
									variant="body1"
									sx={{
										paddingInline: '0.75rem',
										marginBlock: '0.25rem',
										fontWeight: '700',
									}}
									color={(theme) => theme.palette.primary.main}
								>
									{camelToFlat(me)}
								</Typography>
						  )}
					{menu[me]?.map((m) => {
						let isAuthorized = authCheck(m, permissions);
						if (isAuthorized) {
							return m?.submenus && m?.submenus?.length > 0 ? (
								<SubMenu
									key={m?.key}
									handleToggle={() => handleToggle(m?.title?.text)}
									isOpen={state[m?.title?.text]}
									name={m?.title?.i18n || m?.title?.text}
									{...(m?.icon && { icon: m?.icon })}
									dense={dense}
								>
									{m?.submenus?.map((sub) =>
										sub.popover ? (
											<MenuPopover menu={sub} />
										) : (
											<MenuItemLink
												to={sub?.to}
												state={{ _scrollToTop: true }}
												primaryText={
													sub?.title?.i18n
														? translate(sub?.title?.i18n, {
																smart_count: 2,
														  })
														: sub?.title?.text
												}
												leftIcon={sub?.icon}
												dense={dense}
											/>
										)
									)}
								</SubMenu>
							) : m.popover ? (
								<MenuPopover menu={m} />
							) : (
								<MenuItemLink
									to={m?.to}
									state={{ _scrollToTop: true }}
									primaryText={
										m?.title?.i18n
											? translate(m?.title?.i18n, {
													smart_count: 2,
											  })
											: m?.title?.text
									}
									leftIcon={m?.icon}
									dense={dense}
								/>
							);
						} else return null;
					})}
				</>
			))}
		</Box>
	);
};

export default Menu;

const MenuPopover = ({ menu }) => {
	const translate = useTranslate();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<>
			<MenuItem
				aria-describedby={menu?.key}
				sx={{ borderLeft: '3px solid transparent' }}
				onClick={handleClick}
			>
				{menu?.icon && <ListItemIcon>{menu?.icon}</ListItemIcon>}
				<ListItemText>
					{translate(menu.title?.i18n) || menu.title?.text}
				</ListItemText>
			</MenuItem>
			<Popover
				id={menu?.key}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box sx={{ padding: '1em' }}>{menu?.popover}</Box>
			</Popover>
		</>
	);
};

function authCheck(menu, permissions) {
	if (permissions?.['*']) return true;
	if (menu?.permission) {
		const [name = '', allowed = ''] = menu?.permission?.split(':');
		const rules = permissions?.[name];
		console.log(name, allowed, rules);
		if (rules?.includes(allowed)) return true;
		else return false;
	}
	return false;
}
