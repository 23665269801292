import React, { useState } from "react";
import {
  Datagrid,
  FunctionField,
  TextField,
  Button,
  downloadCSV,
  TopToolbar,
  ExportButton,
  CreateButton,
  useGetList,
  useList,
  ListContextProvider,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import handlePrint from "../../../_helper/print";
import { NepaliDateInputCommon } from "../../../elements/NepaliDateInput";
import { ADToBS } from "../../../elements/DatePicker/AdToBsConverter/AdBsConverter";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function BillingList() {
  const [filters, setFilters] = useState({
    date: {
      $gte: ADToBS(dayjs(new Date()).format("YYYY/MM/DD")).replace(
        new RegExp("/", "g"),
        "-"
      ),
      $lte: ADToBS(dayjs(new Date()).format("YYYY/MM/DD")).replace(
        new RegExp("/", "g"),
        "-"
      ),
    },
  });
  const { data, isLoading } = useGetList("pharmacyBilling", {
    filters: [],
    filter: filters,

    sort: { field: "createdAt", order: "DESC" },
  });
  const listContext = useList({ data, isLoading });
  return (
    <ListContextProvider value={listContext}>
      <Filters filters={filters} setFilters={setFilters} />
      <Datagrid bulkActionButtons={false}>
        <TextField source="date" />
        <TextField source="name" />
        <TextField source="billNo" />
        <TextField source="basicAmount" label="Amount" />
        <TextField source="discountAmount" label="Discount" />
        <TextField source="vat" label="VAT" />
        <TextField source="total" />
        <FunctionField
          label="Bill"
          render={(record) =>
            record?.print && (
              <Button onClick={() => handlePrint(record?.print)}>Print</Button>
            )
          }
        />
      </Datagrid>
    </ListContextProvider>
  );
}

function Filters({ filters, setFilters }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <NepaliDateInputCommon
          label="Start Date"
          value={filters?.date?.$gte?.replace(new RegExp("-", "g"), "/")}
          onChange={(date) =>
            setFilters((prev) => ({ date: { ...prev.date, $gte: date } }))
          }
        />
        <NepaliDateInputCommon
          label="End Date"
          value={filters?.date?.$lte?.replace(new RegExp("-", "g"), "/")}
          onChange={(date) =>
            setFilters((prev) => ({ date: { ...prev.date, $lte: date } }))
          }
        />
      </Box>
      <TopToolbar>
        <Button
          startIcon={<Close />}
          label="Clear Filter"
          onClick={() => setFilters({})}
        />
        <CreateButton />
        <ExportButton
          exporter={(data) => {
            let postsForExport = data.map((post) => {
              let d = { ...post };
              delete d.patient;
              delete d.brands;
              delete d.createdAt;
              delete d.createdBy;
              delete d.id;
              delete d.print;
              delete d.charity;
              return d;
            });
            const lastRow = {
              basicAmount: data?.reduce(
                (a, c) => +(a || 0) + +(c?.basicAmount || 0),
                0
              ),
              discountAmount: data?.reduce(
                (a, c) => +(a || 0) + +(c?.discountAmount || 0),
                0
              ),
              total: data?.reduce((a, c) => +(a || 0) + +(c?.total || 0), 0),
              tenderAmount: data?.reduce(
                (a, c) => +(a || 0) + +(c?.tenderAmount || 0),
                0
              ),
              changeAmount: data?.reduce(
                (a, c) => +(a || 0) + +(c?.changeAmount || 0),
                0
              ),
              date: "Total",
            };
            postsForExport.push(lastRow);
            jsonExport(
              postsForExport,
              {
                headers: [
                  "date",
                  "billNo",
                  "name",
                  "basicAmount",
                  "discount",
                  "discountAmount",
                  "roundOff",
                  "total",
                  "tenderAmount",
                  "changeAmount",
                ],
              },
              (err, csv) => {
                downloadCSV(csv, "billings");
              }
            );
          }}
        />
      </TopToolbar>
    </Box>
  );
}
