import React, { useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import {
	AutocompleteInput,
	NumberInput,
	ReferenceInput,
	SelectInput,
	TextInput,
} from 'react-admin';
import ArrayTable, {
	CustomAutoInput,
} from '../../../elements/CustomArrayInput';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import NepaliDateInput from '../../../elements/NepaliDateInput';
import { ADToBS } from '../../../elements/DatePicker/AdToBsConverter/AdBsConverter';

export default function BillingForm({ state }) {
	const { setValue } = useFormContext();
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'stretch',
				// gridTemplateColumns: '5fr auto 2fr',
				columnGap: '1rem',
			}}
		>
			<Box sx={{ flexGrow: 1 }}>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(4, 1fr)',
						width: '100%',
						columnGap: '1rem',
					}}
				>
					<NepaliDateInput
						source="date"
						defaultValue={ADToBS(dayjs(new Date()).format('YYYY/MM/DD'))}
						isRequired
					/>
					{/* <DateInput
						source="date"
						defaultValue={dayjs(Date.now()).format('MM/DD/YYYY')}
						isRequired
					/> */}
					<Box sx={{ gridColumn: 'span 3 / span 3' }} />
					<ReferenceInput
						label="Patient Id"
						source="patient"
						reference="patient"
					>
						<AutocompleteInput
							label="Patient ID"
							optionText="patientId"
							optionValue="id"
							onChange={(_, option) =>
								setValue('name', option?.firstName + ' ' + option?.lastName)
							}
							size="small"
							fullWidth
							isRequired
						/>
					</ReferenceInput>
					<ReferenceInput
						label="Patient Name"
						source="patient"
						reference="patient"
					>
						<AutocompleteInput
							label="Patient Name"
							optionText={(record) =>
								record?.firstName + ' ' + record?.lastName
							}
							onChange={(_, option) =>
								setValue('name', option?.firstName + ' ' + option?.lastName)
							}
							optionValue="id"
							size="small"
							fullWidth
							isRequired
						/>
					</ReferenceInput>
					<ReferenceInput label="Scheme" source="charity" reference="charity">
						<AutocompleteInput
							label="Scheme"
							optionText="title"
							optionValue="id"
							size="small"
							fullWidth
							isRequired
						/>
					</ReferenceInput>
					<TextInput source="name" label="Invoice Name" />
				</Box>
				<Divider
					sx={{ width: '100%', marginBottom: '1em', marginTop: '-0.75em' }}
				/>
				<ArrayTable
					inputs={inputs}
					headers={headers}
					state={state}
					actions={{ delete: true }}
					inputGridColumn={4}
				/>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(4, 1fr)',
						width: '100%',
						columnGap: '1rem',
					}}
				>
					<TextInput source="doctor" label="Prescribed By Doctor" />
					<SelectInput
						label="Payment Method"
						source="paymentMethod"
						fullWidth
						sx={{ marginTop: 0 }}
						defaultValue="cash"
						choices={[
							{ id: 'cash', name: 'Cash' },
							{ id: 'credit', name: 'Credit' },
						]}
					/>
					<TextInput source="remarks" />
				</Box>
			</Box>
			<Box sx={{ width: '2px', backgroundColor: 'gray' }} />
			<BillingAsideForm state={state} />
		</Box>
	);
}

export function BillingAsideForm({ state }) {
	const { setValue, watch, getValues } = useFormContext();
	const [discount, roundOff, basicAmount, vat, discountAmount, total] = watch([
		'discount',
		'roundOff',
		'basicAmount',
		'vat',
		'discountAmount',
		'total',
	]);
	useEffect(() => {
		if (state[0].length > 0) {
			setValue(
				'basicAmount',
				(+state[0]?.reduce((acc, curr) => acc + +curr?.amount, 0) || 0).toFixed(
					2
				)
			);
			setValue(
				'taxableAmount',
				(
					+state[0]?.reduce((acc, curr) => {
						return acc + (curr?.brand?.data?.taxable ? curr?.amount : 0);
					}, 0) || 0
				).toFixed(2)
			);
			setValue(
				'vat',
				(
					+state[0]?.reduce((acc, curr) => {
						return acc + (curr?.brand?.data?.taxable ? curr?.amount * 0.13 : 0);
					}, 0) || 0
				).toFixed(2)
			);
		}
	}, [setValue, state]);

	useEffect(() => {
		setValue(
			'discountAmount',
			((basicAmount * discount) / 100 || 0).toFixed(2)
		);
	}, [discount, setValue, basicAmount]);

	useEffect(() => {
		setValue(
			'total',
			(
				+basicAmount + +(vat || 0) - (discountAmount || 0) + +roundOff || 0
			).toFixed(2)
		);
		setValue(
			'changeAmount',
			(
				getValues(['tenderAmount'])[0] -
				(+basicAmount + +vat - discountAmount + +roundOff)
			).toFixed(2)
		);
	}, [discountAmount, setValue, basicAmount, vat, roundOff, getValues]);

	return (
		<Box>
			<NumberInput source="basicAmount" disabled fullWidth />
			<Box sx={{ display: 'flex' }}>
				<NumberInput source="discount" label="%" sx={{ flexBasis: '40%' }} />
				<NumberInput
					source="discountAmount"
					label="Discount Amount"
					sx={{ flexGrow: 1 }}
					onChange={(e) => {
						setValue(
							'discount',
							((e.target.value * 100) / basicAmount).toFixed(2)
						);
						return e.target.value;
					}}
				/>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<NumberInput source="taxableAmount" disabled label="Taxable Amount" />
				<NumberInput source="vat" label="VAT" disabled />
			</Box>
			<NumberInput source="roundOff" label="Round Off" fullWidth />
			<NumberInput source="total" label="Net Amount" fullWidth disabled />
			<NumberInput
				source="tenderAmount"
				onChange={(e) => {
					setValue('changeAmount', e.target.value - total);
				}}
				label="Tender Amount"
				fullWidth
			/>
			<NumberInput
				source="changeAmount"
				disabled
				label="Change Amount"
				parse={(value) => (+value || 0).toFixed(2)}
				format={(value) => (+value || 0).toFixed(2)}
				fullWidth
			/>
		</Box>
	);
}

const inputs = [
	{
		key: 1,
		type: 'autoSelect',
		source: 'brand',
		reference: 'brand',
		optionText: 'name',
		nonCreatable: true,
	},
	{
		key: 2,
		type: 'custom',
		render: (values, onValueChange, setValues) =>
			values?.brand?.data ? (
				<CustomAutoInput
					onValueChange={onValueChange}
					input={{
						source: 'batchNumber',
						reference: `store/brand/${values?.brand?.data?.id}`,
						optionText: 'batchNumber',
						selectedOptionRender: (option) => {
							return `${option?.batchNumber} [${
								option?.totalQty -
								(option?.soldQty || 0) -
								(option?.returnedUnitQty || 0) -
								(option?.returnedFreeQty || 0)
							}]`;
						},
						onChange: (value, _, setValues) =>
							setValues((prev) => ({ ...prev, rate: value?.salesRPUnit })),
						optionLabelRender: (props, option) => {
							return (
								<li {...props}>
									{option?.batchNumber}&nbsp;&nbsp;[
									{option?.totalQty -
										(option?.soldQty || 0) -
										(option?.returnedUnitQty || 0) -
										(option?.returnedFreeQty || 0)}
									]
								</li>
							);
						},
					}}
					values={values}
					setValues={setValues}
				/>
			) : (
				<TextInput source="batchNumber" disabled />
			),
	},
	{
		key: 3,
		type: 'number',
		source: 'quantity',
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				amount: (value * (_?.rate || 0)).toFixed(2),
			})),
	},
	{
		key: 4,
		type: 'number',
		source: 'rate',
		props: { disabled: true },
	},
	{
		key: 6,
		type: 'number',
		source: 'discount',
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				amount: ((_?.rate || 0) * (_?.quantity || 0) - (value || 0)).toFixed(2),
				discountRate: (
					(value / ((_?.rate || 0) * (_?.quantity || 0))) *
					100
				).toFixed(2),
			})),
	},
	{
		key: 7,
		type: 'number',
		source: 'discountRate',
		onChange: (value, _, setValues) =>
			setValues((prev) => ({
				...prev,
				amount: (
					(_?.rate || 0) *
					(_?.quantity || 0) *
					(1 - value / 100)
				).toFixed(2),
				discount: (((_?.rate || 0) * (_?.quantity || 0) * value) / 100).toFixed(
					2
				),
			})),
	},
	{
		key: 5,
		type: 'number',
		source: 'amount',
		props: {
			disabled: true,
		},
	},
];

const headers = [
	[
		{
			key: 1,
			source: 'brand',
			label: 'Brand/Description',
			render: (value) => value?.brand?.value,
			editTrigger: true,
		},
		{
			key: 2,
			source: 'batchNumber',
			label: 'Batch',
			render: (value) => value?.batchNumber?.value,
		},
		{
			key: 3,
			source: 'quantity',
			label: 'Qty',
		},
		{
			key: 4,
			source: 'rate',
			label: 'Rate',
		},
		{
			key: 7,
			source: 'Amount',
			render: (value) => (value?.rate * value?.quantity || 0).toFixed(2),
		},
		{
			key: 6,
			source: 'discount',
			label: 'Discount / Rate',
			render: (value) =>
				`${value?.discount || 0} / ${value?.discountRate || 0}%`,
		},
		{
			key: 5,
			source: 'amount',
			label: 'Net Amount',
		},
	],
];
