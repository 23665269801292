
  import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { validateForm } from './CashDepositCreate';
import CashDepositForm from './CashDepositForm';

const CashDepositEdit = () => {
	const translate = useTranslate();
	return (
		<Edit redirect="/cashDeposit">
			<SimpleForm validate={validateForm}>
				<Typography variant="h6" gutterBottom>
					{translate('pharmacy.account.cashDeposit')}
				</Typography>
				<CashDepositForm />
			</SimpleForm>
		</Edit>
	);
};



export default CashDepositEdit;

  