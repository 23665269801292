import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import UserForm from './UserForm';
import SectionTitle from '../../../elements/SectionTitle';
import { Box } from '@mui/material';

export default function UserCreate() {
	return (
		<Create
			redirect="/users"
			transform={(data) => {
				const body = { ...data };
				body.username = data.email;
				return body;
			}}
		>
			<Box p={2} pb={0}>
				<SectionTitle label="admin.users.create" />
			</Box>
			<SimpleForm>
				<UserForm />
			</SimpleForm>
		</Create>
	);
}
