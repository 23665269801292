import { forwardRef, useState } from 'react';
import { Button, ReferenceInput, useTheme } from 'react-admin';
import CustomTextInput from './CustomTextInput';
import CustomAutoMUI from './CustomAutoMUI';
import { camelToFlat } from '../_helper';
import {
	Table,
	Box,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import BrandCreateModal from '../pages/pharmacy/components/BrandCreateModal';

const CustomArrayInput = forwardRef(
	({ inputs, inputContainerSX, setResults, editData = null }, ref) => {
		const [values, setValues] = useState({});

		function onValueChange(value, source) {
			setValues((prev) => {
				let tempValues = { ...prev };
				tempValues[source] = value;
				return tempValues;
			});
		}

		function onSubmit() {
			setResults && setResults((prev) => [...prev, values]);
			setValues({});
		}

		return (
			<form ref={ref}>
				<Box sx={inputContainerSX}>
					{inputs?.map((input) => {
						switch (input?.type) {
							case 'text':
							case 'number':
							case 'date':
								return (
									<CustomTextInput
										key={input?.source}
										source={input?.source}
										type={input?.type}
										sx={{ marginBottom: '1.5em', ...input?.props?.sx }}
										{...(input?.type === 'date' && {
											InputLabelProps: { shrink: true },
										})}
										fullWidth
										onChange={(e) => {
											onValueChange(e.target.value, input?.source);
											input?.onChange &&
												input?.onChange(e.target.value, values, setValues);
										}}
										onBlur={(e) => {
											onValueChange(e.target.value, input?.source);
											input?.onBLur &&
												input?.onBlur(e.target.value, values, setValues);
										}}
										value={
											editData ? editData[input?.source] : values[input?.source]
										}
										{...input?.props}
									/>
								);
							case 'autoSelect':
								return (
									<CustomAutoInput
										key={input.source}
										onValueChange={onValueChange}
										input={input}
										values={editData ? { ...editData, editMode: true } : values}
										setValues={setValues}
										{...input?.props}
									/>
								);
							case 'custom':
								return input.render(
									editData ? editData : values,
									onValueChange,
									setValues
								);

							default:
								return (
									<CustomTextInput
										key={input?.source}
										source={input?.source}
										type={input?.type}
										sx={{ marginBottom: '1.5em', ...input?.props?.sx }}
										{...(input?.type === 'date' && {
											InputLabelProps: { shrink: true },
										})}
										onChange={(e) => {
											onValueChange(e.target.value, input?.source);
											input?.onChange &&
												input?.onChange(e.target.value, values, setValues);
										}}
										value={values[input?.source]}
										{...input?.props}
									/>
								);
						}
					})}
					{inputs?.length > 0 && (
						<Button
							variant="contained"
							sx={{
								height: 'max-content',
								fontSize: '12px',
								display: 'flex',
								alignItems: 'center',
							}}
							size="medium"
							onClick={onSubmit}
						>
							<Add />
							ADD
						</Button>
					)}
				</Box>
			</form>
		);
	}
);

export function CustomAutoInput({ input, onValueChange, values, setValues }) {
	const [openBrandModal, setOpenBrandModal] = useState({ open: false });
	const [creatableOption, setCreatableOption] = useState([]);
	const [filter, setFilter] = useState(null);
	function handleOpen(value) {
		setOpenBrandModal((prev) => ({ ...prev, open: true, value }));
	}
	function handleClose() {
		setOpenBrandModal((prev) => ({ ...prev, open: false }));
	}

	function onBrandAdded(data) {
		setCreatableOption([data]);
		setValues((prev) => ({
			...prev,
			[input?.source]: { id: data.id, label: data?.name, data: data },
		}));
	}

	return (
		<>
			<ReferenceInput
				key={input?.source}
				label={input?.label || camelToFlat(input?.source)}
				source={input?.source}
				isRequired={input?.props?.isRequired}
				reference={input?.reference || input?.source}
				filter={{ q: filter }}
				enableGetChoices={() => true}
				sort={{ field: input?.optionText || 'name', order: 'ASC' }}
			>
				<CustomAutoMUI
					key={'input-auto'}
					optionText={input?.optionText}
					optionValue={input?.optionValue ? input?.optionValue : 'id'}
					optionLabelRender={input?.optionLabelRender}
					selectedOptionRender={input?.selectedOptionRender}
					fullWidth
					creatable={
						input?.creatable ||
						(input?.source === 'brand' && !input?.nonCreatable)
					}
					creatableOption={creatableOption}
					onSelect={(e) => {
						try {
							if (e) {
								onValueChange(
									{ id: e?.id, value: e[input?.optionText], data: e },
									input?.source
								);
								input?.onChange && input?.onChange(e, values, setValues);
							}
						} catch (error) {
							console.log('select error', error);
						}
					}}
					setFilter={setFilter}
					onAdd={input?.source === 'brand' ? handleOpen : input?.onAdd}
					size={'small'}
					value={
						values[input?.source]?.editMode
							? values[input?.source]
							: values[input?.source]
					}
					label={input?.label || camelToFlat(input?.source)}
				/>
			</ReferenceInput>
			{input?.source === 'brand' && (
				<BrandCreateModal
					modalData={openBrandModal}
					handleClose={handleClose}
					handleOpen={handleOpen}
					onBrandAdded={onBrandAdded}
				/>
			)}
		</>
	);
}

const ArrayTable = forwardRef(
	(
		{
			inputs,
			inputContainerSX,
			headers = [],
			hasSerialNumber = true,
			actions = null,
			state: [results, setResults],
			inputGridColumn = 6,
		},
		ref
	) => {
		const [theme] = useTheme();
		const [editData, setEditData] = useState(null);

		function handleEditClick(values) {
			return (e) => {
				alert('clicked');
				setEditData(values);
			};
		}

		const remove = (index) => () => {
			const tempResults = [...results];
			tempResults.splice(index, 1);
			setResults(tempResults);
		};

		return (
			<>
				<CustomArrayInput
					inputContainerSX={{
						display: 'grid',
						gridTemplateColumns: `repeat(${inputGridColumn}, 1fr)`,
						width: '100%',
						columnGap: '1rem',
						...inputContainerSX,
					}}
					editData={editData}
					inputs={inputs}
					setResults={setResults}
					ref={ref}
				/>
				<Box sx={{ width: '100%', minHeight: '25vmin' }}>
					<Table sx={{ marginBlock: '1em' }} size="small" dense table>
						<TableHead
							sx={{
								backgroundColor: theme === 'dark' ? 'black' : '#ccc',
								color: theme === 'dark' ? '#ccc' : '#333',
							}}
						>
							{headers?.map((headerRow, i) => (
								<TableRow key={i}>
									{i === 0 && hasSerialNumber && (
										<TableCell rowSpan={headers?.length}>#</TableCell>
									)}
									{headerRow?.map((head) => (
										<TableCell
											colSpan={head?.colSpan}
											rowSpan={head?.rowSpan}
											key={head?.source || head?.key}
										>
											{head?.label || camelToFlat(head?.source)}
										</TableCell>
									))}
									{i === 0 && actions && (
										<TableCell rowSpan={headers?.length}>Actions</TableCell>
									)}
								</TableRow>
							))}
						</TableHead>
						<TableBody>
							{results?.map((result, i) => (
								<TableRow key={i}>
									{hasSerialNumber && <TableCell>{i + 1}</TableCell>}
									{headers
										?.flat()
										.filter((h) => Boolean(h?.source))
										?.sort((a, b) => a?.order - b?.order)
										?.map(
											(head, i) =>
												(head?.source || head?.render) && (
													<TableCell
														key={i}
														sx={{
															color: head?.editTrigger
																? 'primary.main'
																: 'inherit',
															textDecoration: head?.editTrigger
																? 'underline'
																: 'none',
															textUnderlineOffset: '3px',
															cursor: head?.editTrigger ? 'pointer' : 'auto',
														}}
														{...(head?.editTrigger && {
															onClick: handleEditClick(result),
														})}
													>
														{head?.render
															? head?.render(result)
															: result[head?.source]}
													</TableCell>
												)
										)}
									{actions && (
										<TableCell>
											{actions?.delete && (
												<Delete style={{ color: 'red' }} onClick={remove(i)} />
											)}
										</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
						{/* <tfoot>
							<tr style={{ borderTop: '3px double black' }}></tr>
						</tfoot> */}
					</Table>
				</Box>
			</>
		);
	}
);

export default ArrayTable;
