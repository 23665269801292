import { Datagrid, FunctionField, List } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import SectionTitle from '../../elements/SectionTitle';

const DocumentsList = () => {
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	//   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

	return (
		<>
			<SectionTitle label="document.template" />
			<List filters={undefined} sort={{}} perPage={25}>
				{isXsmall ? null : (
					<Datagrid
						optimized
						rowClick="edit"
						sx={{
							'& .column-groups': {
								md: { display: 'none' },
								lg: { display: 'table-cell' },
							},
						}}
					>
						<FunctionField
							label="Bill"
							render={(template) => (
								<a href={template.bill} download target="_blank">
									Download
								</a>
							)}
						/>
						<FunctionField
							label="Ticket"
							render={(template) => (
								<a href={template.ticket} download target="_blank">
									Download
								</a>
							)}
						/>
					</Datagrid>
				)}
			</List>
		</>
	);
};

export default DocumentsList;
