import { useMemo } from "react";
import { useChoicesContext } from "react-admin";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";

const filter = createFilterOptions({
  matchFrom: "start",
});

const CustomAutoMUI = ({
  onSelect,
  onAdd = null,
  value,
  label,
  selectRef,
  optionText = "name",
  creatable = false,
  creatableOption = [],
  optionLabelRender = null,
  selectedOptionRender = null,
  setFilter = null,
  ...props
}) => {
  const { allChoices, isLoading, setFilters } = useChoicesContext();

  const choices = useMemo(() => {
    if (!isLoading) {
      return allChoices
        .map((c) => ({
          ...c,
          label: selectedOptionRender ? selectedOptionRender(c) : c[optionText],
        }))
        .concat(creatableOption);
    } else {
      return [];
    }
  }, [
    allChoices,
    isLoading,
    optionText,
    creatableOption,
    selectedOptionRender,
  ]);
  const filteredChoices = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    console.log(params);
    const isExisting = options.some(
      (option) => inputValue === option[optionText]
    );
    setFilters({ q: inputValue });
    setFilter && setFilter(inputValue);
    if (inputValue !== "" && !isExisting && creatable) {
      filtered.push({
        inputValue,
        [optionText]: `Add "${inputValue}"`,
        label: inputValue,
        new: true,
      });
    }
    return [...filtered];
  };

  return isLoading ? (
    <TextField source="loading" size="small" label="Loading..." disabled />
  ) : (
    <Autocomplete
      ref={selectRef ? selectRef : null}
      {...props}
      options={choices}
      renderInput={(params) => {
        return <TextField {...params} label={label} />;
      }}
      onChange={(event, newValue) => {
        if (newValue?.new) {
          onAdd(newValue);
          return;
        }
        onSelect && onSelect(newValue);
      }}
      filterOptions={filteredChoices}
      {...(creatable && {
        freeSolo: true,
        getOptionLabel: (option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option?.inputValue) {
            return `Add "${option?.inputValue}"`;
          }
          return selectedOptionRender
            ? selectedOptionRender(option)
            : option[optionText];
        },
      })}
      {...(optionLabelRender && { renderOption: optionLabelRender })}
      renderOption={(props, option) => {
        return (
          <li
            style={{ fontSize: "12px", paddingBlock: 0 }}
            {...props}
            key={option?.id}
          >
            {option?.[optionText]}
          </li>
        );
      }}
      size="small"
      // value={value}
      // inputValue={value}
      disabled={isLoading}
    />
  );
};

export default CustomAutoMUI;
